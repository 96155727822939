import React, { useState } from 'react';
import { 
  Box, 
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Modal,
  Typography,
  Tooltip
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: color || '#495057',
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
}));

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

const tableHeaders = [
  { id: 'checkbox', label: '체크박스', color: '#bbdefb' },
  { id: 'no', label: 'NO', color: '#bbdefb' },
  { id: 'pg', label: 'PG', color: '#bbdefb' },
  { id: 'accountInfo', label: '계정정보', color: '#bbdefb' },
  { id: 'detail', label: '상세팝업', color: '#bbdefb' },
  { id: 'walletStatus', label: '월렛여부', color: '#2196f3' },
  { id: 'settlementPossible', label: '정산가능', color: '#2196f3' },
  { id: 'merchantTID', label: '가맹점TID', color: '#2196f3' },
  { id: 'merchantMID', label: '가맹점 MID', color: '#2196f3' },
  { id: 'merchantName', label: '가맹점명', color: '#2196f3' },
  { id: 'transactionDate', label: '결제일시', color: '#c8e6c9' },
  { id: 'settlementCycle', label: '정산주기', color: '#c8e6c9' },
  { id: 'expectedSettlementDate', label: '정산예정일', color: '#c8e6c9' },
  { id: 'approvalCount', label: '승인건수', color: '#c8e6c9' },
  { id: 'cancelCount', label: '취소건수', color: '#c8e6c9' },
  { id: 'approvalAmount', label: '승인금액', color: '#c8e6c9' },
  { id: 'cancelAmount', label: '취소금액', color: '#c8e6c9' },
  { id: 'detailCount', label: '세부내역', color: '#c8e6c9' },
  { id: 'actualSalesAmount', label: '실매출금액', color: '#ffffff' },
  { id: 'settlementAmount', label: '정산액', color: '#ffffff' },
  { id: 'merchantCommissionRate', label: '가맹점수수료율', color: '#ffffff' },
  { id: 'commission', label: '수수료', color: '#ffffff' },
  { id: 'rmCount', label: 'RM건수', color: '#ffffff' },
  { id: 'unpaidAmount', label: '미수금액', color: '#ffffff' },
  { id: 'retentionRate', label: '유보율', color: '#ffffff' },
  { id: 'paymentRate', label: '지급률', color: '#ffffff' },
  { id: 'depositRate', label: '보증금률', color: '#ffffff' },
];

const dummyData = [
  {
    no: 1,
    pg: 'KICC',
    accountInfo: 'Account 1',
    detail: '상세보기',
    walletStatus: 'Yes',
    settlementPossible: 'Yes',
    merchantTID: 'TID123',
    merchantMID: 'MID123',
    merchantName: 'Merchant 1',
    transactionDate: '2024-01-01',
    settlementCycle: 'D+1',
    expectedSettlementDate: '2024-01-02',
    approvalCount: 5,
    cancelCount: 1,
    approvalAmount: 500000,
    cancelAmount: -10000,
    detailCount: 1,
    actualSalesAmount: 490000,
    settlementAmount: 480000,
    merchantCommissionRate: '1.5%',
    commission: 7200,
    rmCount: 0,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
  {
    no: 2,
    pg: 'PayUp',
    accountInfo: 'Account 2',
    detail: '상세보기',
    walletStatus: 'No',
    settlementPossible: 'No',
    merchantTID: 'TID456',
    merchantMID: 'MID456',
    merchantName: 'Merchant 2',
    transactionDate: '2024-01-02',
    settlementCycle: 'D+2',
    expectedSettlementDate: '2024-01-04',
    approvalCount: 3,
    cancelCount: 0,
    approvalAmount: 300000,
    cancelAmount: 0,
    detailCount: 0,
    actualSalesAmount: 300000,
    settlementAmount: 300000,
    merchantCommissionRate: '2%',
    commission: 6000,
    rmCount: 1,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
];

function SettlementHoldDetailsTables({ 
  searchPanelOpen, 
  setSearchPanelOpen, 
  selectedRows, 
  setSelectedRows 
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const handleCheckboxChange = (checked, rowId) => {
    if (checked) {
      setSelectedRows(prev => [...prev, rowId]);
    } else {
      setSelectedRows(prev => prev.filter(id => id !== rowId));
    }
  };

  const handleUserIconClick = (userData) => {
    setSelectedUserData(userData);
    setModalOpen(true);
  };

  return (
    <>
      <Box mb={2}>
        <Tooltip title="정산보류">
          <IconButton 
            sx={{ 
              backgroundColor: '#dc3545',
              color: '#fff',
              '&:hover': { backgroundColor: '#c82333' },
              borderRadius: '4px',
              padding: '8px'
            }}
            onClick={() => console.log('정산보류 클릭')}
          >
            <CancelIcon />
            <Typography variant="caption" sx={{ marginLeft: 1 }}>
              정산보류
            </Typography>
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  align={header.id === 'checkbox' ? 'center' : 'left'}
                  style={{ 
                    backgroundColor: header.color,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.no}>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={`${row.no}-${header.id}`}
                    align={header.id === 'checkbox' ? 'center' : 'left'}
                  >
                    {header.id === 'checkbox' ? (
                      <Checkbox 
                        checked={selectedRows.includes(row.no)}
                        onChange={(e) => handleCheckboxChange(e.target.checked, row.no)}
                      />
                    ) : (
                      row[header.id]
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 1,
          width: '400px',
          maxWidth: '90vw'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            사용자 정보
          </Typography>
          {selectedUserData && (
            <>
              <Typography>고객명: {selectedUserData.customerName}</Typography>
              <Typography>휴대폰 번호: {selectedUserData.phoneNumber}</Typography>
              <Typography>승인금액: {selectedUserData.approvalAmount}</Typography>
              <Typography>승인번호: {selectedUserData.approvalNo}</Typography>
              <Typography>결제일자: {selectedUserData.paymentDate}</Typography>
              <Typography>취소일자: {selectedUserData.cancelDate || '-'}</Typography>
              <Typography>첨부파일: {selectedUserData.hasAttachments ? '있음' : '없음'}</Typography>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default SettlementHoldDetailsTables;
