import React from 'react';
import { Box, Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import StorageIcon from '@mui/icons-material/Storage';

const TaxIcons = ({ onIconClick }) => {
  const buttons = [
    {
      label: '세금계산서 일반',
      icon: <GetAppIcon />,
      value: 'general',
      color: '#007bff'
    },
    {
      label: '세금계산서 대량',
      icon: <CloudDownloadIcon />,
      value: 'bulk',
      color: '#28a745'
    },
    {
      label: '전산매체',
      icon: <StorageIcon />,
      value: 'media',
      color: '#17a2b8'
    }
  ];

  return (
    <Box>
      <Box sx={{ 
        display: 'flex',
        gap: 1,
        flexDirection: 'row',  // 가로 방향
        alignItems: 'center',  // 세로 중앙 정렬
        overflowX: 'auto',    // 가로 스크롤 허용
        width: '100%',        // 전체 너비
        '&::-webkit-scrollbar': { height: '0px' } // 스크롤바 숨김
      }}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            startIcon={button.icon}
            onClick={() => onIconClick?.(button.value)}
            sx={{
              bgcolor: '#fff',
              color: button.color,
              border: `1px solid ${button.color}`,
              fontSize: '0.75rem',
              height: '32px',
              padding: '4px 12px',
              minWidth: 'auto',
              flexShrink: 0,    // 버튼 크기 고정
              boxShadow: 'none',
              '&:hover': {
                bgcolor: `${button.color}10`,
                border: `1px solid ${button.color}`,
                boxShadow: 'none'
              },
              '& .MuiButton-startIcon': {
                marginRight: '4px',
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem'
                }
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default TaxIcons;
