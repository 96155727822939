import React, { useState } from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Paper, 
  Box,
  TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import DateRangePicker from '../../Common/DateRangePicker';

const StyledTableCell = styled(TableCell)(({ theme, bgcolor }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  
  '&.MuiTableCell-head': {
    color: '#000',
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '40px',
    backgroundColor: bgcolor || '#f8f9fa'
  },

  '&.status-cell': {
    textAlign: 'center',
    '& .active': { color: '#2185d0' },
    '& .inactive': { color: '#f44336' }
  },

  '&:last-child': {
    borderRight: 'none'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#fafbfc',
  },
  '&:hover': {
    backgroundColor: '#f5f9ff',
    transition: 'background-color 0.2s ease'
  }
}));

const StyledTableWrapper = styled(Box)({
  border: '1px solid #e9ecef',
  borderRadius: '4px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  backgroundColor: '#fff',
  overflowX: 'auto',
  
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  }
});

const StatusChip = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  
  &.active {
    background-color: #e3f2fd;
    color: #2185d0;
  }
  
  &.inactive {
    background-color: #ffebee;
    color: #f44336;
  }
`;

// 더미데이터 수정
const dummyData = [
  {
    no: 1,
    registerDate: '2024-01-01 14:00:00',
    nextPaymentDate: '2024-02-01 14:00:00',
    stopDate: '-',
    status: '사용중',
    deleteStatus: '삭제',
    authType: 'SMS',
    pgName: 'PG1',
    merchantId: 'MID00000001',
    merchantName: '가맹점1',
    registerName: '등록자1',
    amount: '10,000원',
    cardNumber: '4321-****-****-0001',
    validThru: '01/25',
    customerName: '고객1',
    password: '******',
    phone: '010-1234-5678',
    email: 'user1@example.com',
    smsStatus: '발송',
    result: '성공'
  },
  {
    no: 2,
    registerDate: '2024-01-02 14:10:00',
    nextPaymentDate: '2024-02-02 14:10:00',
    stopDate: '2024-03-02',
    status: '종료',
    deleteStatus: '-',
    authType: 'CARD',
    pgName: 'PG2',
    merchantId: 'MID00000002',
    merchantName: '가맹점2',
    registerName: '등록자2',
    amount: '20,000원',
    cardNumber: '4321-****-****-0002',
    validThru: '02/25',
    customerName: '고객2',
    password: '******',
    phone: '010-1235-5679',
    email: 'user2@example.com',
    smsStatus: '미발송',
    result: '성공'
  },
  {
    no: 3,
    registerDate: '2024-01-03 14:20:00',
    nextPaymentDate: '2024-02-03 14:20:00',
    stopDate: '-',
    status: '사용중',
    deleteStatus: '-',
    authType: 'BIO',
    pgName: 'PG3',
    merchantId: 'MID00000003',
    merchantName: '가맹점3',
    registerName: '등록자3',
    amount: '30,000원',
    cardNumber: '4321-****-****-0003',
    validThru: '03/25',
    customerName: '고객3',
    password: '******',
    phone: '010-1236-5680',
    email: 'user3@example.com',
    smsStatus: '발송',
    result: '성공'
  }
];

function RegularPaymentTable() {
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });

  return (
    <>
      {/* 검색 옵션 박스 */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <DateRangePicker
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onStartDateChange={(date) => setDateRange(prev => ({ ...prev, startDate: date }))}
            onEndDateChange={(date) => setDateRange(prev => ({ ...prev, endDate: date }))}
          />
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            sx={{ minWidth: '200px' }}
          />
        </Box>
      </Paper>

      {/* 테이블 */}
      <StyledTableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              {/* 기본 정보 (흰색 배경) */}
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell>등록일시</StyledTableCell>
              <StyledTableCell>다음결제일</StyledTableCell>
              <StyledTableCell>중지일시</StyledTableCell>
              <StyledTableCell>사용구분</StyledTableCell>
              <StyledTableCell>삭제여부</StyledTableCell>
              
              {/* 상태 정보 (녹색 배경) */}
              <StyledTableCell bgcolor="#e8f5e9">문자발송여부</StyledTableCell>
              <StyledTableCell bgcolor="#e8f5e9">응답결과</StyledTableCell>
              
              {/* 인증/가맹점 정보 (파란색 배경) */}
              <StyledTableCell bgcolor="#e3f2fd">인증타입</StyledTableCell>
              <StyledTableCell bgcolor="#e3f2fd">PG</StyledTableCell>
              <StyledTableCell bgcolor="#e3f2fd">ID</StyledTableCell>
              <StyledTableCell bgcolor="#e3f2fd">상호명</StyledTableCell>
              <StyledTableCell bgcolor="#e3f2fd">등록명</StyledTableCell>
              
              {/* 결제 정보 (주황색 배경) */}
              <StyledTableCell bgcolor="#fff3e0">결제금액</StyledTableCell>
              <StyledTableCell bgcolor="#fff3e0">카드번호</StyledTableCell>
              <StyledTableCell bgcolor="#fff3e0">유효기간</StyledTableCell>
              
              {/* 고객 정보 (흰색 배경) */}
              <StyledTableCell>고객명</StyledTableCell>
              <StyledTableCell>비밀번호</StyledTableCell>
              <StyledTableCell>휴대폰번호</StyledTableCell>
              <StyledTableCell>이메일</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.no}>
                <StyledTableCell>{row.no}</StyledTableCell>
                <StyledTableCell>{row.registerDate}</StyledTableCell>
                <StyledTableCell>{row.nextPaymentDate}</StyledTableCell>
                <StyledTableCell>{row.stopDate}</StyledTableCell>
                <StyledTableCell>
                  <StatusChip className={row.status === '사용중' ? 'active' : 'inactive'}>
                    {row.status}
                  </StatusChip>
                </StyledTableCell>
                <StyledTableCell>{row.deleteStatus}</StyledTableCell>
                <StyledTableCell bgcolor="#e8f5e9">{row.smsStatus}</StyledTableCell>
                <StyledTableCell bgcolor="#e8f5e9">{row.result}</StyledTableCell>
                <StyledTableCell bgcolor="#e3f2fd">{row.authType}</StyledTableCell>
                <StyledTableCell bgcolor="#e3f2fd">{row.pgName}</StyledTableCell>
                <StyledTableCell bgcolor="#e3f2fd">{row.merchantId}</StyledTableCell>
                <StyledTableCell bgcolor="#e3f2fd">{row.merchantName}</StyledTableCell>
                <StyledTableCell bgcolor="#e3f2fd">{row.registerName}</StyledTableCell>
                <StyledTableCell bgcolor="#fff3e0">{row.amount}</StyledTableCell>
                <StyledTableCell bgcolor="#fff3e0">{row.cardNumber}</StyledTableCell>
                <StyledTableCell bgcolor="#fff3e0">{row.validThru}</StyledTableCell>
                <StyledTableCell>{row.customerName}</StyledTableCell>
                <StyledTableCell>{row.password}</StyledTableCell>
                <StyledTableCell>{row.phone}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>
    </>
  );
}

export default RegularPaymentTable;
