import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
  Box,
  Card
} from '@mui/material';

// 스타일 정의
const useStyles = makeStyles((theme) => ({
  statusGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(780)]: {
      gridTemplateColumns: '1fr', // 모바일에서는 한 줄로
      gap: theme.spacing(2)
    },
    [theme.breakpoints.between(781, 1024)]: {
      gap: theme.spacing(2)
    }
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0 10px 20px rgba(0,0,0,0.05)',
    overflow: 'hidden',
    border: '1px solid #e8eef4'
  },
  cardHeader: {
    padding: theme.spacing(2.5),
    backgroundColor: '#ffffff',
    borderBottom: '2px solid #e8eef4',
    [theme.breakpoints.between(781, 1024)]: {
      padding: theme.spacing(1.5)
    }
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: '#1a1a1a',
    textAlign: 'center',
    [theme.breakpoints.between(781, 1024)]: {
      fontSize: '1rem'
    }
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
    '& .MuiTableCell-root': {
      padding: theme.spacing(2.5),
      textAlign: 'center',
      '&:not(:last-child)': {
        borderRight: '1px solid #e8eef4'
      },
      [theme.breakpoints.between(781, 1024)]: {
        padding: theme.spacing(1.5)
      }
    }
  },
  tableHead: {
    '& .MuiTableCell-root': {
      fontSize: '0.875rem',
      color: '#666666',
      fontWeight: 600,
      backgroundColor: '#f8fafc',
      borderBottom: '1px solid #e8eef4',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '2px',
        background: 'linear-gradient(90deg, #e8eef4 0%, #d1dce8 100%)'
      },
      [theme.breakpoints.between(781, 1024)]: {
        fontSize: '0.75rem'
      }
    }
  },
  tableBody: {
    '& .MuiTableCell-root': {
      fontSize: '1.5rem',
      fontWeight: 600,
      fontFamily: "'Montserrat', 'Noto Sans KR', sans-serif",
      color: '#1a1a1a',
      background: 'linear-gradient(180deg, #ffffff 0%, #fafbfc 100%)',
      [theme.breakpoints.between(781, 1024)]: {
        fontSize: '1.1rem'
      }
    }
  }
}));

function MerchantStats() {
  const [screenSize, setScreenSize] = useState({
    isMobile: window.innerWidth <= 780,
    isTablet: window.innerWidth > 780 && window.innerWidth <= 1024
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        isMobile: window.innerWidth <= 780,
        isTablet: window.innerWidth > 780 && window.innerWidth <= 1024
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const classes = useStyles();

  const merchantStatusData = [
    {
      id: 1,
      title: '현재 머천트 잔액',
      value: '5,000,000원',
      color: '#ff0000'
    },
    {
      id: 2,
      title: '현재 미출금 합계',
      value: '3,500,000원',
      color: '#ff0000'
    }
  ];

  const paymentStatusData = [
    {
      id: 1,
      title: '당월지급 예정액',
      value: '0원',
      color: '#666666'
    },
    {
      id: 2,
      title: '익월지급 예정액',
      value: '0원',
      color: '#666666'
    }
  ];

  const merchantData = [
    {
      id: 1,
      title: '최근 7일 신규 가맹점',
      value: '0개',
      color: '#666666'
    },
    {
      id: 2,
      title: '최근 7일 신규 영업점',
      value: '0개',
      color: '#666666'
    }
  ];

  const renderStatusCard = (title, data) => (
    <Card className={classes.card}>
      <Box className={classes.cardHeader}>
        <Typography className={classes.cardTitle}>
          {title}
        </Typography>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {data.map((item) => (
                <TableCell key={item.id}>
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            <TableRow>
              {data.map((item) => (
                <TableCell 
                  key={item.id}
                  style={{ color: item.color }}
                >
                  {item.value}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const content = (
    <Box sx={{ padding: theme => theme.spacing(0) }}>
      <Box className={classes.statusGrid}>
        {renderStatusCard('머천트 현황', merchantStatusData)}
        {renderStatusCard('지급 현황', paymentStatusData)}
        {renderStatusCard('신규 가맹점/영업점 현황', merchantData)}
      </Box>
    </Box>
  );

  return screenSize.isMobile ? (
    <Box sx={{
      overflowX: 'hidden', // 모바일에서는 가로 스크롤 제거
      WebkitOverflowScrolling: 'touch',
      '&::-webkit-scrollbar': {
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }}>
      {content}
    </Box>
  ) : content;
}

export default MerchantStats;