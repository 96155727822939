import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import HistoryIcon from '@mui/icons-material/History';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendIcon from '@mui/icons-material/Send';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ListAltIcon from '@mui/icons-material/ListAlt';

const ButtonWidget = ({ showFirst = true, showSecond = false }) => {
    const firstButtonGroup = [
        { label: '정산초기화', icon: <RestartAltIcon /> },
        { label: '갱신', icon: <RefreshIcon /> },
        { label: '송금 실패내역', icon: <HistoryIcon /> },
    ];

    const secondButtonGroup = [
        { label: '송금하기', icon: <SendIcon /> },
        { label: '예약송금', icon: <ScheduleIcon /> },
        { label: '예약목록', icon: <ListAltIcon /> },
    ];

    return (
        <div>
            {showFirst && (
                <div className="button-group">
                    {firstButtonGroup.map((button, index) => (
                        <button key={index} className="styled-button">
                            {button.icon} {button.label}
                        </button>
                    ))}
                </div>
            )}
            {showSecond && (
                <div className="button-group">
                    {secondButtonGroup.map((button, index) => (
                        <button key={index} className="styled-button">
                            {button.icon} {button.label}
                        </button>
                    ))}
                </div>
            )}

            <style jsx>{`
                .button-group {
                    display: flex;
                    gap: 8px;
                    margin: 15px 0;
                }

                .styled-button {
                    display: flex;
                    align-items: center;
                    padding: 6px 10px;
                    border: none;
                    border-radius: 4px;
                    background-color: #007BFF;
                    color: white;
                    cursor: pointer;
                    transition: background-color 0.3s;
                    font-size: 13px;
                }

                .styled-button:hover {
                    background-color: #0056b3;
                }

                .styled-button svg {
                    margin-right: 4px;
                    font-size: 14px;
                }
            `}</style>
        </div>
    );
};

export default ButtonWidget;
