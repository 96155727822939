import React from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const UserFAQ = () => {
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [faqs, setFaqs] = React.useState([
    {
      action: '변경',
      group: '결제사이트',
      public: 1,
      question: '기업 결제가 어떻게 되나요?',
      answer: '전자계약으로 방문하시면 기업결제 서비스를 이용하실 수 있습니다. 가입시 필요한 서류는 1) 사업자등록증 사본 1장 2) 업금 입증서 서류 1장 3) 법인: 법인등기부등본(현재) 1장이 필요합니다.',
      date: '2024-10-18'
    },
    {
      action: '추가',
      group: '메인사이트',
      public: 1,
      question: '보증보험 가입은 어떻게 해야하나요?',
      answer: '보증보험 가입은 서울보증보험에서 하시면 됩니다. 단, 업체에 따라 보험료가 다를 수 있습니다.',
      date: '2024-10-18'
    },
    {
      action: '변경',
      group: '결제사이트',
      public: 0,
      question: '정산은 언제 되나요?',
      answer: '정산은 D+5일 기준으로 진행됩니다. 공휴일이나 주말인 경우 다음 영업일에 처리됩니다.',
      date: '2024-10-18'
    }
  ]);

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedFaqs = [...faqs].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setFaqs(sortedFaqs);
  };

  return (
    <Card sx={{ 
      mt: 4, 
      borderRadius: '12px', 
      boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
      height: 'calc(100vh - 280px)'
    }}>
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <QuestionAnswerIcon sx={{ color: '#1976d2' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            유저 FAQ
          </Typography>
        </Box>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />} 
          sx={{ 
            backgroundColor: '#4a90e2',
            '&:hover': {
              backgroundColor: '#357abd'
            },
            boxShadow: '0 2px 4px rgba(74, 144, 226, 0.3)',
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          FAQ 추가
        </Button>
      </Box>
      <TableContainer sx={{ 
        height: 'calc(100vh - 380px)',
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
          '&:hover': {
            background: '#666'
          }
        }
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>행동</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>그룹</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>공개/비공개</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>제목</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>내용</TableCell>
              <TableCell 
                sx={{ 
                  backgroundColor: '#f7f7f7', 
                  fontWeight: 600, 
                  color: '#252631',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#eaeaea'
                  }
                }}
                onClick={handleSortClick}
              >
                등록시간
                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
              </TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>작업</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faqs.map((faq, index) => (
              <TableRow 
                key={index}
                sx={{ 
                  '&:hover': { backgroundColor: '#f5f5f5' },
                  transition: 'background-color 0.2s'
                }}
              >
                <TableCell>{faq.action}</TableCell>
                <TableCell>{faq.group}</TableCell>
                <TableCell>{faq.public ? '공개' : '비공��'}</TableCell>
                <TableCell>{faq.question}</TableCell>
                <TableCell sx={{ fontSize: '0.8rem', maxWidth: '300px' }}>{faq.answer}</TableCell>
                <TableCell>{faq.date}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="보기">
                      <IconButton size="small" sx={{ color: '#51cbce' }}>
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="수정">
                      <IconButton size="small" sx={{ color: '#fbc658' }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="삭제">
                      <IconButton size="small" sx={{ color: '#ef8157' }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default UserFAQ;
