import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Paper,
  Grid,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import Sidebar from '../../Sidebar';
import Notice from '../../Notice/Notice';
import DateRangePicker from '../../Common/DateRangePicker';
import Breadcrumb from '../../Common/Breadcrumb';
import SummaryData from '../../Common/SummaryData';
import { debounce } from 'lodash';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '40px',
    width: 'calc(100% - 80px)',
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '40px',
    pr: '40px',
    ml: '0px'
  },
  noticeBox: { 
    width: '100%',
    margin: '0 auto', 
    mb: 2 
  }
};

function AlarmHistory() {
  // 더미 데이터를 먼저 선언
  const dummyData = [
    {
      time: "2024-11-27 11:10:02",
      content: `머천트 전액부족 ${(8000000).toLocaleString()} 기준금액 미달`,
      status: "읽음",
      reader: "b19003",
      readTime: "2024-11-27 11:19:54"
    },
    {
      time: "2024-11-27 10:39:02",
      content: `머천트 전액부족 ${(10000000).toLocaleString()} 기준금액 미달`,
      status: "읽음",
      reader: "b19000",
      readTime: "2024-11-27 10:41:29"
    },
    {
      time: "2024-11-26 22:22:05",
      content: `머천트 전액부족 ${(10000000).toLocaleString()} 기준금액 미달`,
      status: "읽음",
      reader: "b19003",
      readTime: "2024-11-26 22:33:56"
    },
    {
      time: "2024-11-26 20:16:50",
      content: "[긴급메리] m99986962 네트워크 지연 오류 발생, 인터넷 정검 완료, 정시후 재시도 요청",
      status: "읽음",
      reader: "b19003",
      readTime: "2024-11-26 20:26:39"
    },
    {
      time: "2024-11-26 17:01:03",
      content: `머천트 전액부족 ${(5000000).toLocaleString()} 기준금액 미달`,
      status: "읽음",
      reader: "b19003",
      readTime: "2024-11-26 18:48:42"
    },
    {
      time: "2024-11-26 16:44:02",
      content: `머천트 전액부족 ${(8000000).toLocaleString()} 기준금액 미달`,
      status: "읽음",
      reader: "b19003",
      readTime: "2024-11-26 18:48:42"
    },
    {
      time: "2024-11-26 15:26:03",
      content: `머천트 전액부족 ${(8000000).toLocaleString()} 기준금액 미달`,
      status: "읽음",
      reader: "b19003",
      readTime: "2024-11-26 15:42:03"
    },
    {
      time: "2024-11-26 15:08:02",
      content: `머천트 전액부족 ${(10000000).toLocaleString()} 기준금액 미달`,
      status: "읽음",
      reader: "b19003",
      readTime: "2024-11-26 15:42:03"
    },
    {
      time: "2024-11-26 11:52:52",
      content: "[출금실패] m76640459의신드 전액취인 ERROR_CODE: B012",
      status: "읽음",
      reader: "admin",
      readTime: "2024-11-26 11:54:45"
    },
    {
      time: "2024-11-26 11:52:48",
      content: "m76640459 출금요청금액이 머천트 전액을 초과했습니다.",
      status: "읽음",
      reader: "admin",
      readTime: "2024-11-26 11:54:45"
    }
  ];

  // 그 다음 state 선언
  const [currentTab, setCurrentTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');
  const [alarmData, setAlarmData] = useState(dummyData);
  const [statusSortDirection, setStatusSortDirection] = useState(null);

  // debounce된 검색 함수 생성
  const debouncedSearch = React.useCallback(
    debounce((term) => {
      // 실제로는 여기서 API 호출
      console.log('Searching for:', term);
    }, 500),  // 500ms 딜레이
    []
  );

  // 검색어 변경 핸들러
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleTimeSort = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  // 상태 토글 핸들러 추가
  const handleStatusToggle = (index) => {
    setAlarmData(prevData => prevData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          status: item.status === '읽음' ? '안읽음' : '읽음'
        };
      }
      return item;
    }));
  };

  // 상태 정렬 핸들러 추가
  const handleStatusSort = () => {
    if (statusSortDirection === 'asc') {
      // asc 상태에서 클릭하면 정렬 해제하고 시간 정렬로 돌아감
      setStatusSortDirection(null);
    } else {
      // 정렬이 없거나 desc 상태에서 클릭하면 asc로 변경
      setStatusSortDirection('asc');
    }
  };

  // 정렬된 데이터 생성 - 시간 정렬과 상태 정렬 모두 고려
  const sortedData = [...alarmData].sort((a, b) => {
    // 상태 정렬이 활성화된고 asc인 경우에만 상태 정렬 적용
    if (statusSortDirection === 'asc') {
      // '안읽음'이 위로 오도록 정렬
      return a.status === '안읽음' ? -1 : 1;
    }
    
    // 그 외의 경우(desc이거나 null인 경우) 시간 정렬 사용
    if (sortDirection === 'asc') {
      return new Date(a.time) - new Date(b.time);
    }
    return new Date(b.time) - new Date(a.time);
  });

  return (
    <Box component="main" sx={{
      ...styles.mainBox,
      mx: { xs: '40px', md: '40px' },
      pl: { xs: '40px', md: '40px' },
      pr: { xs: '40px', md: '40px' },
      width: { xs: 'calc(100% - 80px)', md: 'calc(100% - 80px)' }
    }}>
      {/* Notice 영역 */}
      <Box sx={{
        ...styles.noticeBox,
        width: { xs: '100%', md: 'calc(100% - 20px)' }
      }}>
        <Notice />
      </Box>

      {/* 카테고리 탭 */}
      <Box sx={{ 
        width: { xs: '100%', md: 'calc(100% - 20px)' }, 
        margin: '0 auto', 
        mb: 2 
      }}>
        <Paper sx={{ p: 2 }}>
          <Tabs 
            value={currentTab} 
            onChange={(e, newValue) => setCurrentTab(newValue)}
            TabIndicatorProps={{
              sx: {
                height: '1px',  // 하단 선 두께
                backgroundColor: '#000'  // 하단 선 색상
              }
            }}
            sx={{
              minHeight: '36px',  // 탭 높이 조정
              '& .MuiTab-root': {
                minHeight: '36px',
                padding: '0 16px',
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: 'normal',
                color: '#000',
                '&.Mui-selected': {
                  color: '#000',
                  fontWeight: 'bold'
                }
              },
              // 탭 사이의 구분선
              '& .MuiTab-root:not(:last-child)::after': {
                content: '""',
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                height: '12px',
                width: '1px',
                backgroundColor: '#ddd'
              },
              '@media (max-width: 600px)': {
                '& .MuiTab-root': {
                  minWidth: 'auto',
                  padding: '0 8px',
                  fontSize: '12px'
                }
              }
            }}
          >
            <Tab label="월렛" />
            <Tab label="결제/오류" />
            <Tab label="알림" />
            <Tab label="등록신청" />
            <Tab label="서브전산" />
          </Tabs>
        </Paper>
      </Box>

      {/* 검색 영역 */}
      <Box sx={{ 
        width: { xs: '100%', md: 'calc(100% - 20px)' }, 
        margin: '0 auto', 
        mb: 2 
      }}>
        <Paper sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="모든 필드를 검색합니다."
              size="small"
              fullWidth
            />
          </Box>
        </Paper>
      </Box>

      {/* 테이블 */}
      <Box sx={{ 
        width: { xs: '100%', md: 'calc(100% - 20px)' }, 
        margin: '0 auto' 
      }}>
        <TableContainer 
          component={Paper}
          sx={{
            overflowX: 'auto',
            WebkitOverflowScrolling: 'touch',
            '&::-webkit-scrollbar': {
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.2)',
              borderRadius: '3px'
            }
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '80px', md: '15%' },
                    minWidth: '80px',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                  onClick={handleTimeSort}
                >
                  시간 {sortDirection === 'asc' ? '↑' : '↓'}
                </TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '150px', md: '40%' },
                    minWidth: '150px',
                    whiteSpace: 'nowrap'
                  }}
                >내용</TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '70px', md: '15%' },
                    minWidth: '70px',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                  onClick={handleStatusSort}
                >
                  상태 {statusSortDirection === 'asc' ? '↑' : statusSortDirection === 'desc' ? '↓' : ''}
                </TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '80px', md: '15%' },
                    minWidth: '80px',
                    whiteSpace: 'nowrap'
                  }}
                >읽은이</TableCell>
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: { xs: '120px', md: '15%' },
                    minWidth: '120px',
                    whiteSpace: 'nowrap'
                  }}
                >읽은시간</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{row.time}</TableCell>
                  <TableCell sx={{ 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{row.content}</TableCell>
                  <TableCell align="center">
                    <Box 
                      onClick={() => handleStatusToggle(index)}
                      sx={{ 
                        bgcolor: row.status === '읽음' ? '#4caf50' : '#f44336',
                        color: 'white',
                        borderRadius: '12px',
                        px: 2,
                        py: 0.5,
                        display: 'inline-block',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.9
                        },
                        transition: 'opacity 0.2s'
                      }}
                    >
                      {row.status}
                    </Box>
                  </TableCell>
                  <TableCell align="center">{row.reader}</TableCell>
                  <TableCell align="center">{row.readTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <SummaryData />
    </Box>
  );
}

export default AlarmHistory;
