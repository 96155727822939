import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  IconButton, 
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  styled
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description';
import SearchIcon from '@mui/icons-material/Search';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '16px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  marginBottom: '16px'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px',
  whiteSpace: 'nowrap',
  ['&.MuiTableCell-head']: {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
  }
}));

const UnconfirmedDeposits = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headers = [
    { id: 'depositorName', label: '입금자명' },
    { id: 'amount', label: '금액', align: 'right' },
    { id: 'tid', label: 'TID' },
    { id: 'merchantId', label: '머천트ID' },
    { id: 'depositDate', label: '입금일시', align: 'center' }
  ];

  const rows = [
    {
      depositorName: '페이업주식회사',
      amount: '38,140,858',
      tid: '203803433',
      merchantId: '742',
      depositDate: '2024-12-11 10:15:00'
    }
  ];

  return (
    <Box sx={{ p: 2 }}>
      <StyledBox>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontSize: '1.1rem',
            fontWeight: 600,
            color: '#1976d2'
          }}
        >
          <DescriptionIcon sx={{ fontSize: '1.4rem' }} />
          미확인입금내역
        </Typography>
      </StyledBox>

      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2, 
        mb: 2,
        flexWrap: 'wrap'
      }}>
        <TextField
          size="small"
          placeholder="모든 필드를 검색합니다"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1, maxWidth: '300px' }}
        />

        <Tooltip title="검색">
          <IconButton
            sx={{
              backgroundColor: '#1976d2',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#1565c0',
              }
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="엑셀 다운로드">
          <IconButton
            sx={{
              backgroundColor: '#fff',
              border: '1px solid #2e7d32',
              color: '#2e7d32',
              '&:hover': {
                backgroundColor: '#2e7d3210',
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)' }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell
                  key={header.id}
                  align={header.align || 'left'}
                  sortDirection={orderBy === header.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={() => handleSort(header.id)}
                  >
                    {header.label}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} hover>
                <StyledTableCell>{row.depositorName}</StyledTableCell>
                <StyledTableCell align="right">{row.amount}</StyledTableCell>
                <StyledTableCell>{row.tid}</StyledTableCell>
                <StyledTableCell>{row.merchantId}</StyledTableCell>
                <StyledTableCell align="center">{row.depositDate}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UnconfirmedDeposits;