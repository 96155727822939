import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  Typography, 
  TextField,
  Button,
  Grid
} from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const WalletManagement = () => {
  // 첫 번째 섹션 상태
  const [merchant, setMerchant] = useState('');
  const [name, setName] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  // 두 번째 섹션 상태
  const [contact, setContact] = useState('');
  const [walletHyphen, setWalletHyphen] = useState('');
  const [walletWelcome, setWalletWelcome] = useState('');

  // 세 번째 섹션 상태
  const [wp_hash, setWpHash] = useState('');
  const [enckey, setEnckey] = useState('');
  const [ivt, setIvt] = useState('');
  const [guid, setGuid] = useState('');
  const [sendingNumber, setSendingNumber] = useState('');
  const [resultSendingNumber, setResultSendingNumber] = useState('');

  return (
    <Card sx={{ mt: 4, borderRadius: '12px', boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)' }}>
      <Box sx={{ p: 3 }}>
        {/* 제목 */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <AccountBalanceWalletIcon sx={{ color: '#9c27b0' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            월렛 정보관리
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* 첫 번째 섹션 - 왼쪽 */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>기본 정보</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  size="small"
                  label="머천트"
                  value={merchant}
                  onChange={(e) => setMerchant(e.target.value)}
                />
                <TextField
                  size="small"
                  label="호명"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  size="small"
                  label="은행명"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
                <TextField
                  size="small"
                  label="계좌번호"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </Box>
            </Box>
          </Grid>

          {/* 첫 번째 섹션 - 오른쪽 */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>연락처 및 월렛 정보</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  size="small"
                  label="연락처"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
                <TextField
                  size="small"
                  label="월렛입출(하이픈)"
                  value={walletHyphen}
                  onChange={(e) => setWalletHyphen(e.target.value)}
                />
                <TextField
                  size="small"
                  label="월렛입출(웰컴)"
                  value={walletWelcome}
                  onChange={(e) => setWalletWelcome(e.target.value)}
                />
              </Box>
            </Box>
          </Grid>

          {/* 두 번째 섹션 */}
          <Grid item xs={12}>
            <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>추가 정보</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  size="small"
                  label="wp_hash(msalt)"
                  value={wp_hash}
                  onChange={(e) => setWpHash(e.target.value)}
                />
                <TextField
                  size="small"
                  label="enckey(tkey)"
                  value={enckey}
                  onChange={(e) => setEnckey(e.target.value)}
                />
                <TextField
                  size="small"
                  label="ivt(kscode)"
                  value={ivt}
                  onChange={(e) => setIvt(e.target.value)}
                />
                <TextField
                  size="small"
                  label="guid(compCode)"
                  value={guid}
                  onChange={(e) => setGuid(e.target.value)}
                />
                <TextField
                  size="small"
                  label="송금수수료(원)"
                  value={sendingNumber}
                  onChange={(e) => setSendingNumber(e.target.value)}
                />
                <TextField
                  size="small"
                  label="월렛송금수수료(원)"
                  value={resultSendingNumber}
                  onChange={(e) => setResultSendingNumber(e.target.value)}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* 적용 버튼 */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button 
            variant="contained"
            sx={{ 
              bgcolor: '#51cbce',
              '&:hover': {
                bgcolor: '#45b0b3'
              }
            }}
          >
            적용
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default WalletManagement;
