import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { styled } from '@mui/material/styles';

// 스타일링된 아이콘 버튼
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '&.receipt-icon': {
    color: '#51cbce',
    '&:hover': {
      color: '#34b5b8',
    }
  },
  // 추후 다른 아이콘들을 위한 클래스 추가 가능
}));

// 영수증 아이콘 컴포넌트
export const ReceiptIconButton = ({ onClick, tooltipTitle = '영수증' }) => {
  return (
    <Tooltip title={tooltipTitle} arrow placement="top">
      <StyledIconButton
        onClick={onClick}
        className="receipt-icon"
        id="receipt-icon-button"
        size="small"
      >
        <ReceiptIcon fontSize="small" />
      </StyledIconButton>
    </Tooltip>
  );
};

// 모든 아이콘 컴포넌트들을 하나의 객체로 내보내기
const PlusIcons = {
  ReceiptIconButton,
  // 추후 다른 아이콘 컴포넌트들 추가 가능
};

export default PlusIcons;
