import React, { useState, useEffect } from 'react';
import { 
  Box, 
  useMediaQuery,
  useTheme,
  Typography
} from '@mui/material';

// 필요한 컴포넌트들 import
import Notice from '../../Notice/Notice';
import SummaryData from '../../Common/SummaryData';
import SearchArea from '../../Common/SettlementSearch';
import OutpuyTable from './OutputTable';
import SettlementDownButton from '../../Common/SettlementDownButton';
import SummaryTable from './SummaryTable';
import SecurityCheck from './SecurityCheck';
import WalletInOutTable from './WalletInOutTable';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    position: 'relative'
  },
  noticeBox: { 
    width: 'calc(100% - 20px)', 
    margin: '0 auto', 
    mb: 2 
  },
  tableContainer: {
    marginLeft: { xs: '10px', md: '40px' },
    marginRight: { xs: '10px', md: '40px' },
    maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 400px)' },
    overflowY: 'auto',
    overflowX: 'auto',
    width: '100%',
    backgroundColor: '#fff', 
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)', 
  }
};

function WalletInOutHistory() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  useEffect(() => {
    console.log('WalletInOutHistory 컴포넌트가 마운트되었습니다.');
    document.title = '월렛입출금 내역'; // 페이지 타이틀 설정
  }, []);

  // 동적 스타일 계산
  const mainBoxStyle = {
    ...styles.mainBox,
    pl: {
      xs: '10px',
      md: '50px'
    },
    mx: {
      xs: '10px',
      md: '50px'
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      

      {/* SummaryData 컴포넌트는 항상 표시 */}
      <SummaryData />

      {/* Notice 영역 */}
      <Box sx={styles.noticeBox}>
        <Notice />
      </Box>

      {/* 검색 영역 추가 */}
      <Box sx={{ mb: 2 }}>
        <SearchArea />
      </Box>

      {/* ResultText */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          <OutpuyTable/>
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          <SettlementDownButton/>
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          <SummaryTable/>
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          <SecurityCheck/>
        </Typography>
      </Box>


      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="text.secondary">
          <WalletInOutTable/>
        </Typography>
      </Box>

    </Box>
  );
}

export default WalletInOutHistory;
