import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, Grid, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { useMediaQuery } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// 스타일 정의
const useStyles = makeStyles((theme) => ({
  statsCard: {
    marginBottom: '20px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    backgroundColor: '#fff',
    transition: 'transform 0.2s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
      transform: 'translateY(-4px)'
    }
  },
  statsHeader: {
    color: '#428bca',
    padding: '10px',
    width: '100%',
    margin: '0 auto',
    fontSize: '2rem',
    fontWeight: 700,
    background: '#f4f4f4',
    border: '1px solid #dddddd',
    borderBottom: 'none',
    textAlign: 'center',
  },
  statsHeaderDanger: {
    color: '#ff3333',
    padding: '10px',
    width: '100%',
    margin: '0 auto',
    marginTop: '0px',
    fontSize: '2rem',
    fontWeight: 700,
    background: '#f4f4f4',
    border: '1px solid #dddddd',
    borderTop: 'none',
    borderBottom: 'none',
    textAlign: 'center',
  },
  statsRow: {
    display: 'flex',
    border: '1px solid #DDDDDD',
    padding: '17px',
    margin: 0,
    borderTop: 'none',
    '&:last-child': {
      borderBottom: '1px solid #DDDDDD',
    },
  },
  statsLabel: {
    flex: 1,
    fontSize: '2rem',
    textAlign: 'left',
  },
  statsValue: {
    flex: 3,
    fontSize: '1.8rem',
    textAlign: 'right',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600,
    color: '#2c3e50',
    letterSpacing: '0.5px'
  },
  statsValuePrimary: {
    flex: 3,
    fontSize: '1.8rem',
    textAlign: 'right',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600,
    color: '#428bca',
    letterSpacing: '0.5px'
  },
  statsValueDanger: {
    flex: 3,
    fontSize: '1.8rem',
    textAlign: 'right',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600,
    color: '#ff3333',
    letterSpacing: '0.5px'
  },
  chartCard: {
    height: '400px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    backgroundColor: '#fff',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateY(-4px)'
    }
  },
  chartHeader: {
    padding: '15px',
    '& h4': {
      margin: 0,
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  },
  chartContainer: {
    position: 'relative',
    height: 'calc(100% - 60px)',
    padding: '15px',
  },
}));

// ChartJS 컴포넌트 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MonthlyStats = ({ isEmbedded = false }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:780px)');

  const monthlyData = {
    total: "1,689,991,365",
    manual: "916,972,622",
    terminal: "773,018,743",
    online: "0",
    settlement: "43,771,424"
  };

  // 차트 데이터 3개로 수정
  const chartData = {
    labels: ["1주", "2주", "3주", "4주"],
    datasets: [
      {
        label: "단말기",
        borderColor: "#428bca",
        backgroundColor: "rgba(66, 139, 202, 0.05)",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#428bca",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        borderWidth: 2,
        data: [400, 600, 450, 700]
      },
      {
        label: "수기",
        borderColor: "#ff3333",
        backgroundColor: "rgba(255, 51, 51, 0.05)",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#ff3333",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        borderWidth: 2,
        data: [300, 400, 300, 400]
      },
      {
        label: "온라인",
        borderColor: "#4CAF50",
        backgroundColor: "rgba(76, 175, 80, 0.05)",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#4CAF50",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        fill: true,
        borderWidth: 2,
        data: [100, 200, 150, 250]
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#666666',
          font: {
            size: 12
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#666666",
          font: {
            size: 11
          }
        }
      },
      x: {
        ticks: {
          color: "#666666",
          font: {
            size: 11
          }
        }
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: isEmbedded ? 0 : 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{
          width: '100%',
          flexBasis: isMobile ? '100%' : 'auto'
        }}>
          <Card className={classes.statsCard} sx={{ height: '100%' }}>
            <Box className={classes.statsHeader}>
              <Typography>이번달 거래액</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}>합계</Typography>
              <Typography className={classes.statsValuePrimary}>{monthlyData.total}원</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}>수기</Typography>
              <Typography className={classes.statsValue}>{monthlyData.manual}원</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}>단말기</Typography>
              <Typography className={classes.statsValue}>{monthlyData.terminal}원</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}>온라인</Typography>
              <Typography className={classes.statsValue}>{monthlyData.online}원</Typography>
            </Box>
            <Box className={classes.statsHeaderDanger} sx={{ borderTop: '1px solid #dddddd' }}>
              <Typography>이번달 정산예정액</Typography>
            </Box>
            <Box className={classes.statsRow}>
              <Typography className={classes.statsLabel}></Typography>
              <Typography className={classes.statsValueDanger}>{monthlyData.settlement}원</Typography>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} sx={{
          width: '100%',
          flexBasis: isMobile ? '100%' : 'auto'
        }}>
          <Card className={classes.chartCard} sx={{ 
            height: isMobile ? '400px' : '100%',
            minHeight: isMobile ? '400px' : 'auto'
          }}>
            <Box className={classes.chartHeader}>
              <Typography variant="h4">주간 거래 추이</Typography>
            </Box>
            <Box className={classes.chartContainer}>
              <Line data={chartData} options={{
                ...chartOptions,
                maintainAspectRatio: false,
                responsive: true
              }} />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MonthlyStats;