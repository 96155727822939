import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Button,
  Typography,
  Tooltip
} from '@mui/material';
import {
  CheckCircleOutline as CheckIcon,
  DeleteOutline as DeleteIcon,
  SelectAll as SelectAllIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';
import Notice from '../../Notice/Notice';
import SummaryData from '../../Common/SummaryData';

// 스타일 객체
const styles = {
  mainBox: {
    p: 3,
    transition: 'padding 0.3s ease',
    position: 'relative'
  },
  noticeBox: {
    mb: 3
  }
};

// 스타일드 컴포넌트
const StyledTableCell = styled(TableCell)({
  padding: '12px 16px',
  borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
  ml : '80px',
  '&.header': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#1976d2'
  },
  '@media (max-width: 600px)': {
    padding: '8px',
    fontSize: '0.8rem'
  }
});

const ActionButton = styled(Button)({
  margin: '0 4px',
  textTransform: 'none',
  minWidth: 'unset',
  padding: '6px 12px',
  '& .MuiSvgIcon-root': {
    marginRight: '4px'
  },
  '@media (max-width: 600px)': {
    padding: '4px 8px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem'
    }
  }
});

// 모바일 테이블 행 스타일 수정
const MobileTableRow = styled(TableRow)({
  '& td': {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px',
    whiteSpace: 'nowrap',
    '& > *': {
      marginRight: '8px'
    }
  }
});

// 모바일 버튼 컨테이너 스타일
const MobileButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  padding: '8px 16px',
  gap: '8px'
});

// 모바일 테이블 셀 스타일 수정
const MobileTableCell = styled(TableCell)({
  padding: '12px 16px',
  whiteSpace: 'nowrap',
  '&.header': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#1976d2'
  }
});

// 공통 스타일 정의
const columnStyles = {
  no: { width: '40px' },
  galaxia: { width: '80px' },
  description: { width: '120px' },
  amount: { width: '100px' },
  date: { width: '140px' },
  status: { width: '80px' },
  reference: { width: '100px' }
};

// 날짜 포맷팅 함수 추가
const formatDateTime = (dateString) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  const hour = dateString.substring(8, 10);
  const minute = dateString.substring(10, 12);
  const second = dateString.substring(12, 14);
  
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

function ErrorLog() {
  const [selected, setSelected] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  
  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 전체 선택 핸들러
  const handleSelectAll = () => {
    if (selected.length === dummyData.length) {
      setSelected([]);
    } else {
      setSelected(dummyData.map(row => row.id));
    }
  };

  // 개별 선택 핸들러
  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter(item => item !== id);
    }

    setSelected(newSelected);
  };

  // 더미 데이터 확장
  const dummyData = [
    {
      id: 1,
      galaxia: 'Galaxia',
      description: '또봉이 통닭',
      amount: '-42,000',
      date: '20241205214728',
      status: 'REFUND',
      reference: '46649719'
    },
    {
      id: 2,
      galaxia: 'Galaxia',
      description: '맘스터치',
      amount: '-15,000',
      date: '20241205213512',
      status: 'REFUND',
      reference: '46649720'
    },
    {
      id: 3,
      galaxia: 'Galaxia',
      description: '스타벅스',
      amount: '-8,500',
      date: '20241205212845',
      status: 'REFUND',
      reference: '46649721'
    },
    {
      id: 4,
      galaxia: 'Galaxia',
      description: 'GS25',
      amount: '-12,500',
      date: '20241205211923',
      status: 'REFUND',
      reference: '46649722'
    },
    {
      id: 5,
      galaxia: 'Galaxia',
      description: '파리바게트',
      amount: '-22,000',
      date: '20241205211056',
      status: 'REFUND',
      reference: '46649723'
    },
    {
      id: 6,
      galaxia: 'Galaxia',
      description: '버거킹',
      amount: '-18,900',
      date: '20241205205847',
      status: 'REFUND',
      reference: '46649724'
    },
    {
      id: 7,
      galaxia: 'Galaxia',
      description: 'CU편의점',
      amount: '-9,800',
      date: '20241205204532',
      status: 'REFUND',
      reference: '46649725'
    },
    {
      id: 8,
      galaxia: 'Galaxia',
      description: '롯데리아',
      amount: '-16,500',
      date: '20241205203421',
      status: 'REFUND',
      reference: '46649726'
    },
    {
      id: 9,
      galaxia: 'Galaxia',
      description: '이디야커피',
      amount: '-5,800',
      date: '20241205202156',
      status: 'REFUND',
      reference: '46649727'
    },
    {
      id: 10,
      galaxia: 'Galaxia',
      description: '배스킨라빈스',
      amount: '-28,000',
      date: '20241205201034',
      status: 'REFUND',
      reference: '46649728'
    },
  ];

  // 모바일 테이블 렌더링 수정
  const renderMobileTable = () => (
    <>
      {/* 모바일 버튼 영역 */}
      <MobileButtonContainer>
        <Tooltip title="전체선택">
          <IconButton onClick={handleSelectAll} color="primary" size="small">
            <SelectAllIcon />
          </IconButton>
        </Tooltip>
        <ActionButton
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          size="small"
        >
          검증
        </ActionButton>
        <ActionButton
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          size="small"
        >
          삭제
        </ActionButton>
      </MobileButtonContainer>

      {/* 모바일 테이블 */}
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <MobileTableCell padding="checkbox" width="48px" />
              <MobileTableCell className="header">
                <Box sx={{ display: 'flex', minWidth: 'max-content' }}>
                  <Box sx={columnStyles.no}>No</Box>
                  <Box sx={columnStyles.galaxia}>거래구분</Box>
                  <Box sx={columnStyles.description}>가맹점명</Box>
                  <Box sx={columnStyles.amount}>거래금액</Box>
                  <Box sx={columnStyles.date}>거래일시</Box>
                  <Box sx={columnStyles.status}>취소여부</Box>
                  <Box sx={columnStyles.reference}>승인번호</Box>
                </Box>
              </MobileTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row, index) => (
              <TableRow key={row.id}>
                <MobileTableCell padding="checkbox" width="48px">
                  <Checkbox
                    checked={selected.includes(row.id)}
                    onChange={() => handleSelect(row.id)}
                  />
                </MobileTableCell>
                <MobileTableCell>
                  <Box sx={{ display: 'flex', minWidth: 'max-content' }}>
                    <Box sx={columnStyles.no}>{index + 1}</Box>
                    <Box sx={columnStyles.galaxia}>{row.galaxia}</Box>
                    <Box sx={columnStyles.description}>{row.description}</Box>
                    <Box sx={columnStyles.amount}>{row.amount}</Box>
                    <Box sx={columnStyles.date}>{formatDateTime(row.date)}</Box>
                    <Box sx={columnStyles.status}>{row.status}</Box>
                    <Box sx={columnStyles.reference}>{row.reference}</Box>
                  </Box>
                </MobileTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );

  return (
    <Box component="main" sx={styles.mainBox}>
      <Box sx={{ mx: { xs: '10px', sm: '90px' } }}> {/* 반응형 마진 */}
        <Box sx={styles.noticeBox}>
          <Notice />
        </Box>

        <Paper elevation={2}>
          {/* 데스크톱/모바일 분기 */}
          {isMobile ? renderMobileTable() : (
            <>
              <Box sx={{ 
                p: 2, 
                display: 'flex', 
                alignItems: 'center',
                gap: 1
              }}>
                <Tooltip title="전체선택">
                  <IconButton onClick={handleSelectAll} color="primary">
                    <SelectAllIcon />
                  </IconButton>
                </Tooltip>
                <ActionButton
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                >
                  검색
                </ActionButton>
                <ActionButton
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  삭제
                </ActionButton>
              </Box>
              <Box sx={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="header" padding="checkbox" />
                      <StyledTableCell className="header">No</StyledTableCell>
                      <StyledTableCell className="header">거래구분</StyledTableCell>
                      <StyledTableCell className="header">가맹점명</StyledTableCell>
                      <StyledTableCell className="header">거래금액</StyledTableCell>
                      <StyledTableCell className="header">거래일시</StyledTableCell>
                      <StyledTableCell className="header">취소여부</StyledTableCell>
                      <StyledTableCell className="header">승인번호</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dummyData.map((row, index) => (
                      <TableRow 
                        key={row.id}
                        hover
                        selected={selected.includes(row.id)}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            checked={selected.includes(row.id)}
                            onChange={() => handleSelect(row.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{row.galaxia}</StyledTableCell>
                        <StyledTableCell>{row.description}</StyledTableCell>
                        <StyledTableCell>{row.amount}</StyledTableCell>
                        <StyledTableCell>{formatDateTime(row.date)}</StyledTableCell>
                        <StyledTableCell>{row.status}</StyledTableCell>
                        <StyledTableCell>{row.reference}</StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </>
          )}
        </Paper>
      </Box>

      <SummaryData />
    </Box>
  );
}

export default ErrorLog;
