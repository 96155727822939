import React, { useState } from 'react';
import { Box, Card, Grid, Typography, Table, TableBody, TableCell, 
         TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { useStyles } from './styles';

const TopTenData = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:800px) and (max-width:1025px)');
  const shouldScroll = isMobile || isTablet;
  
  // 전체 결제 데이터
  const totalPaymentData = [
    {
      rank: 1,
      company: '(주)농부고을',
      sales: '네오플랜 윤병원',
      accountName: 'm21644470',
      paymentCount: 94,
      paymentAmount: 166569000,
      cancelAmount: -3400000,
      actualAmount: 163169000,
      paymentType: '전체'
    },
    {
      rank: 2,
      company: '쉬즈위시의원',
      sales: 'B본사',
      accountName: '107836',
      paymentCount: 369,
      paymentAmount: 107050000,
      cancelAmount: -9238200,
      actualAmount: 97811800,
      paymentType: '전체'
    },
    {
      rank: 3,
      company: '더조은약국',
      sales: '서울지사',
      accountName: 'm21654321',
      paymentCount: 285,
      paymentAmount: 98750000,
      cancelAmount: -2150000,
      actualAmount: 96600000,
      paymentType: '전체'
    },
    {
      rank: 4,
      company: '굿모닝병원',
      sales: '경기지사',
      accountName: 'm21987654',
      paymentCount: 246,
      paymentAmount: 87650000,
      cancelAmount: -1890000,
      actualAmount: 85760000,
      paymentType: '전체'
    },
    {
      rank: 5,
      company: '행복한치과',
      sales: '인천지사',
      accountName: 'm21456789',
      paymentCount: 198,
      paymentAmount: 76540000,
      cancelAmount: -2340000,
      actualAmount: 74200000,
      paymentType: '전체'
    },
    {
      rank: 6,
      company: '미소약국',
      sales: '부산지사',
      accountName: 'm21234567',
      paymentCount: 167,
      paymentAmount: 65430000,
      cancelAmount: -1780000,
      actualAmount: 63650000,
      paymentType: '체'
    },
    {
      rank: 7,
      company: '연세의원',
      sales: '대구지사',
      accountName: 'm21345678',
      paymentCount: 145,
      paymentAmount: 54320000,
      cancelAmount: -1230000,
      actualAmount: 53090000,
      paymentType: '전체'
    },
    {
      rank: 8,
      company: '리들병원',
      sales: '광주지사',
      accountName: 'm21567890',
      paymentCount: 123,
      paymentAmount: 43210000,
      cancelAmount: -980000,
      actualAmount: 42230000,
      paymentType: '전체'
    },
    {
      rank: 9,
      company: '새봄약국',
      sales: '대전지사',
      accountName: 'm21678901',
      paymentCount: 98,
      paymentAmount: 32100000,
      cancelAmount: -760000,
      actualAmount: 31340000,
      paymentType: '전체'
    },
    {
      rank: 10,
      company: '하나의원',
      sales: '울산지사',
      accountName: 'm21789012',
      paymentCount: 76,
      paymentAmount: 21000000,
      cancelAmount: -450000,
      actualAmount: 20550000,
      paymentType: '전체'
    }
  ];

  // 수기 결제 데이터
  const manualPaymentData = [
    {
      rank: 1,
      company: '(주)농부고을',
      sales: '네오플랜 윤병원',
      accountName: 'm21644470',
      paymentCount: 94,
      paymentAmount: 166569000,
      cancelAmount: -3400000,
      actualAmount: 163169000,
      paymentType: '수기'
    },
    {
      rank: 2,
      company: '쉬즈위시의원',
      sales: 'B본사',
      accountName: '107836',
      paymentCount: 289,
      paymentAmount: 98750000,
      cancelAmount: -8238200,
      actualAmount: 90511800,
      paymentType: '수기'
    },
    {
      rank: 3,
      company: '더조은약국',
      sales: '서울지사',
      accountName: 'm21654321',
      paymentCount: 285,
      paymentAmount: 98750000,
      cancelAmount: -2150000,
      actualAmount: 96600000,
      paymentType: '수기'
    },
    {
      rank: 4,
      company: '굿모닝병원',
      sales: '경기지사',
      accountName: 'm21987654',
      paymentCount: 246,
      paymentAmount: 87650000,
      cancelAmount: -1890000,
      actualAmount: 85760000,
      paymentType: '수기'
    },
    {
      rank: 5,
      company: '행복한치과',
      sales: '인천지사',
      accountName: 'm21456789',
      paymentCount: 198,
      paymentAmount: 76540000,
      cancelAmount: -2340000,
      actualAmount: 74200000,
      paymentType: '수기'
    },
    {
      rank: 6,
      company: '미소약국',
      sales: '부산지사',
      accountName: 'm21234567',
      paymentCount: 167,
      paymentAmount: 65430000,
      cancelAmount: -1780000,
      actualAmount: 63650000,
      paymentType: '수기'
    },
    {
      rank: 7,
      company: '연세의원',
      sales: '대구지사',
      accountName: 'm21345678',
      paymentCount: 145,
      paymentAmount: 54320000,
      cancelAmount: -1230000,
      actualAmount: 53090000,
      paymentType: '수기'
    },
    {
      rank: 8,
      company: '우리들병원',
      sales: '광주지사',
      accountName: 'm21567890',
      paymentCount: 123,
      paymentAmount: 43210000,
      cancelAmount: -980000,
      actualAmount: 42230000,
      paymentType: '수기'
    },
    {
      rank: 9,
      company: '새봄약국',
      sales: '대전지사',
      accountName: 'm21678901',
      paymentCount: 98,
      paymentAmount: 32100000,
      cancelAmount: -760000,
      actualAmount: 31340000,
      paymentType: '수기'
    },
    {
      rank: 10,
      company: '하나의원',
      sales: '울산지사',
      accountName: 'm21789012',
      paymentCount: 76,
      paymentAmount: 21000000,
      cancelAmount: -450000,
      actualAmount: 20550000,
      paymentType: '수기'
    }
  ];

  // 단말기 결제 데이터
  const terminalPaymentData = [
    {
      rank: 1,
      company: '쉬즈위시의원_단말(코밴)',
      sales: 'B본사',
      accountName: '107836',
      paymentCount: 369,
      paymentAmount: 107050000,
      cancelAmount: -9238200,
      actualAmount: 97811800,
      paymentType: '단말기'
    },
    {
      rank: 2,
      company: '쉬즈위시의원_단말(코밴)',
      sales: 'B본사',
      accountName: '107836',
      paymentCount: 289,
      paymentAmount: 98750000,
      cancelAmount: -8238200,
      actualAmount: 90511800,
      paymentType: '단말기'
    },
    {
      rank: 3,
      company: '더조은약국_단말(코밴)',
      sales: '서울지사',
      accountName: 'm21654321',
      paymentCount: 285,
      paymentAmount: 98750000,
      cancelAmount: -2150000,
      actualAmount: 96600000,
      paymentType: '단말기'
    },
    {
      rank: 4,
      company: '굿모닝병원_단말(코밴)',
      sales: '경기지사',
      accountName: 'm21987654',
      paymentCount: 246,
      paymentAmount: 87650000,
      cancelAmount: -1890000,
      actualAmount: 85760000,
      paymentType: '단말기'
    },
    {
      rank: 5,
      company: '행복한치과_단말(코밴)',
      sales: '인천지사',
      accountName: 'm21456789',
      paymentCount: 198,
      paymentAmount: 76540000,
      cancelAmount: -2340000,
      actualAmount: 74200000,
      paymentType: '단말기'
    },
    {
      rank: 6,
      company: '미소약국_단말(코밴)',
      sales: '부산지사',
      accountName: 'm21234567',
      paymentCount: 167,
      paymentAmount: 65430000,
      cancelAmount: -1780000,
      actualAmount: 63650000,
      paymentType: '단말기'
    },
    {
      rank: 7,
      company: '연세의원_단말(코밴)',
      sales: '대구지사',
      accountName: 'm21345678',
      paymentCount: 145,
      paymentAmount: 54320000,
      cancelAmount: -1230000,
      actualAmount: 53090000,
      paymentType: '단말기'
    },
    {
      rank: 8,
      company: '우리들병원_단말(코밴)',
      sales: '광주지사',
      accountName: 'm21567890',
      paymentCount: 123,
      paymentAmount: 43210000,
      cancelAmount: -980000,
      actualAmount: 42230000,
      paymentType: '단말기'
    },
    {
      rank: 9,
      company: '봄약국_단말(코밴)',
      sales: '대전지사',
      accountName: 'm21678901',
      paymentCount: 98,
      paymentAmount: 32100000,
      cancelAmount: -760000,
      actualAmount: 31340000,
      paymentType: '단말기'
    },
    {
      rank: 10,
      company: '미래약국_단말(코밴)',
      sales: '강원지사',
      accountName: 'm21890123',
      paymentCount: 56,
      paymentAmount: 18000000,
      cancelAmount: -420000,
      actualAmount: 17580000,
      paymentType: '단말기'
    }
  ];

  // 온라인 결제 데이터
  const onlinePaymentData = [
    {
      rank: 1,
      company: '온라인몰(주)',
      sales: '서울본사',
      accountName: 'o21644470',
      paymentCount: 456,
      paymentAmount: 187569000,
      cancelAmount: -4400000,
      actualAmount: 183169000,
      paymentType: '온라인'
    },
    {
      rank: 2,
      company: '온라인몰(주)',
      sales: '서울본사',
      accountName: 'o21644470',
      paymentCount: 289,
      paymentAmount: 98750000,
      cancelAmount: -8238200,
      actualAmount: 90511800,
      paymentType: '온라인'
    },
    {
      rank: 3,
      company: '더조은약국_온라인',
      sales: '서울지사',
      accountName: 'm21654321',
      paymentCount: 285,
      paymentAmount: 98750000,
      cancelAmount: -2150000,
      actualAmount: 96600000,
      paymentType: '온라인'
    },
    {
      rank: 4,
      company: '굿모닝병원_온라인',
      sales: '경기지사',
      accountName: 'm21987654',
      paymentCount: 246,
      paymentAmount: 87650000,
      cancelAmount: -1890000,
      actualAmount: 85760000,
      paymentType: '온라인'
    },
    {
      rank: 5,
      company: '행복한치과_온라인',
      sales: '인천지사',
      accountName: 'm21456789',
      paymentCount: 198,
      paymentAmount: 76540000,
      cancelAmount: -2340000,
      actualAmount: 74200000,
      paymentType: '온라인'
    },
    {
      rank: 6,
      company: '미소약국_온라인',
      sales: '부산지사',
      accountName: 'm21234567',
      paymentCount: 167,
      paymentAmount: 65430000,
      cancelAmount: -1780000,
      actualAmount: 63650000,
      paymentType: '온라인'
    },
    {
      rank: 7,
      company: '연세의원_온라인',
      sales: '대구지사',
      accountName: 'm21345678',
      paymentCount: 145,
      paymentAmount: 54320000,
      cancelAmount: -1230000,
      actualAmount: 53090000,
      paymentType: '온라인'
    },
    {
      rank: 8,
      company: '우리들병원_온라인',
      sales: '광주지사',
      accountName: 'm21567890',
      paymentCount: 123,
      paymentAmount: 43210000,
      cancelAmount: -980000,
      actualAmount: 42230000,
      paymentType: '온라인'
    },
    {
      rank: 9,
      company: '새봄약국_온라인',
      sales: '대전지사',
      accountName: 'm21678901',
      paymentCount: 98,
      paymentAmount: 32100000,
      cancelAmount: -760000,
      actualAmount: 31340000,
      paymentType: '온라인'
    },
    {
      rank: 10,
      company: '스마트쇼핑',
      sales: '제주지사',
      accountName: 'o21789012',
      paymentCount: 89,
      paymentAmount: 25000000,
      cancelAmount: -550000,
      actualAmount: 24450000,
      paymentType: '온라인'
    }
  ];

  // 취소 결제 데이터
  const cancelPaymentData = [
    {
      rank: 1,
      company: '(주)리턴몰',
      sales: '서울본사',
      accountName: 'c21644470',
      paymentCount: 78,
      paymentAmount: 145569000,
      cancelAmount: -12400000,
      actualAmount: 133169000,
      paymentType: '취소'
    },
    {
      rank: 2,
      company: '(주)리턴몰',
      sales: '서울본사',
      accountName: 'c21644470',
      paymentCount: 289,
      paymentAmount: 98750000,
      cancelAmount: -8238200,
      actualAmount: 90511800,
      paymentType: '취소'
    },
    {
      rank: 3,
      company: '더조은약국_취소',
      sales: '서울지사',
      accountName: 'm21654321',
      paymentCount: 285,
      paymentAmount: 98750000,
      cancelAmount: -2150000,
      actualAmount: 96600000,
      paymentType: '취소'
    },
    {
      rank: 4,
      company: '굿모닝병원_취소',
      sales: '경기지사',
      accountName: 'm21987654',
      paymentCount: 246,
      paymentAmount: 87650000,
      cancelAmount: -1890000,
      actualAmount: 85760000,
      paymentType: '취소'
    },
    {
      rank: 5,
      company: '행복한치과_취소',
      sales: '인천지사',
      accountName: 'm21456789',
      paymentCount: 198,
      paymentAmount: 76540000,
      cancelAmount: -2340000,
      actualAmount: 74200000,
      paymentType: '취소'
    },
    {
      rank: 6,
      company: '미소약국_취소',
      sales: '부산지사',
      accountName: 'm21234567',
      paymentCount: 167,
      paymentAmount: 65430000,
      cancelAmount: -1780000,
      actualAmount: 63650000,
      paymentType: '취소'
    },
    {
      rank: 7,
      company: '연세의원_취소',
      sales: '대구지사',
      accountName: 'm21345678',
      paymentCount: 145,
      paymentAmount: 54320000,
      cancelAmount: -1230000,
      actualAmount: 53090000,
      paymentType: '취소'
    },
    {
      rank: 8,
      company: '우리들병원_취소',
      sales: '광주지사',
      accountName: 'm21567890',
      paymentCount: 123,
      paymentAmount: 43210000,
      cancelAmount: -980000,
      actualAmount: 42230000,
      paymentType: '취소'
    },
    {
      rank: 9,
      company: '새봄약국_취소',
      sales: '대전지사',
      accountName: 'm21678901',
      paymentCount: 98,
      paymentAmount: 32100000,
      cancelAmount: -760000,
      actualAmount: 31340000,
      paymentType: '취소'
    },
    {
      rank: 10,
      company: '환불약국',
      sales: '제주지사',
      accountName: 'c21789012',
      paymentCount: 34,
      paymentAmount: 12000000,
      cancelAmount: -8450000,
      actualAmount: 3550000,
      paymentType: '취소'
    }
  ];

  // 수기 취소 결제 데이터
  const manualCancelData = [
    {
      rank: 1,
      company: '(주)농부고을',
      sales: '네오플랜 윤병원',
      accountName: 'm21644470',
      paymentCount: 94,
      paymentAmount: 166569000,
      cancelAmount: -3400000,
      actualAmount: 163169000,
      paymentType: '수기'
    },
    {
      rank: 2,
      company: '쉬즈위시의원',
      sales: 'B본사',
      accountName: '107836',
      paymentCount: 289,
      paymentAmount: 98750000,
      cancelAmount: -8238200,
      actualAmount: 90511800,
      paymentType: '���기'
    },
    {
      rank: 3,
      company: '더조은약국',
      sales: '서울지사',
      accountName: 'm21654321',
      paymentCount: 285,
      paymentAmount: 98750000,
      cancelAmount: -2150000,
      actualAmount: 96600000,
      paymentType: '수기'
    },
    {
      rank: 4,
      company: '굿모닝병원',
      sales: '경기지사',
      accountName: 'm21987654',
      paymentCount: 246,
      paymentAmount: 87650000,
      cancelAmount: -1890000,
      actualAmount: 85760000,
      paymentType: '수기'
    },
    {
      rank: 5,
      company: '행복한치과',
      sales: '인천지사',
      accountName: 'm21456789',
      paymentCount: 198,
      paymentAmount: 76540000,
      cancelAmount: -2340000,
      actualAmount: 74200000,
      paymentType: '수기'
    },
    {
      rank: 6,
      company: '미소약국',
      sales: '부산지사',
      accountName: 'm21234567',
      paymentCount: 167,
      paymentAmount: 65430000,
      cancelAmount: -1780000,
      actualAmount: 63650000,
      paymentType: '수기'
    },
    {
      rank: 7,
      company: '연세의원',
      sales: '대구지사',
      accountName: 'm21345678',
      paymentCount: 145,
      paymentAmount: 54320000,
      cancelAmount: -1230000,
      actualAmount: 53090000,
      paymentType: '수기'
    },
    {
      rank: 8,
      company: '우리들병원',
      sales: '광주지사',
      accountName: 'm21567890',
      paymentCount: 123,
      paymentAmount: 43210000,
      cancelAmount: -980000,
      actualAmount: 42230000,
      paymentType: '수기'
    },
    {
      rank: 9,
      company: '새봄약국',
      sales: '대전지사',
      accountName: 'm21678901',
      paymentCount: 98,
      paymentAmount: 32100000,
      cancelAmount: -760000,
      actualAmount: 31340000,
      paymentType: '수기'
    },
    {
      rank: 10,
      company: '하나의원',
      sales: '울산지사',
      accountName: 'm21789012',
      paymentCount: 76,
      paymentAmount: 21000000,
      cancelAmount: -450000,
      actualAmount: 20550000,
      paymentType: '수기'
    }
  ];

  // 단말기 취소 결제 데이터
  const terminalCancelData = [
    {
      rank: 1,
      company: '압구정에이치스타일',
      sales: 'B본사',
      accountName: '1047083795',
      paymentCount: 12,
      paymentAmount: 20467500,
      cancelAmount: -9935000,
      actualAmount: 10532500,
      paymentType: '단말기'
    },
    {
      rank: 2,
      company: '쉬즈위시의원_단말(코밴)',
      sales: 'B본사',
      accountName: '107836',
      paymentCount: 21,
      paymentAmount: 107050000,
      cancelAmount: -9238200,
      actualAmount: 97811800,
      paymentType: '단말기'
    },
    {
      rank: 3,
      company: '킹스맨',
      sales: '네오플랜 윤병원',
      accountName: '1047097906',
      paymentCount: 1,
      paymentAmount: 23230000,
      cancelAmount: -4000000,
      actualAmount: 19230000,
      paymentType: '단말기'
    },
    {
      rank: 4,
      company: '라비다스킨케어',
      sales: 'B본사',
      accountName: '1046957969',
      paymentCount: 4,
      paymentAmount: 12470000,
      cancelAmount: -2900000,
      actualAmount: 9570000,
      paymentType: '단말기'
    },
    {
      rank: 5,
      company: '루',
      sales: '네오알파시스템즈 윤병원',
      accountName: '1046962605',
      paymentCount: 2,
      paymentAmount: 3000000,
      cancelAmount: -2000000,
      actualAmount: 1000000,
      paymentType: '단말기'
    },
    {
      rank: 6,
      company: '새로',
      sales: 'B본사',
      accountName: '1046962619',
      paymentCount: 1,
      paymentAmount: 6001000,
      cancelAmount: -1100000,
      actualAmount: 4901000,
      paymentType: '단말기'
    },
    {
      rank: 7,
      company: '88켄터키치킨',
      sales: 'N오평욱B 46',
      accountName: '1046949742',
      paymentCount: 1,
      paymentAmount: 8229600,
      cancelAmount: -1000000,
      actualAmount: 7229600,
      paymentType: '단말기'
    },
    {
      rank: 8,
      company: '그래음악홀',
      sales: 'N오평욱B 49',
      accountName: '1047145891',
      paymentCount: 2,
      paymentAmount: 10588000,
      cancelAmount: -980000,
      actualAmount: 9608000,
      paymentType: '단말기'
    },
    {
      rank: 9,
      company: '라비다스킨케어',
      sales: 'B본사',
      accountName: '1046957970',
      paymentCount: 1,
      paymentAmount: 9822000,
      cancelAmount: -880000,
      actualAmount: 8942000,
      paymentType: '단말기'
    },
    {
      rank: 10,
      company: '라비다스킨케어',
      sales: 'B본사',
      accountName: '1047154890',
      paymentCount: 1,
      paymentAmount: 5035000,
      cancelAmount: -880000,
      actualAmount: 4155000,
      paymentType: '단말기'
    }
  ];

  // 온라인 취소 결제 데이터
  const onlineCancelData = [
    {
      rank: 1,
      company: '1테스트 shop 001',
      sales: '',
      accountName: 'T00010001',
      paymentCount: 1,
      paymentAmount: 500,
      cancelAmount: -500,
      actualAmount: 0,
      paymentType: '온라인'
    },
    {
      rank: 2,
      company: '2테스트 shop 002',
      sales: '',
      accountName: 'T00010002',
      paymentCount: 1,
      paymentAmount: 1000,
      cancelAmount: -400,
      actualAmount: 600,
      paymentType: '온라인'
    },
    // ... 나머지 8개의 데이터도 비슷한 패턴으로 추가
  ];

  // RM 결제순위 TOP10 데이터
  const rmPaymentData = [
    {
      rank: 1,
      company: 'RM테스트_수기',
      sales: 'RM본사',
      accountName: 'rm21644470',
      paymentCount: 94,
      paymentAmount: 166569000,
      cancelAmount: -3400000,
      actualAmount: 163169000,
      paymentType: 'RM'
    },
    {
      rank: 2,
      company: 'RM위시의원',
      sales: 'RM본사',
      accountName: 'rm107836',
      paymentCount: 289,
      paymentAmount: 98750000,
      cancelAmount: -8238200,
      actualAmount: 90511800,
      paymentType: 'RM'
    },
    // ... 나머지 8개의 데이터도 비슷한 패턴으로 추가
  ];

  // 결제건수 TOP10 데이터
  const paymentCountData = [
    {
      rank: 1,
      company: '쉬즈위시의원_단말(코밴)',
      sales: 'B본사',
      accountName: '107836',
      paymentCount: 369,
      paymentAmount: 107050000,
      cancelAmount: -9238200,
      actualAmount: 97811800,
      paymentType: '단말기'
    },
    {
      rank: 2,
      company: '(주)농부고을',
      sales: '네오플랜 윤병원',
      accountName: 'm21644470',
      paymentCount: 289,
      paymentAmount: 166569000,
      cancelAmount: -3400000,
      actualAmount: 163169000,
      paymentType: '수기'
    },
    {
      rank: 3,
      company: '더조은약국',
      sales: '서울지사',
      accountName: 'm21654321',
      paymentCount: 285,
      paymentAmount: 98750000,
      cancelAmount: -2150000,
      actualAmount: 96600000,
      paymentType: '수기'
    },
    {
      rank: 4,
      company: '굿모닝병원',
      sales: '경기지사',
      accountName: 'm21987654',
      paymentCount: 246,
      paymentAmount: 87650000,
      cancelAmount: -1890000,
      actualAmount: 85760000,
      paymentType: '수기'
    },
    {
      rank: 5,
      company: '행복한치과',
      sales: '인천지사',
      accountName: 'm21456789',
      paymentCount: 198,
      paymentAmount: 76540000,
      cancelAmount: -2340000,
      actualAmount: 74200000,
      paymentType: '수기'
    },
    {
      rank: 6,
      company: '미소약국',
      sales: '부산지사',
      accountName: 'm21234567',
      paymentCount: 167,
      paymentAmount: 65430000,
      cancelAmount: -1780000,
      actualAmount: 63650000,
      paymentType: '수기'
    },
    {
      rank: 7,
      company: '연세의원',
      sales: '대구지사',
      accountName: 'm21345678',
      paymentCount: 145,
      paymentAmount: 54320000,
      cancelAmount: -1230000,
      actualAmount: 53090000,
      paymentType: '수기'
    },
    {
      rank: 8,
      company: '우리들병원',
      sales: '광주지사',
      accountName: 'm21567890',
      paymentCount: 123,
      paymentAmount: 43210000,
      cancelAmount: -980000,
      actualAmount: 42230000,
      paymentType: '수기'
    },
    {
      rank: 9,
      company: '새봄약국',
      sales: '대전지사',
      accountName: 'm21678901',
      paymentCount: 98,
      paymentAmount: 32100000,
      cancelAmount: -760000,
      actualAmount: 31340000,
      paymentType: '수기'
    },
    {
      rank: 10,
      company: '하나의원',
      sales: '울산지사',
      accountName: 'm21789012',
      paymentCount: 76,
      paymentAmount: 21000000,
      cancelAmount: -450000,
      actualAmount: 20550000,
      paymentType: '수기'
    }
  ];

  // 미실적 가맹점 순위 데이터
  const nonPerformanceData = [
    {
      rank: 1,
      company: '미실적테스트1',
      sales: '서울지사',
      accountName: 'np21644470',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 2,
      company: '미실적테스트2',
      sales: '경기지사',
      accountName: 'np21654321',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 3,
      company: '미실적테스트3',
      sales: '인천지사',
      accountName: 'np21456789',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 4,
      company: '미실적스트4',
      sales: '부산지사',
      accountName: 'np21234567',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 5,
      company: '미실적테스트5',
      sales: '대구지사',
      accountName: 'np21345678',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 6,
      company: '미실적테스트6',
      sales: '광주지사',
      accountName: 'np21567890',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 7,
      company: '미실적테스트7',
      sales: '대전지사',
      accountName: 'np21678901',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 8,
      company: '미실적테스트8',
      sales: '울산지사',
      accountName: 'np21789012',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 9,
      company: '미실적테스트9',
      sales: '강원지사',
      accountName: 'np21890123',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    },
    {
      rank: 10,
      company: '미실적테스트10',
      sales: '제주지사',
      accountName: 'np21901234',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '미실적'
    }
  ];

  // 캐시빌 미실적 가맹점 순위 데이터
  const cashbillNonPerformanceData = [
    {
      rank: 1,
      company: '캐시빌미실적1',
      sales: '서울지사',
      accountName: 'cb21644470',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 2,
      company: '캐시빌미실적2',
      sales: '경기지사',
      accountName: 'cb21654321',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 3,
      company: '캐시빌미실적3',
      sales: '인천지사',
      accountName: 'cb21456789',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 4,
      company: '캐시빌미실적4',
      sales: '부산지사',
      accountName: 'cb21234567',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 5,
      company: '캐시빌미실적5',
      sales: '대구지사',
      accountName: 'cb21345678',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 6,
      company: '캐시빌미실적6',
      sales: '광주지사',
      accountName: 'cb21567890',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 7,
      company: '캐시빌미실적7',
      sales: '대전지사',
      accountName: 'cb21678901',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 8,
      company: '캐시빌미실적8',
      sales: '울산지사',
      accountName: 'cb21789012',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 9,
      company: '캐시빌미실적9',
      sales: '강원지사',
      accountName: 'cb21890123',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    },
    {
      rank: 10,
      company: '캐시빌미실적10',
      sales: '제주지사',
      accountName: 'cb21901234',
      paymentCount: 0,
      paymentAmount: 0,
      cancelAmount: 0,
      actualAmount: 0,
      paymentType: '캐시빌미실적'
    }
  ];

  const renderTable = (data, title) => (
    <Card>
      <Box className={classes.cardHeader}>
        <Typography variant="h5" className={classes.chartTitle}>
          {title}
        </Typography>
      </Box>
      <TableContainer 
        component={Paper} 
        sx={{
          overflowX: 'auto',
          '& .MuiTable-root': {
            minWidth: shouldScroll ? '800px' : 'auto'
          },
          '& .MuiTableCell-root': {
            padding: shouldScroll ? '8px' : '16px',
            whiteSpace: 'nowrap'
          }
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>순위</TableCell>
              <TableCell>가맹점명</TableCell>
              <TableCell>영업</TableCell>
              <TableCell>계정명</TableCell>
              <TableCell align="right">결제건수</TableCell>
              <TableCell align="right">결제금액</TableCell>
              <TableCell align="right">취소금액</TableCell>
              <TableCell align="right">실매출금액</TableCell>
              <TableCell>결제형식</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.rank}</TableCell>
                <TableCell>{row.company}</TableCell>
                <TableCell>{row.sales}</TableCell>
                <TableCell>{row.accountName}</TableCell>
                <TableCell align="right">{row.paymentCount.toLocaleString()}</TableCell>
                <TableCell align="right">{row.paymentAmount.toLocaleString()}원</TableCell>
                <TableCell align="right">{row.cancelAmount.toLocaleString()}원</TableCell>
                <TableCell align="right">{row.actualAmount.toLocaleString()}원</TableCell>
                <TableCell>{row.paymentType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  return (
    <Box className={classes.content}>
      <Grid container spacing={3}>
        {/* 전체 결제순위 */}
        <Grid item xs={12}>
          {renderTable(totalPaymentData, "가맹점 결제순위 TOP10")}
        </Grid>

        {/* 수기 결제순위 */}
        <Grid item xs={12}>
          {renderTable(manualPaymentData, "가맹점 수기 결제순위 TOP10")}
        </Grid>

        {/* 단말기 결제순위 */}
        <Grid item xs={12}>
          {renderTable(terminalPaymentData, "가맹점 단말기 결제순위 TOP10")}
        </Grid>

        {/* 온라인 결제순위 */}
        <Grid item xs={12}>
          {renderTable(onlinePaymentData, "가맹점 온라인 결제순위 TOP10")}
        </Grid>

        {/* 취소 결제순위 */}
        <Grid item xs={12}>
          {renderTable(cancelPaymentData, "가맹점 취소 결제순위 TOP10")}
        </Grid>

        {/* 수기 취소 결제순위 */}
        <Grid item xs={12}>
          {renderTable(manualCancelData, "가맹점 수기 취소 결제순위 TOP10")}
        </Grid>

        {/* 단말기 취소 결제순위 */}
        <Grid item xs={12}>
          {renderTable(terminalCancelData, "가맹점 단말기 취소 결제순위 TOP10")}
        </Grid>

        {/* 온라인 취소 결제순위 */}
        <Grid item xs={12}>
          {renderTable(onlineCancelData, "가맹점 온라인 취소 결제순위 TOP10")}
        </Grid>

        {/* RM 결제순위 */}
        <Grid item xs={12}>
          {renderTable(rmPaymentData, "가맹점 RM 결제순위 TOP10")}
        </Grid>

        {/* 결제건수 순위 */}
        <Grid item xs={12}>
          {renderTable(paymentCountData, "가맹점 결제건수 순위 TOP10")}
        </Grid>

        {/* 미실적 가맹점 순위 */}
        <Grid item xs={12}>
          {renderTable(nonPerformanceData, "미실적 가맹점 순위")}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopTenData;