import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Button,
  TextField,
  Tooltip,
  IconButton
} from '@mui/material';
import LanIcon from '@mui/icons-material/Lan';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';

const IPBlock = () => {
  const [searchText, setSearchText] = useState('');

  // 상단 버튼 설정
  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2'
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32'
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2'
    }
  ];

  // 더미 데이터
  const rows = [
    {
      no: 5,
      ip: '121.165.129.213',
      content: '비정상적인 접근 시도',
      register_date: '2024-12-03'
    },
    {
      no: 4,
      ip: '175.118.44.171',
      content: '무단 스크래핑 시도',
      register_date: '2024-10-06'
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      {/* 헤더 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <LanIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          IP 차단
        </Typography>
      </Box>

      {/* 검색 및 필터 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            <TextField
              size="small"
              placeholder="검색어를 입력하세요"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ width: '300px' }}
            />

            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.label}>
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#00acc1',
              '&:hover': {
                backgroundColor: '#008a9a'
              }
            }}
          >
            등록하기
          </Button>
        </Box>
      </Paper>

      {/* 테이블 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f8f9fa' }}>
                <th style={tableHeaderStyle}>No</th>
                <th style={tableHeaderStyle}>차단IP</th>
                <th style={tableHeaderStyle}>차단내용</th>
                <th style={tableHeaderStyle}>등록일자</th>
                <th style={tableHeaderStyle}>삭제</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.no}>
                  <td style={tableCellStyle}>{row.no}</td>
                  <td style={tableCellStyle}>{row.ip}</td>
                  <td style={tableCellStyle}>{row.content}</td>
                  <td style={tableCellStyle}>{row.register_date}</td>
                  <td style={tableCellStyle}>
                    <ActionButton
                      title="삭제"
                      icon={<DeleteIcon />}
                      color="#ef5350"
                      hoverColor="#e53935"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Paper>
    </Box>
  );
};

// 스타일 상수
const tableHeaderStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  color: '#344767',
  fontWeight: 600,
  fontSize: '0.75rem',
  textTransform: 'uppercase'
};

const tableCellStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  fontSize: '0.875rem'
};

// 액션 버튼 컴포넌트
const ActionButton = ({ title, icon, color, hoverColor }) => (
  <Tooltip title={title}>
    <IconButton
      size="medium"
      sx={{
        backgroundColor: color,
        color: '#fff',
        width: '36px',
        height: '36px',
        '&:hover': {
          backgroundColor: hoverColor
        }
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: '1.2rem' } })}
    </IconButton>
  </Tooltip>
);

export default IPBlock;
