import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Tooltip,
  TextField
} from '@mui/material';
import DateRangePicker from '../../Common/DateRangePicker';
import DownloadIcon from '@mui/icons-material/Download';
import ListAltIcon from '@mui/icons-material/ListAlt';

const PointHistory = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountType, setAccountType] = useState('');
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('desc');
  const [searchText, setSearchText] = useState('');

  // 정렬 처리
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 정렬을 위한 비교 함수
  const compareValues = (a, b, orderBy) => {
    if (orderBy === 'date') {
      return new Date(b.date) - new Date(a.date);
    }
    
    if (orderBy === 'payment' || orderBy === 'charge' || orderBy === 'fee') {
      // 숫자 문자열에서 쉼표 제거하고 숫자로 변환
      const aValue = Number(a[orderBy].replace(/,/g, ''));
      const bValue = Number(b[orderBy].replace(/,/g, ''));
      return bValue - aValue;
    }
    
    return 0;
  };

  // 정렬된 데이터 얻기
  const getSortedRows = () => {
    const sortedRows = [...rows];
    if (orderBy) {
      sortedRows.sort((a, b) => {
        const compare = compareValues(a, b, orderBy);
        return order === 'desc' ? compare : -compare;
      });
    }
    return sortedRows;
  };

  // 테이블 헤더 정의
  const headCells = [
    { id: 'no', label: 'No', sortable: false },
    { id: 'date', label: '날짜', sortable: true },
    { id: 'id', label: '아이디', sortable: false },
    { id: 'accountNumber', label: '송금번호', sortable: false },
    { id: 'payment', label: '결제액', sortable: true },
    { id: 'fee', label: '수수료', sortable: true },
    { id: 'charge', label: '충전액', sortable: true },
    { id: 'status', label: '상태메세지', sortable: false }
  ];

  // 임시 데이터
  const rows = [
    {
      no: '176855',
      date: '2024-12-06 11:02:16',
      id: 'A59273166',
      accountNumber: '30125013',
      payment: '18,000',
      fee: '90',
      charge: '17,910 (8.8)',
      status: '실패'
    },
    {
      no: '176854',
      date: '2024-12-06 11:00:41',
      id: 'B12345678',
      accountNumber: '51738015',
      payment: '21,000',
      fee: '105',
      charge: '20,895 (8.25)',
      status: '실패'
    },
    {
      no: '176853',
      date: '2024-12-05 23:59:10',
      id: 'C98765432',
      accountNumber: '00084857',
      payment: '400,000',
      fee: '2,000',
      charge: '398,000 (4.84)',
      status: '완료'
    },
    {
      no: '176852',
      date: '2024-12-05 22:58:38',
      id: 'D45678901',
      accountNumber: '49161620',
      payment: '21,000',
      fee: '105',
      charge: '20,895 (8.25)',
      status: '완료'
    },
    {
      no: '176851',
      date: '2024-12-05 21:58:26',
      id: 'E34567890',
      accountNumber: '24464080',
      payment: '20,000',
      fee: '100',
      charge: '19,900 (8.8)',
      status: '실패'
    },
    {
      no: '176850',
      date: '2024-12-05 20:54:33',
      id: 'F23456789',
      accountNumber: '63734019',
      payment: '1,000,000',
      fee: '5,000',
      charge: '995,000 (4.7)',
      status: '완료'
    },
    {
      no: '176849',
      date: '2024-12-05 19:52:06',
      id: 'G12345678',
      accountNumber: '24389942',
      payment: '18,000',
      fee: '90',
      charge: '17,910 (8.8)',
      status: '완료'
    },
    {
      no: '176848',
      date: '2024-12-05 18:46:59',
      id: 'H98765432',
      accountNumber: '30032085',
      payment: '15,500',
      fee: '78',
      charge: '15,422 (8.8)',
      status: '완료'
    },
    {
      no: '176847',
      date: '2024-12-05 17:35:22',
      id: 'I87654321',
      accountNumber: '45678901',
      payment: '2,500,000',
      fee: '12,500',
      charge: '2,487,500 (4.5)',
      status: '완료'
    },
    {
      no: '176846',
      date: '2024-12-05 16:24:15',
      id: 'J76543210',
      accountNumber: '34567890',
      payment: '50,000',
      fee: '250',
      charge: '49,750 (8.5)',
      status: '완료'
    },
    {
      no: '176845',
      date: '2024-12-05 15:13:08',
      id: 'K65432109',
      accountNumber: '23456789',
      payment: '750,000',
      fee: '3,750',
      charge: '746,250 (4.8)',
      status: '완료'
    },
    {
      no: '176844',
      date: '2024-12-05 14:02:01',
      id: 'L54321098',
      accountNumber: '12345678',
      payment: '35,000',
      fee: '175',
      charge: '34,825 (8.6)',
      status: '완료'
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      {/* 제목 영역 추가 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <ListAltIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          포인트 내역
        </Typography>
      </Box>

      {/* 필터/검색 영역을 한 줄로 수정 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexWrap: 'nowrap'  // 한 줄로 유지
        }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
          
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 2
          }}>
            <RadioGroup
              row
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
            >
              <FormControlLabel 
                value="merchant" 
                control={<Radio size="small" />} 
                label="가맹" 
              />
              <FormControlLabel 
                value="account" 
                control={<Radio size="small" />} 
                label="계정" 
              />
            </RadioGroup>
          </Box>

          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ 
              width: '300px',
              ml: 'auto'  // 오른쪽으로 밀어내기
            }}
          />

          <Tooltip title="엑셀 다운로드">
            <IconButton
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#fff',
                border: '1px solid #2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  backgroundColor: '#2e7d3210',
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Paper>

      {/* 테이블 영역 스타일링 수정 */}
      <TableContainer 
        component={Paper}
        sx={{
          boxShadow: 'none',
          border: '1px solid #dee2e6',
          borderRadius: '8px',
          overflow: 'hidden' // 모서리가 깔끔하게 보이도록
        }}
      >
        <Table sx={{ minWidth: 800 }} size="small">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: '#f8f9fa',
                '& th': {
                  borderBottom: '2px solid #dee2e6',
                  color: '#344767',
                  fontWeight: 700,
                  fontSize: '0.75rem',
                  textTransform: 'uppercase',
                  padding: '12px 8px'
                }
              }}
            >
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={['payment', 'fee', 'charge'].includes(headCell.id) ? 'right' : 'center'}
                  sx={{ 
                    whiteSpace: 'nowrap',
                    '&:not(:last-child)': {
                      borderRight: '1px solid #dee2e6'
                    }
                  }}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getSortedRows().map((row, index) => (
              <TableRow
                key={row.no}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: '#fafbfc'
                  },
                  '&:hover': {
                    backgroundColor: '#f5f6f7'
                  },
                  '& td': {
                    padding: '12px 8px',
                    fontSize: '0.875rem',
                    borderRight: '1px solid #dee2e6',
                    '&:last-child': {
                      borderRight: 'none'
                    }
                  }
                }}
              >
                <TableCell align="center">{row.no}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">{row.accountNumber}</TableCell>
                <TableCell align="right" sx={{ fontWeight: 500 }}>{row.payment}</TableCell>
                <TableCell align="right">{row.fee}</TableCell>
                <TableCell align="right" sx={{ fontWeight: 500 }}>{row.charge}</TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    color: row.status === '완료' ? '#2e7d32' : '#d32f2f',
                    fontWeight: 600,
                    fontSize: '0.8125rem'
                  }}
                >
                  {row.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PointHistory;
