import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '800px',
    borderRadius: '8px'
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid #edf2f9'
});

const StyledTableCell = styled(TableCell)({
  padding: '8px 16px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #edf2f9',
  '&.header': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600
  }
});

const UserInfoTwo = ({ open, onClose, data }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        계정별 정보
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell className="header">구분</StyledTableCell>
                <StyledTableCell className="header">영업사명</StyledTableCell>
                <StyledTableCell className="header">수수료율</StyledTableCell>
                <StyledTableCell className="header">수수료금(VAT포함)</StyledTableCell>
                <StyledTableCell className="header">지급금액(VAT포함)</StyledTableCell>
                <StyledTableCell className="header">원천징수</StyledTableCell>
                <StyledTableCell className="header">포인트 수수료</StyledTableCell>
                <StyledTableCell className="header">적립금 수수료</StyledTableCell>
                <StyledTableCell className="header">계좌번호</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{row.type}</StyledTableCell>
                  <StyledTableCell>{row.businessName}</StyledTableCell>
                  <StyledTableCell>{row.feeRate}%</StyledTableCell>
                  <StyledTableCell>{row.feeAmount?.toLocaleString()}</StyledTableCell>
                  <StyledTableCell>{row.paymentAmount?.toLocaleString()}</StyledTableCell>
                  <StyledTableCell>{row.withholding}</StyledTableCell>
                  <StyledTableCell>{row.pointFee}</StyledTableCell>
                  <StyledTableCell>{row.rewardFee}</StyledTableCell>
                  <StyledTableCell>{row.accountNumber}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default UserInfoTwo;