import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  IconButton,
  Typography,
  styled,
  Select,
  MenuItem,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '1200px',
    maxWidth: '95vw',
    height: '95vh',
    maxHeight: '95vh'
  }
});

const StyledTableCell = styled(TableCell)({
  padding: '8px 16px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  '&:last-child': {
    borderRight: 'none'
  },
  '&.header': {
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
    color: '#495057'
  }
});

const TableContainer = styled(Box)({
  flex: 1,
  minWidth: '48%',  // 두 테이블이 나란히 오도록
  marginBottom: '20px'
});

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '8px 14px',
    fontSize: '0.875rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#e0e0e0',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#bdbdbd',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
  },
  backgroundColor: 'white'
});

const StyledMenuItem = styled(MenuItem)({
  fontSize: '0.875rem',
  '&.Mui-selected': {
    backgroundColor: '#f5f5f5'
  }
});

const RMquestion = ({ open, onClose, data }) => {
  const [complaintType, setComplaintType] = React.useState('선택');
  const [processStatus, setProcessStatus] = React.useState('대기중');
  const [replyDate, setReplyDate] = React.useState(null);

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle sx={{ 
        borderBottom: '1px solid #e9ecef',
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1
      }}>
        <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
          가맹점 & 지사 정보
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent sx={{ 
        padding: '24px',
        overflowY: 'auto'
      }}>
        {/* 상단 테이블 영역 */}
        <Box sx={{ 
          display: 'flex', 
          gap: '24px',
          mb: 3,
          marginTop: '24px'
        }}>
          {/* 가맹점 정보 테이블 */}
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <StyledTableCell className="header" width="30%">가맹점명</StyledTableCell>
                  <StyledTableCell>{data?.merchantName}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">로그인ID</StyledTableCell>
                  <StyledTableCell>{data?.loginId}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">연락처</StyledTableCell>
                  <StyledTableCell>{data?.phone}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">휴대전화</StyledTableCell>
                  <StyledTableCell>{data?.mobile}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">주소</StyledTableCell>
                  <StyledTableCell>{data?.address}</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* 지사 정보 테이블 */}
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <StyledTableCell className="header" width="30%">지사명</StyledTableCell>
                  <StyledTableCell>{data?.branchName}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">지사ID</StyledTableCell>
                  <StyledTableCell>{data?.branchLoginId}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">연락처</StyledTableCell>
                  <StyledTableCell>{data?.branchPhone}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">휴대전화</StyledTableCell>
                  <StyledTableCell>{data?.branchMobile}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">주소</StyledTableCell>
                  <StyledTableCell>{data?.branchAddress}</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* 하단 테이블 영역 */}
        <Box sx={{ 
          display: 'flex', 
          gap: '24px'
        }}>
          {/* 수수료 정보 테이블 */}
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <StyledTableCell className="header" width="30%">수수료</StyledTableCell>
                  <StyledTableCell>8.0000</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">정산주기(일)</StyledTableCell>
                  <StyledTableCell>1</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">정산상태(원)</StyledTableCell>
                  <StyledTableCell>미정산</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">RM횟수</StyledTableCell>
                  <StyledTableCell>2</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">수수료율(%)</StyledTableCell>
                  <StyledTableCell>3.5</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* 상태 정보 테이블 */}
          <TableContainer sx={{ display: 'flex', flexDirection: 'column' }}>
            <Table size="small" sx={{ flex: '0 0 auto' }}>
              <TableBody>
                <TableRow>
                  <StyledTableCell className="header" width="30%">업종</StyledTableCell>
                  <StyledTableCell>테스트</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">통화</StyledTableCell>
                  <StyledTableCell>통화</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">결제형식</StyledTableCell>
                  <StyledTableCell>수기</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell className="header">인증방식</StyledTableCell>
                  <StyledTableCell>구인증</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box sx={{ flex: '1 1 auto' }} /> {/* 여백을 위한 빈 공간 */}
          </TableContainer>
        </Box>

        {/* 민원 접수 섹션 */}
        <Box sx={{ 
          borderBottom: '1px solid #e9ecef',
          pb: 1,
          mb: 3,
          mt: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
            민원 접수
          </Typography>
        </Box>

        {/* 민원 접수 데이터 테이블 영역 */}
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',  // 2:1 비율로 분할
          gap: '16px',
          width: '100%',
          mb: 3
        }}>
          {/* 왼 번째 테이블 (2/3 너비) */}
          <Table size="small" sx={{ border: '1px solid #e0e0e0' }}>
            <TableBody>
              <TableRow>
                <StyledTableCell className="header" width="20%">기계일시</StyledTableCell>
                <StyledTableCell>2024-12-12 20:17:7</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="header">회신기한</StyledTableCell>
                <StyledTableCell>2024-12-28</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="header">고객명</StyledTableCell>
                <StyledTableCell>테스트</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="header">결제 연락처</StyledTableCell>
                <StyledTableCell>0</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>

          {/* 두 번째 테이블 (1/3 너비) */}
          <Table size="small" sx={{ border: '1px solid #e0e0e0' }}>
            <TableBody>
              <TableRow>
                <StyledTableCell className="header" width="40%">접속아이디</StyledTableCell>
                <StyledTableCell>b19006</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell className="header">등록일</StyledTableCell>
                <StyledTableCell>2024-12-12 20:21:07</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        {/* 민원종류와 처리여부 Select 영역 */}
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '16px',
          width: '100%'
        }}>
          <StyledSelect
            value={complaintType}
            onChange={(e) => setComplaintType(e.target.value)}
            size="small"
            displayEmpty
            fullWidth
          >
            <StyledMenuItem value="선택">선택</StyledMenuItem>
            <StyledMenuItem value="결제취소">결제취소</StyledMenuItem>
            <StyledMenuItem value="금액오류">금액오류</StyledMenuItem>
            <StyledMenuItem value="기타">기타</StyledMenuItem>
          </StyledSelect>

          <StyledSelect
            value={processStatus}
            onChange={(e) => setProcessStatus(e.target.value)}
            size="small"
            fullWidth
            sx={{
              backgroundColor: processStatus === '대기중' ? '#ffebee' : 
                              processStatus === '처리중' ? '#fff3e0' : '#e8f5e9'
            }}
          >
            <StyledMenuItem value="대기중">대기중</StyledMenuItem>
            <StyledMenuItem value="처리중">처리중</StyledMenuItem>
            <StyledMenuItem value="완료">완료</StyledMenuItem>
          </StyledSelect>

          <TextField
            label="민원내용"
            multiline
            rows={4}
            fullWidth
          />
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default RMquestion;