import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import StorefrontIcon from '@mui/icons-material/Storefront'; // 가맹점 아이콘
import StarsIcon from '@mui/icons-material/Stars'; // 계급 아이콘으로 변경
import { faGear, faUser, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha } from '@mui/material/styles';

function UserInfoPanel({ open, onClose }) {
  const navigate = useNavigate();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: 320,
          boxSizing: 'border-box',
          background: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(8px)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
          animation: 'slideIn 0.3s ease-out',
          '@keyframes slideIn': {
            from: {
              transform: 'translateX(100%)',
              opacity: 0
            },
            to: {
              transform: 'translateX(0)',
              opacity: 1
            }
          }
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }
      }}
    >
      <Box sx={{ 
        p: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: 'linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(245,246,250,0.9) 100%)'
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2,
          p: 1,
          borderRadius: '10px',
          backgroundColor: alpha('#fff', 0.7)
        }}>
          <Typography variant="h6" sx={{ 
            color: '#34495e', 
            fontWeight: 600,
            textShadow: '1px 1px 1px rgba(0,0,0,0.1)'
          }}>
            사용자 정보
          </Typography>
          <IconButton 
            onClick={onClose}
            sx={{
              '&:hover': {
                transform: 'rotate(90deg)',
                transition: 'transform 0.3s'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        
        <Box sx={{ 
          py: 3, 
          px: 2, 
          backgroundColor: alpha('#f5f6fa', 0.7),
          borderRadius: '12px',
          backdropFilter: 'blur(8px)',
          boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 12px rgba(0,0,0,0.1)'
          }
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 2,
            p: 1.5,
            borderRadius: '8px',
            backgroundColor: alpha('#fff', 0.6),
            transition: 'all 0.2s',
            '&:hover': {
              backgroundColor: alpha('#fff', 0.8),
              transform: 'translateX(5px)'
            }
          }}>
            <PersonIcon sx={{ mr: 1, color: '#546e7a', fontSize: '24px' }} />
            <Typography variant="subtitle1" sx={{ color: '#546e7a', fontWeight: 500 }}>
              사용자: 김민규님
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 2,
            p: 1.5,
            borderRadius: '8px',
            backgroundColor: alpha('#fff', 0.6),
            transition: 'all 0.2s',
            '&:hover': {
              backgroundColor: alpha('#fff', 0.8),
              transform: 'translateX(5px)'
            }
          }}>
            <StorefrontIcon sx={{ mr: 1, color: '#607d8b', fontSize: '24px' }} />
            <Typography variant="subtitle1" sx={{ color: '#607d8b', fontWeight: 500 }}>
              가맹점: 또봉이 통닭
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            p: 1.5,
            borderRadius: '8px',
            backgroundColor: alpha('#fff', 0.6),
            transition: 'all 0.2s',
            '&:hover': {
              backgroundColor: alpha('#fff', 0.8),
              transform: 'translateX(5px)'
            }
          }}>
            <StarsIcon sx={{ mr: 1, color: '#8d6e63', fontSize: '24px' }} />
            <Typography variant="subtitle1" sx={{ color: '#8d6e63', fontWeight: 500 }}>
              가맹점주
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2, opacity: 0.6 }} />

        <List>
          <MenuItem onClick={() => {
            navigate('/admin');
            onClose();
          }}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faShieldHalved} style={{ fontSize: '20px', color: '#666' }} />
            </ListItemIcon>
            <ListItemText>관리자 페이지</ListItemText>
          </MenuItem>
          
          <Divider sx={{ opacity: 0.6 }} />
          
          <ListItem button>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="프로필 설정" />
          </ListItem>
          
          <ListItem button>
            <ListItemIcon>
              <SecurityIcon />
            </ListItemIcon>
            <ListItemText primary="보안 설정" />
          </ListItem>
          
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="환경 설정" />
          </ListItem>
          
          <Divider sx={{ opacity: 0.6 }} />
          
          <ListItem button>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="로그아웃" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}

export default UserInfoPanel;
