import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, Button, Typography, Divider, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBell, 
  faUser, 
  faCircleUser,
  faInfo,
} from '@fortawesome/free-solid-svg-icons';
import UserInfoPanel from './UserInfoPanel';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import AlertMessage from './Common/AlertMessage';

// 스타일 추가
const styles = {
  '@media (max-width: 767px)': {  // 모바일 (iPad Mini 제외)
    '.header-toolbar': {
      padding: '0 8px',
      overflowX: 'auto',
    },
    '.menu-container': {
      gap: '8px',
    },
    '.menu-button': {
      fontSize: '14px',
      padding: '6px 8px',
    },
    '.user-info': {
      display: 'none',
    },
    '.logo-text': {
      display: 'none',
    }
  },
  '@media (min-width: 768px) and (max-width: 1024px)': {  // 태블릿 (iPad Mini 포함)
    '.header-toolbar': {
      padding: '0 16px',
    },
    '.menu-container': {
      gap: '12px',
    },
    '.menu-button': {
      fontSize: '15px',
      padding: '8px 12px',
    },
    '.user-info': {
      display: 'flex',
    },
    '.logo-text': {
      display: 'block',
      fontSize: '1.1rem',
    }
  },
  '@media (min-width: 1025px)': {  // 데스크톱
    '.header-toolbar': {
      padding: '0 24px',
    },
    '.menu-container': {
      gap: '16px',
    },
    '.menu-button': {
      fontSize: '16px',
      padding: '8px 16px',
    },
    '.user-info': {
      display: 'flex',
    },
    '.logo-text': {
      display: 'block',
      fontSize: '1.2rem',
    }
  }
};

// 알람 카운트를 표시할 Badge 스타일 추가
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 3,
    padding: '0 4px',
    backgroundColor: '#ff0000',
    color: 'white',
    minWidth: '20px',
    height: '20px',
    borderRadius: '10px',
    fontSize: '0.75rem',
  },
}));

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentMenu, setCurrentMenu] = useState('');
  const [userPanelOpen, setUserPanelOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:800px)');
  const [alerts, setAlerts] = useState([]);

  // 모바일/태블릿 감지 로직 수정
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm')); // 모바일 감지
  const isTabletDevice = useMediaQuery(theme.breakpoints.between('sm', 'lg')); // 태블릿 감지
  const isMobileOrTablet = isMobileDevice || isTabletDevice; // 모바일 또는 태블릿 여부

  // 전체 영역 클릭 핸들러
  React.useEffect(() => {
    const handleClickOutside = () => {
      if (currentMenu && isMobileOrTablet) {
        setCurrentMenu('');
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [currentMenu, isMobileOrTablet]);

  // 메뉴 클릭 핸들러 수정
  const handleMenuClick = (e, key) => {
    e.stopPropagation();
    if (isMobileOrTablet) {  // 모바일이나 태블릿에서 동작
      setCurrentMenu(prevMenu => prevMenu === key ? '' : key);
    }
  };

  // 로고 클릭 핸들러 수정
  const handleLogoClick = (e) => {
    e.stopPropagation();
    setCurrentMenu('');
    navigate('/'); // Dashboard로 이동
  };

  // 서브메뉴 클릭 핸들러 수정
  const handleSubMenuClick = (e) => {
    e.preventDefault();  // 기본 이벤트 방지
    e.stopPropagation();
  };

  // 메뉴 아이템 클릭 핸들러 수정
  const handleMenuItemClick = (e, path) => {
    e.preventDefault();
    e.stopPropagation();
    if (isMobileOrTablet) {
      setCurrentMenu(''); // 메뉴 닫기
      setTimeout(() => {
        navigate(path); // 페이지 이동
      }, 10);
    } else {
      navigate(path);
    }
  };

  // 날짜 포맷 수정 - 점 앞에 공백 추가
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year} . ${month} . ${day}`;
  };

  const currentDate = formatDate(new Date());

  const menus = {
    payment: {
      label: '결제',
      subMenus: {
        payment: {
          label: '결제',
          items: [
            { label: '실패내역', path: '/fail-list' },
            { label: '알람내역', path: '/alarm-history' },
            { label: '결제내역', path: '/payment-history' },
            { label: '결제내역집계', path: '/payment-aggregation' },
            { label: '정기결제내역', path: '/auto-payment' }
          ]
        },
        management: {
          label: '결제관리',
          items: [
            { label: '오류내역', path: '/error-log' },
            { label: '중복확인', path: '/duplicate-check' },
            { label: '미실적 가맹점관리', path: '/payment/non-merchant' }
          ]
        },
        cancel: {
          label: '취소관리',
          items: [
            { label: '취소요청 내역', path: '/cancel-requests' },
            { label: '취소완료 내역', path: '/cancel-completed' },
            { label: '임의취소 환입등록', path: '/cancel-refund' }
          ]
        }
      }
    },
    settlement: {
      label: '정산',
      subMenus: {
        main: {
          label: '정산',
          items: [
            { label: '거래민원 내역', path: '/settlement/complaint' },
            { label: '정산달력', path: '/settlement/calendar' }
          ]
        },
        management: {
          label: '정산관리',
          items: [
            { label: '정산예정 내역', path: '/settlement/scheduled' }, 
            { label: '정산보류 내역', path: '/settlement/hold' },
            { label: '정산집계 내역', path: '/settlement/summary' },
            { label: '입금내역', path: '/settlement/deposit' }
          ]
        },
        transfer: {
          label: '송금하기',
          items: [
            { label: '비월렛', path: '/settlement/transfer/non-wallet' },
            { label: '월렛', path: '/settlement/transfer/wallet' }
          ]
        },
        additional: {
          label: '부가서비스',
          items: [
            { label: '월렛입출금 내역', path: '/settlement/wallet-history' },
            { label: '입력검증표', path: '/settlement/input-validation' },
            { label: '세금계 전매체', path: '/settlement/tax-it' }
          ]
        }
      }
    },
    statistics: {
      label: '통계',
      subMenus: {
        sales: {
          label: '매출통계',
          items: [
            { label: '기간별 통계', path: '/statistics/sales/period' },
            { label: '월별통계', path: '/statistics/sales/monthly' },
            { label: '가맹별 통계', path: '/statistics/sales/merchant' }
          ]
        },
        payment: {
          label: '지급통계',
          items: [
            { label: '가맹별', path: '/statistics/payment/merchant' },
            { label: '영업별', path: '/statistics/payment/sales' }
          ]
        },
        analysis: {
          label: '통계분석',
          items: [
            { label: '기간별', path: '/statistics/analysis/period' },
            { label: '월별', path: '/statistics/analysis/monthly' },
            { label: '가맹별', path: '/statistics/analysis/merchant' },
            { label: '카드사별', path: '/statistics/analysis/card' }
          ]
        }
      }
    },
    management: {
      label: '관리',
      subMenus: {
        account: {
          label: '계정',
          items: [
            { label: '계정관리', path: '/management/account' }
          ]
        },
        merchant: {
          label: '가맹',
          items: [
            { label: '가맹관리', path: '/management/merchant' }
          ]
        },
        cashbill: {
          label: '캐시빌',
          items: [
            { label: '캐시빌 담당관리', path: '/management/cashbill/manager' },
            { label: '캐시빌 회원관리', path: '/management/cashbill/member' }
          ]
        }
      }
    }
  };

  // 상태 표시 색상 스타일
  const statusColors = [
    { color: '#ff0000', count: 4 }, // 빨강
    { color: '#ff9900', count: 2 }, // 주황
    { color: '#ffff00', count: 1 }, // 노랑
    { color: '#0000ff', count: 3 }, // 파랑
    { color: '#ffffff', count: 0 }  // 흰색
  ];

  // 현재 경로에 따른 활성 뉴 확인 함수
  const isActiveMenuItem = (path) => {
    return location.pathname === path;
  };

  // 현재 경로가 특정 메뉴의 하위 항목인지 확인하는 함수
  const isActiveParentMenu = (subMenus) => {
    return Object.values(subMenus).some(subMenu => 
      subMenu.items.some(item => location.pathname === item.path)
    );
  };

  // 현재 경로에 따른 현재 페이지 이름을 찾는 함수 추가
  const getCurrentPageName = () => {
    for (const [, menu] of Object.entries(menus)) {
      for (const [, subMenu] of Object.entries(menu.subMenus)) {
        const matchingItem = subMenu.items.find(item => item.path === location.pathname);
        if (matchingItem) {
          return matchingItem.label;
        }
      }
    }
    return null;
  };

  // 메뉴 레이블을 가져오는 함수 추가
  const getMenuLabel = (key, menu) => {
    // 현재 로가 이 메뉴의 하위 항목인 경우
    if (isActiveParentMenu(menu.subMenus)) {
      const currentPageName = getCurrentPageName();
      return currentPageName || menu.label;
    }
    return menu.label;
  };

  // 빨간색 상태의 카운트에 따른 알람 표시
  useEffect(() => {
    const redStatus = statusColors.find(status => status.color === '#ff0000');
    if (!isMobile && redStatus && redStatus.count > 0) {
      setAlerts([{
        id: Date.now(),
        message: '[긴급실패] 1046930753번전 진행확인! ERROR_CODE: B012',
        timestamp: new Date()
      }]);
    }
  }, [isMobile]);

  const removeAlert = (id) => {
    setAlerts(prev => prev.filter(alert => alert.id !== id));
  };

  return (
    <>
      {/* 헤더 */}
      <AppBar position="fixed" sx={{ 
        backgroundColor: '#f0f0f0',
        fontFamily: "'Font Awesome 6 Free', 'Noto Sans KR', sans-serif",
        zIndex: 1300,
        ...styles
      }} elevation={1}>
        <Toolbar className="header-toolbar" sx={{ display: 'flex', justifyContent: 'space-between', py: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: isMobileOrTablet ? 0.5 : 2 }}>
            <Box className="menu-container" sx={{ display: 'flex', gap: isMobileOrTablet ? 1 : 2 }}>
              {/* P&IMS 로고 - 모바일에서만 클릭 가능 */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: isMobileOrTablet ? 1 : 2,
                  cursor: 'pointer'  // 모든 디바이스에서 커 포인터
                }}
                onClick={handleLogoClick}  // 모든 디바이스에서 클릭 가능
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    color: '#1976d2',
                    fontSize: '24px',
                    fontFamily: 'Arial',
                    letterSpacing: '0.5px',
                    background: 'linear-gradient(45deg, #1976d2, #2196f3)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      width: '32px',
                      height: '32px',
                      borderRadius: '8px',
                      background: 'linear-gradient(45deg, #1976d2, #2196f3)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 900,
                      fontSize: '18px',
                      boxShadow: '2px 2px 4px rgba(0,0,0,0.2)'
                    }}
                  >
                    <span style={{ 
                      color: '#333333',
                      WebkitTextFillColor: '#333333'
                    }}>
                      P
                    </span>
                  </Box>
                  <span className="logo-text">&IMS</span>
                </Typography>
              </Box>

              {/* 메뉴 부 수정 */}
              {Object.entries(menus).map(([key, menu]) => (
                <Box
                  key={key}
                  onMouseEnter={() => !isMobileOrTablet && setCurrentMenu(key)}
                  onMouseLeave={() => !isMobileOrTablet && setCurrentMenu('')}
                  sx={{ 
                    position: 'relative',
                    '&:hover > .MuiBox-root': {
                      display: !isMobileOrTablet ? 'block' : 'none'
                    },
                    zIndex: 1500
                  }}
                >
                  <Button
                    className="menu-button"
                    onClick={(e) => handleMenuClick(e, key)}
                    sx={{ 
                      color: isActiveParentMenu(menu.subMenus) ? 'primary.main' : '#000000',
                      textTransform: 'none',
                      fontSize: isMobileOrTablet ? '16px' : '18px',
                      fontWeight: 700,
                      borderBottom: isActiveParentMenu(menu.subMenus) ? 2 : 0,
                      borderColor: 'primary.main',
                      borderRadius: 0,
                      px: isMobileOrTablet ? 1 : 2,
                      py: 1
                    }}
                  >
                    {getMenuLabel(key, menu)}
                  </Button>
                  
                  {/* 서브 메뉴 표시 */}
                  {(!isMobileOrTablet && currentMenu === key) && menu.subMenus && (
                    <Box
                      className="MuiBox-root"
                      sx={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        backgroundColor: 'white',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        borderRadius: 1,
                        minWidth: '200px',
                        zIndex: 1400,
                        p: 1
                      }}
                    >
                      {Object.entries(menu.subMenus).map(([subKey, subMenu]) => (
                        <Box key={subKey}>
                          <Typography
                            sx={{
                              px: 2,
                              py: 1,
                              fontWeight: 600,
                              color: '#1976d2',
                              fontSize: '1.1rem'
                            }}
                          >
                            {subMenu.label}
                          </Typography>
                          {subMenu.items?.map((item) => (
                            <Button
                              key={item.path}
                              onClick={(e) => handleMenuItemClick(e, item.path)}
                              sx={{
                                width: '100%',
                                textAlign: 'left',
                                justifyContent: 'flex-start',
                                textTransform: 'none',
                                py: 0.5,
                                px: 2,
                                color: isActiveMenuItem(item.path) ? 'primary.main' : '#000000',
                                backgroundColor: isActiveMenuItem(item.path) ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                                fontWeight: 700,
                                '&:hover': {
                                  backgroundColor: isActiveMenuItem(item.path) 
                                    ? 'rgba(25, 118, 210, 0.12)' 
                                    : '#f5f5f5'
                                }
                              }}
                            >
                              {item.label}
                            </Button>
                          ))}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 2
          }}>
            {!isMobileOrTablet && (
              <>
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                  <StyledBadge badgeContent={4} max={99}>
                    <NotificationsActiveIcon 
                      sx={{ 
                        color: '#ff0000',
                        fontSize: '28px',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.8
                        }
                      }} 
                    />
                  </StyledBadge>
                </Box>
                
                {/* 상태 표시 dots - 숫자 추가 */}
                <Box sx={{ display: 'flex', gap: 0.5, ml: 1 }}>
                  {statusColors.map((status, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: 24,  // 크기 더 증가
                        height: 24, // 크기 더 증가
                        borderRadius: '50%',
                        backgroundColor: status.color,
                        border: status.color === '#ffffff' ? '1px solid #ddd' : 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: status.color === '#ffff00' ? '#000' : '#fff', // 노란색 배경일 때는 검은색 텍스트
                        position: 'relative'
                      }}
                    >
                      {status.count > 0 && status.count}
                    </Box>
                  ))}
                </Box>

                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: '#000000',
                    fontFamily: "'Font Awesome 6 Free', 'Noto Sans KR', sans-serif"
                  }}
                >
                  {currentDate}
                </Typography>

                <Box 
                  className="user-info"
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.8 }
                  }}
                  onClick={() => setUserPanelOpen(true)}
                >
                  <FontAwesomeIcon icon={faUser} style={{ color: '#000000' }} />
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '0.9rem',
                      fontWeight: 'bold',
                      fontFamily: "'Font Awesome 6 Free', 'Noto Sans KR', sans-serif",
                      display: 'flex',
                      gap: 2,
                    }}
                  >
                    <span>IP주소: 127.0.0.1</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>환영합니다 김민규님</span>
                  </Typography>
                </Box>
              </>
            )}
            <IconButton 
              sx={{ color: '#00acc1' }}
              onClick={() => setUserPanelOpen(true)}
            >
              <FontAwesomeIcon icon={faCircleUser} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* 알람 메시지 영역 - top 값 증가 */}
      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            top: '80px',
            left: '20px',
            zIndex: 1200
          }}
        >
          {alerts.map(alert => (
            <AlertMessage
              key={alert.id}
              message={alert.message}
              duration={3000}
              onClose={() => removeAlert(alert.id)}
            />
          ))}
        </Box>
      )}

      {/* 헤더 높이만큼의 여백 */}
      <Toolbar />

      {/* 메인 컨텐츠 */}
      <Box sx={{ width: '100%' }}>
        {/* 모바일/태블릿용 사이드 메뉴 */}
        {isMobileOrTablet && (
          <Box
            sx={{
              position: 'fixed',
              top: '64px',
              left: 0,
              width: '280px',
              height: 'calc(100vh - 64px)',
              backgroundColor: '#ffffff',
              boxShadow: '2px 0 8px rgba(0,0,0,0.15)',
              transition: 'all 0.3s ease-in-out',
              transform: currentMenu ? 'translateX(0)' : 'translateX(-100%)',
              opacity: currentMenu ? 1 : 0,
              visibility: currentMenu ? 'visible' : 'hidden',
              zIndex: 1200,
              overflowY: 'auto'
            }}
          >
            {/* 모바일 메뉴 헤더 */}
            <Box sx={{ 
              p: 2, 
              borderBottom: '1px solid #e0e0e0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {currentMenu && menus[currentMenu]?.label}
              </Typography>
              <IconButton 
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentMenu('');
                }}
                size="small"
              >
                ✕
              </IconButton>
            </Box>

            {/* 모바일 서브메뉴 내용 */}
            {currentMenu && menus[currentMenu]?.subMenus && (
              Object.entries(menus[currentMenu].subMenus).map(([subKey, subMenu]) => (
                <Box key={subKey}>
                  <Typography
                    sx={{
                      px: 2,
                      py: 1,
                      fontWeight: 600,
                      color: '#1976d2',
                      fontSize: '1.1rem'
                    }}
                  >
                    {subMenu.label}
                  </Typography>
                  {subMenu.items?.map((item) => (
                    <Button
                      key={item.path}
                      onClick={(e) => handleMenuItemClick(e, item.path)}
                      sx={{
                        width: '100%',
                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        py: 0.5,
                        px: 2,
                        color: isActiveMenuItem(item.path) ? 'primary.main' : '#000000',
                        backgroundColor: isActiveMenuItem(item.path) ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                        fontWeight: 700,
                        '&:hover': {
                          backgroundColor: isActiveMenuItem(item.path) 
                            ? 'rgba(25, 118, 210, 0.12)' 
                            : '#f5f5f5'
                        }
                      }}
                    >
                      {item.label}
                    </Button>
                  ))}
                </Box>
              ))
            )}
          </Box>
        )}

        {/* 모바일/태블릿 메뉴가 열려있을 때의 오버레이 */}
        {isMobileOrTablet && currentMenu && (
          <Box
            onClick={() => setCurrentMenu('')}
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1100
            }}
          />
        )}

        <UserInfoPanel 
          open={userPanelOpen}
          onClose={() => setUserPanelOpen(false)}
        />
      </Box>
    </>
  );
}

export default Header;
