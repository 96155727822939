import React from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';

const styles = {
  container: {
    position: 'fixed',
    left: 20,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    zIndex: 1000,
    '@media (max-width: 768px)': {
      display: 'none'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      display: 'none'
    }
  },
  button: {
    backgroundColor: '#fff',
    color: '#1976d2',
    padding: '12px',
    borderRadius: '50%',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    minWidth: 'unset',
    width: '48px',
    height: '48px',
    transition: 'all 0.2s ease',
    border: '1px solid #1976d2',
    '& .MuiSvgIcon-root': {
      opacity: 1,
      transition: 'opacity 0.2s ease',
      color: '#1976d2'
    },
    '&:hover': {
      backgroundColor: '#1976d2',
      color: '#fff',
      boxShadow: '0 4px 12px rgba(25, 118, 210, 0.3)',
      border: '1px solid #1976d2',
      '& .MuiSvgIcon-root': {
        opacity: 1,
        color: '#fff'
      }
    }
  }
};

function SummaryData() {
  const navigate = useNavigate();

  return (
    <Box sx={styles.container}>
      <Tooltip title="실패내역" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => navigate('/fail-list')}
        >
          <CancelPresentationIcon />
        </Button>
      </Tooltip>
      <Tooltip title="오류내역" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => navigate('/error-log')}
        >
          <WarningIcon />
        </Button>
      </Tooltip>
      <Tooltip title="중복확인" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => navigate('/duplicate-check')}
        >
          <ContentCopyIcon />
        </Button>
      </Tooltip>
      <Tooltip title="가맹관리" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => console.log('가맹관리 클릭')}
        >
          <StorefrontIcon />
        </Button>
      </Tooltip>
      <Tooltip title="미실적 가맹관리" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => navigate('/payment/non-merchant')}
        >
          <Box sx={{ position: 'relative' }}>
            <StorefrontIcon />
            <ClearIcon sx={{ 
              position: 'absolute', 
              bottom: -4, 
              right: -4, 
              fontSize: '1.2em',
              color: 'error.main',
              opacity: 0.9,
              '&.MuiSvgIcon-root': {
                color: 'error.main !important'
              }
            }} />
          </Box>
        </Button>
      </Tooltip>
      <Tooltip title="월렛 출금내역" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => navigate('/settlement/wallet-history')}
        >
          <AccountBalanceWalletIcon />
        </Button>
      </Tooltip>
      <Tooltip title="결제내역" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => navigate('/payment-history')}
        >
          <ReceiptLongIcon />
        </Button>
      </Tooltip>
      <Tooltip title="계정관리" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => console.log('계정관리 클릭')}
        >
          <ManageAccountsIcon />
        </Button>
      </Tooltip>
      <Tooltip title="기간별 통계" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => console.log('기간별 통계 클릭')}
        >
          <BarChartIcon />
        </Button>
      </Tooltip>
      <Tooltip title="송금하기" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => navigate('/settlement/transfer/wallet')}
        >
          <LocalAtmIcon />
        </Button>
      </Tooltip>
      <Tooltip title="정산보류" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => console.log('정산보류 클릭')}
        >
          <HourglassEmptyIcon />
        </Button>
      </Tooltip>
      <Tooltip title="정산집계" placement="right">
        <Button
          variant="contained"
          sx={styles.button}
          onClick={() => console.log('정산집계 클릭')}
        >
          <AssessmentIcon />
        </Button>
      </Tooltip>
    </Box>
  );
}

export default SummaryData;
