import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Checkbox,
  IconButton, 
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  styled,
  Button,
  FormControlLabel,
  useMediaQuery,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BlockIcon from '@mui/icons-material/Block';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// 스타일드 컴포넌트
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px',
  whiteSpace: 'nowrap',
  ['&.MuiTableCell-head']: {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  border: '1px solid #1976d2',
  color: '#1976d2',
  padding: '4px 12px',
  fontSize: '0.875rem',
  '&:hover': {
    backgroundColor: '#1976d210',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    marginRight: '4px'
  }
}));

// 출금정지 버튼을 위한 새로운 스타일드 컴포넌트
const StyledStopButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  border: '1px solid #d32f2f',
  color: '#d32f2f',
  padding: '2px 8px',
  fontSize: '0.75rem',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: '#d32f2f10',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
    marginRight: '4px'
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '16px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  marginBottom: '16px'
}));

const WalletAccountList = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [merchantName, setMerchantName] = useState('');
  const [loginId, setLoginId] = useState('');
  const [isNegativeBalance, setIsNegativeBalance] = useState(false);
  const [isWithdrawalStopped, setIsWithdrawalStopped] = useState(false);
  const [merchantOption, setMerchantOption] = useState('234');

  // 테이블 헤더 정의
  const headers = [
    { id: 'select', label: '선택' },
    { id: 'merchantName', label: '상호명' },
    { id: 'loginId', label: '로그인아이디' },
    { id: 'balance', label: '잔액', align: 'right' },
    { id: 'availableAmount', label: '출금가능액', align: 'right' },
    { id: 'withdrawalStatus', label: '출금가능여부' }
  ];

  // 샘플 데이터
  const rows = [
    {
      merchantName: '농부고급3',
      loginId: 'm87965090',
      balance: '1,056,000',
      availableAmount: '0',
      withdrawalStatus: '출금가능',
      isWithdrawalStopped: false,
      isSelected: false
    }
  ];

  return (
    <Box sx={{ p: 2 }}>
      {/* 제목 영역 추가 */}
      <StyledBox>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontSize: '1.1rem',
            fontWeight: 600,
            color: '#1976d2'
          }}
        >
          <AccountBalanceWalletIcon sx={{ fontSize: '1.4rem' }} />
          월렛 계정 목록
        </Typography>
      </StyledBox>

      {/* 상단 컨트롤 영역 */}
      <Box sx={{ 
        display: 'flex', 
        flexWrap: 'wrap',
        gap: 2, 
        mb: 2,
        alignItems: 'center'
      }}>
        <TextField
          label="상호명"
          size="small"
          value={merchantName}
          onChange={(e) => setMerchantName(e.target.value)}
          sx={{ minWidth: isMobile ? '100%' : '200px' }}
        />

        <TextField
          label="로그인아이디"
          size="small"
          value={loginId}
          onChange={(e) => setLoginId(e.target.value)}
          sx={{ minWidth: isMobile ? '100%' : '200px' }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={isNegativeBalance}
              onChange={(e) => setIsNegativeBalance(e.target.checked)}
              size="small"
            />
          }
          label="잔액 마이너스"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={isWithdrawalStopped}
              onChange={(e) => setIsWithdrawalStopped(e.target.checked)}
              size="small"
            />
          }
          label="출금정지"
        />

        <FormControl size="small" sx={{ minWidth: '100px' }}>
          <InputLabel>머천트</InputLabel>
          <Select
            value={merchantOption}
            onChange={(e) => setMerchantOption(e.target.value)}
            label="머천트"
          >
            <MenuItem value="234">234</MenuItem>
            <MenuItem value="740">740</MenuItem>
            <MenuItem value="741">741</MenuItem>
          </Select>
        </FormControl>

        <Tooltip title="검색">
          <IconButton
            sx={{
              backgroundColor: '#1976d2',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#1565c0',
              }
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <StyledButton
          startIcon={<SendIcon />}
          sx={{ whiteSpace: 'nowrap' }}
        >
          강제출금(푸시)
        </StyledButton>

        <StyledButton
          startIcon={<CheckCircleIcon />}
          sx={{ whiteSpace: 'nowrap' }}
        >
          일괄체크
        </StyledButton>

        
      </Box>

      {/* 테이블 */}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell
                  key={header.id}
                  align={header.align || 'left'}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} hover>
                <StyledTableCell padding="checkbox">
                  <Checkbox size="small" />
                </StyledTableCell>
                <StyledTableCell>{row.merchantName}</StyledTableCell>
                <StyledTableCell>{row.loginId}</StyledTableCell>
                <StyledTableCell align="right">{row.balance}</StyledTableCell>
                <StyledTableCell align="right">{row.availableAmount}</StyledTableCell>
                <StyledTableCell>
                  {row.withdrawalStatus}
                  <StyledStopButton
                    startIcon={<BlockIcon />}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    출금 정지하기
                  </StyledStopButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WalletAccountList;