import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const Notice = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const notices = [
    { id: 1, text: "11월 무이자할부 안내", link: "/notice/1" },
    { id: 2, text: "카드사 불가 업종", link: "/notice/2" },
    { id: 3, text: "불량거래 가맹점에 대한 안내", link: "/notice/3" },
    { id: 4, text: "업데이트 안내 ver.2.1.1", link: "/notice/4" },
    { id: 5, text: "고객센터 업무시간 안내", link: "/notice/5" }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === notices.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [notices.length]);

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      padding: '12px 20px',
      gap: 2,
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        minWidth: 'fit-content'
      }}>
        <CampaignIcon sx={{ 
          color: '#2185d0',
          fontSize: '1.2rem'
        }} />
        <Typography sx={{
          fontWeight: 600,
          color: '#2185d0',
          fontSize: '0.9rem'
        }}>
          공지사항
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        <Typography 
          component="a" 
          href={notices[currentIndex].link}
          sx={{
            color: '#495057',
            textDecoration: 'none',
            fontSize: '0.9rem',
            transition: 'all 0.2s ease',
            '&:hover': {
              color: '#1a1a1a',
              transform: 'scale(1.02)',
              fontWeight: 600,
            }
          }}
        >
          {notices[currentIndex].text}
        </Typography>
        <KeyboardArrowRightIcon sx={{ 
          color: '#adb5bd',
          fontSize: '1.2rem'
        }} />
      </Box>
    </Box>
  );
};

export default Notice;