import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer, 
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Tooltip
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import SummaryData from '../../Common/SummaryData';
import SearchOptions from '../../Common/SearchOptions';
import Notice from '../../Notice/Notice';

function CancelCompletionDetails() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [selectedApprovals, setSelectedApprovals] = useState([]);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (checked, approvalNo) => {
    if (checked) {
      setSelectedApprovals(prev => [...prev, approvalNo]);
    } else {
      setSelectedApprovals(prev => prev.filter(no => no !== approvalNo));
    }
  };

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: 2,
    ml: 4,
    transition: 'padding 0.2s',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '0px'
    }
  };

  // 테이블 헤더 정의 수정
  const tableHeaders = [
    // 파란색 헤더 (#1976d2)
    { id: 'no', label: 'No', color: '#1976d2' },
    { id: 'accountInfo', label: '계정정보', color: '#1976d2' },
    { id: 'status', label: '상태', color: '#1976d2' },
    { id: 'pg', label: 'PG', color: '#1976d2' },
    { id: 'cancelDate', label: '취소일시', color: '#1976d2' },
    { id: 'requestDate', label: '요청일시', color: '#1976d2' },
    
    // 하늘색 헤더 (#90caf9)
    { id: 'merchantMID', label: '가맹점 MID', color: '#90caf9' },
    { id: 'merchantTID', label: '가맹점 TID', color: '#90caf9' },
    { id: 'accountStatus', label: '계정상태', color: '#90caf9' },
    { id: 'refundAmount', label: '환입금액', color: '#90caf9' },
    { id: 'walletUse', label: '월렛사용여부', color: '#90caf9' },
    { id: 'amount', label: '거래금액', color: '#90caf9' },
    { id: 'approvalNo', label: '승인번호', color: '#90caf9' },
    { id: 'merchantName', label: '가맹점명', color: '#90caf9' },
    { id: 'receipt', label: '영수증', color: '#90caf9' },
    
    // 연한 녹색 헤더 (#a5d6a7)
    { id: 'issuer', label: '발급사', color: '#a5d6a7' },
    { id: 'cardNumber', label: '카드번호', color: '#a5d6a7' },
    { id: 'installment', label: '할부', color: '#a5d6a7' },
    { id: 'feeRate', label: '수수료율', color: '#a5d6a7' },
    { id: 'feeAmount', label: '수수료금액', color: '#a5d6a7' },
    { id: 'deposit', label: '보증금', color: '#a5d6a7' },
    { id: 'depositAmount', label: '입금액', color: '#a5d6a7' },
    { id: 'accountBalance', label: '계정잔액', color: '#a5d6a7' },
    { id: 'deductionAmount', label: '차감금액', color: '#a5d6a7' },
    
    // 흰색 헤더
    { id: 'vanTrxId', label: 'vanTrxidtrxid', color: '#ffffff' }
  ];

  // 더미 데이터 5개로 확장
  const dummyData = Array(5).fill(null).map((_, index) => ({
    id: index + 1,
    no: (index + 1).toString(),
    accountInfo: `user${123 + index}`,
    status: '취소완료',
    pg: 'KG이니시스',
    cancelDate: '2024-03-21 15:30:00',
    requestDate: '2024-03-20 14:30:00',
    merchantMID: `MID${123456 + index}`,
    merchantTID: `TID${789012 + index}`,
    accountStatus: '정상',
    refundAmount: '50,000',
    walletUse: 'Y',
    amount: '50,000',
    approvalNo: `AP${123456 + index}`,
    merchantName: `테스트가맹점${index + 1}`,
    receipt: 'Y',
    issuer: '신한카드',
    cardNumber: '123456******7890',
    installment: '일시불',
    feeRate: '3.3',
    feeAmount: '1,650',
    deposit: '1,000',
    depositAmount: '48,000',
    accountBalance: '100,000',
    deductionAmount: '2,000',
    vanTrxId: `VAN${123456 + index}`
  }));

  const styles = {
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            zIndex: 1199
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Notice />
      
      <Box sx={{ mb: '20px' }} />
      
      <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: 2000 }}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell 
                  key={header.id}
                  align={header.id.includes('WithCheck') ? 'center' : 'left'}
                  style={{ 
                    backgroundColor: header.color,
                    color: header.color === '#ffffff' || header.color === '#ffebee' ? '#000000' : '#ffffff',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    padding: '8px'
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <TableRow key={row.id}>
                {tableHeaders.map((header) => (
                  <TableCell 
                    key={`${row.id}-${header.id}`}
                    align={header.id.includes('WithCheck') ? 'center' : 'left'}
                    style={{
                      whiteSpace: 'nowrap',
                      padding: '8px'
                    }}
                  >
                    {header.id.includes('WithCheck') ? (
                      <Checkbox 
                        checked={selectedApprovals.includes(row.approvalNo)}
                        onChange={(e) => handleCheckboxChange(e.target.checked, row.approvalNo)}
                      />
                    ) : (
                      row[header.id]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mb: '20px' }} />

      <SummaryData />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default CancelCompletionDetails;
