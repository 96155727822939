import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// 필요한 컴포넌트들 import
import Notice from '../../Notice/Notice';
import SearchOptions from '../../Common/SearchOptions';
import DepositHistoryTable from './DepositHistoryTable';
import SummaryData from '../../Common/SummaryData';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    position: 'relative'
  },
  noticeBox: { 
    width: 'calc(100% - 20px)', 
    margin: '0 auto', 
    mb: 2 
  },
  tableContainer: {
    marginLeft: { xs: '10px', md: '40px' },
    marginRight: { xs: '10px', md: '40px' },
    maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 400px)' },
    overflowY: 'auto',
    overflowX: 'auto',
    width: '100%',
    backgroundColor: '#fff', 
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)', 
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '320px',
      boxSizing: 'border-box',
      mt: '64px',
      height: 'calc(100% - 64px)',
      border: 'none',
      backgroundColor: 'transparent',
      '@media (max-width: 768px)': {
        width: '100%',
        mt: '56px',
        height: 'calc(100% - 56px)'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    left: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  }
};

function SettlementHoldDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  const [searchPanelOpen, setSearchPanelOpen] = useState(!isMobile);
  const [selectedRows, setSelectedRows] = useState([]);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      if (!isMobile && !isTablet) {
        setSearchPanelOpen(true);
      } else if (isMobile || isTablet) {
        setSearchPanelOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile, isTablet]);

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '0px'
    },
    pl: {
      xs: '10px',
      md: '50px'
    },
    mx: {
      xs: '10px',
      md: '50px'
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 패널 열기 버튼 - 패널이 닫혔을 때만 표시 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: isMobile ? '30px' : '20px',
            height: isMobile ? '50px' : '40px',
            borderRadius: '4px 0 0 4px',
            zIndex: 1,
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch} 
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>

      {/* SummaryData 컴포넌트는 항상 표시 */}
      <SummaryData />

      {/* Notice 영역 */}
      <Box sx={styles.noticeBox}>
        <Notice />
      </Box>

      {/* 테이블과 버튼 사이에 추가 여백을 위한 Box */}
      <Box sx={{ mb: 2 }} />

      

      {/* SettlementHoldDetailsTables 추가 */}
      <DepositHistoryTable
        searchPanelOpen={searchPanelOpen}
        setSearchPanelOpen={setSearchPanelOpen}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </Box>
  );
}

export default SettlementHoldDetails;
