import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Stack,
  Divider,
  Button,
  Fab,
  useMediaQuery
} from '@mui/material';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

// Material-UI 아이콘
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// 컴포넌트 imports
import Sidebar from './Components/Sidebar';
// 일별/월별 결제 통계를 카드 형태로 보여주는 컴포넌트

// 머천트 잔액, 미출금, 신규 가맹점 현황을 테이블로 보여주는 컴포넌트

// 오늘의 거래액과 정산예정액, 주간 거래 추이 차트를 보여주는 컴포넌트
import TransactionStats from './Components/Dashboard/components/TransactionStats/TransactionStats';
// 이���달 거래 상세 내역과 월간 거래 추이 차트를 보여주는 컴포넌트

// 과세/면세 결제 현황을 테이블과 차트로 보여주는 컴포넌트
import TaxStats from './Components/Dashboard/components/MerchantStats/TaxStats/TaxStats';
import BusinessStats from './Components/Dashboard/components/MerchantStats/BusinessStats/BusinessStats';
import AuthStats from './Components/Dashboard/components/MerchantStats/AuthStats/AuthStats';
import Notice from './Components/Notice/Notice';
import FailHistory from './Components/PaymentFolder/FailHistory/FailHistory';
import AlarmHistory from './Components/PaymentFolder/AlarmHistory/AlarmHistory';
import PaymentHistory from './Components/PaymentFolder/PaymentHistory/PaymentHistory';
import PaymentAggregation from './Components/PaymentFolder/PaymentAggregation/PaymentAggregation';
import NonMerchantManagement from './Components/PaymentFolder/NonMerchantManagement/NonMerchantManagement';
import RegularPayment from './Components/PaymentFolder/RegularPayment/RegularPayment';
import Header from './Components/Header';
import AdminPage from './Components/AdminFolder/AdminPage';
// import TestPage from './Components/AdminFolder/TestPage';
import ErrorLog from './Components/PaymentFolder/ErrorLog/ErrorLog';
// DuplicateCheck import 추가
import DuplicateCheck from './Components/PaymentFolder/DuplicateCheck/DuplicateCheck';
import CancelRequestDetails from './Components/PaymentFolder/CancelRequestDetails/CancelRequestDetails';
import CancelCompletionDetails from './Components/PaymentFolder/CancelCompletionDetails/CancelCompletionDetails';
import CancelRefundRegistration from './Components/PaymentFolder/CancelRefundRegistration/CancelRefundRegistration';
import ComplaintDetails from './Components/SETTLEMENT/ComplaintDetails/ComplaintDetails';
import SettlementCalendar from './Components/SETTLEMENT/SettlementCalendar/SettlementCalendar'; // 추가
import SettlementHoldDetails from './Components/SETTLEMENT/SettlementHoldDetails/SettlementHoldDetails';
import ScheduledSettlementDetails from './Components/SETTLEMENT/ScheduledSettlementDetails/ScheduledSettlementDetails'; // 추가
import ScheduledSettlementSummary from './Components/SETTLEMENT/ScheduledSettlementSummary/ScheduledSettlementSummary'; // 추가
import DepositHistory from './Components/SETTLEMENT/DepositHistory/DepositHistory';
import NonWallet from './Components/SETTLEMENT/Non-wallet/Nonwallet';
import Wallet from './Components/SETTLEMENT/Wallet/Wallet';
import WalletInOutHistory from './Components/SETTLEMENT/WalletInOutHistory/WalletInOutHistory';
import TransferHistory from './Components/SETTLEMENT/WalletInOutHistory/optionButton/TransferHistory';
import WalletAccountList from './Components/SETTLEMENT/WalletInOutHistory/optionButton/WalletAccountList';
import UnconfirmedDeposits from './Components/SETTLEMENT/WalletInOutHistory/optionButton/UnconfirmedDeposits';
import WithdrawalHold from './Components/SETTLEMENT/WalletInOutHistory/optionButton/WithdrawalHold';
import InputValidation from './Components/SETTLEMENT/InputValidation/InputValidation';
import TaxIT from './Components/SETTLEMENT/TaxIT/TaxIT';
// Dashboard import 추가
import Dashboard from './Components/Dashboard/Dashboard';

// Google Fonts import 추가
import '@fontsource/crimson-text';

// Theme 설정 추가
const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans KR',
      'Poppins',
      'sans-serif'
    ].join(','),
    h5: {
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: 1.5
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 500,
      lineHeight: 1.5
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5
    }
  }
});



function App() {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const contentPadding = isMobile ? '1px' : '24px';

  // SettlementDownButton의 모든 팝업 경로 추가
  const hideHeaderPaths = [
    '/settlement/wallet-account-list',
    '/settlement/transfer-history',
    '/settlement/unconfirmed-deposits',
    '/settlement/withdrawal-hold'
  ];

  // 현재 경로가 hideHeaderPaths에 포함되어 있는지 확인
  const shouldHideHeader = hideHeaderPaths.some(path => 
    location.pathname.includes(path)
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById('scroll-container');
      setShowScrollTop(scrollContainer.scrollTop > 300);
    };

    const scrollContainer = document.getElementById('scroll-container');
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    const scrollContainer = document.getElementById('scroll-container');
    scrollContainer.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {!shouldHideHeader && <Header />}
        <Box 
          id="scroll-container"
          sx={{ 
            flexGrow: 1,
            overflowY: 'auto',
            marginTop: shouldHideHeader ? '0' : '64px',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
              borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            p: isMobile ? '1px' : 3
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/fail-list" element={<FailHistory />} />
            <Route path="/alarm-history" element={<AlarmHistory />} />
            <Route path="/payment-history" element={<PaymentHistory />} />
            <Route path="/payment-aggregation" element={<PaymentAggregation />} />
            <Route path="/payment/non-merchant" element={<NonMerchantManagement />} />
            <Route path="/auto-payment" element={<RegularPayment />} />
            {/* <Route path="/test" element={<TestPage />} /> */}
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/error-log" element={<ErrorLog />} />
            <Route path="/duplicate-check" element={<DuplicateCheck />} />
            <Route path="/cancel-requests" element={<CancelRequestDetails />} />
            <Route path="/cancel-completed" element={<CancelCompletionDetails />} />
            <Route path="/cancel-refund" element={<CancelRefundRegistration />} />
            <Route path="/complaint-details" element={<ComplaintDetails />} />
            <Route path="/settlement/complaint" element={<ComplaintDetails />} />
            <Route path="/settlement/calendar" element={<SettlementCalendar />} />
            <Route path="/settlement/hold" element={<SettlementHoldDetails />} />
            <Route path="/settlement/scheduled" element={<ScheduledSettlementDetails />} />
            <Route path="/settlement/summary" element={<ScheduledSettlementSummary />} />
            <Route path="/settlement/deposit" element={<DepositHistory />} />
            <Route path="/settlement/transfer/non-wallet" element={<NonWallet />} /> {/* 추가된 부분 */}
            <Route path="/settlement/transfer/wallet" element={<Wallet />} />
            <Route path="/settlement/wallet-history" element={<WalletInOutHistory />} />
            <Route path="/settlement/transfer-history" element={<TransferHistory />} />
            <Route path="/settlement/wallet-account-list" element={<WalletAccountList />} />
            <Route path="/settlement/unconfirmed-deposits" element={<UnconfirmedDeposits/>} />
            <Route path="/settlement/withdrawal-hold" element={<WithdrawalHold/>} />
            <Route path="/settlement/input-validation" element={<InputValidation />} />
            <Route path="/settlement/tax-it" element={<TaxIT />} />
           
          </Routes>
        </Box>
      </Box>

      <Fab 
        color="primary" 
        size="small"
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          display: showScrollTop ? 'flex' : 'none'
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </ThemeProvider>
  );
}

export default App;