import React, { useState, useEffect } from 'react';
import { 
  Box,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SummaryData from '../../Common/SummaryData';
import DateRangePicker from '../../Common/DateRangePicker';
import Notice from '../../Notice/Notice';

function CancelRefundRegistration() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  const tableHeaders = [
    { id: 'no', label: 'No', width: '60px' },
    { id: 'actions', label: '액션', width: '160px' },
    { id: 'pg', label: '거래구분', width: '100px' },
    { id: 'amount', label: '거래금액', width: '200px' },
    { id: 'refundAmount', label: '환입금액', width: '100px' },
    { id: 'transactionDate', label: '거래일시', width: '120px' },
    { id: 'paymentType', label: '취소여부', width: '80px' },
    { id: 'approvalNo', label: '승인번호', width: '100px' }
  ];

  const dummyData = [
    {
      id: 1,
      no: '1',
      actions: ['취소등록', '환입등록'],
      pg: 'Galaxia',
      amount: '54,781 (59,000/6.5/4,219)',
      refundAmount: '0',
      transactionDate: '20241209002127',
      paymentType: 'PAY',
      approvalNo: '30021713'
    },
    // ... 더 많은 더미 데이터 추가 가능
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box component="main" sx={{ 
      flexGrow: 1, 
      p: 3, 
      mt: 2, 
      ml: { xs: 0, md: 4 },
      px: { xs: 1, md: 3 }
    }}>
      <Notice />
      
      <Box sx={{ mb: '5px' }} />
      
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: { xs: 1, md: 2 },
        mb: 2,
        backgroundColor: '#fff',
        padding: { xs: '8px', md: '16px' },
        borderRadius: '4px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          width: { xs: '100%', sm: 'auto' },
          justifyContent: { xs: 'space-between', sm: 'flex-start' }
        }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
          <Tooltip title="내역조회">
            <IconButton 
              onClick={() => console.log('Search clicked')}
              sx={{
                backgroundColor: '#1976d2',
                color: '#fff',
                ml: { xs: 0, sm: 1 },
                '&:hover': {
                  backgroundColor: '#1565c0'
                }
              }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ 
        mt: 2,
        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
        borderRadius: '4px',
        overflowX: 'auto',
        '& .MuiTable-root': {
          tableLayout: 'fixed',
          minWidth: '1000px'
        }
      }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell 
                  key={header.id}
                  align="center"
                  style={{
                    backgroundColor: '#1976d2',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    width: header.width,
                    padding: '12px 8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <TableRow key={row.id} hover>
                {tableHeaders.map((header) => (
                  <TableCell 
                    key={`${row.id}-${header.id}`}
                    align={header.id === 'amount' || header.id === 'refundAmount' ? 'right' : 'center'}
                    style={{
                      padding: '8px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {header.id === 'actions' ? (
                      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ 
                            backgroundColor: '#4caf50',
                            fontSize: '0.75rem',
                            minWidth: 'unset',
                            padding: '4px 8px',
                            '&:hover': {
                              backgroundColor: '#388e3c'
                            }
                          }}
                        >
                          취소등록
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ 
                            backgroundColor: '#00bcd4',
                            fontSize: '0.75rem',
                            minWidth: 'unset',
                            padding: '4px 8px',
                            '&:hover': {
                              backgroundColor: '#00838f'
                            }
                          }}
                        >
                          환입등록
                        </Button>
                      </Box>
                    ) : (
                      row[header.id]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <SummaryData />
    </Box>
  );
}

export default CancelRefundRegistration; 