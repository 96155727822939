import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  IconButton, 
  Tooltip, 
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import ApiIcon from '@mui/icons-material/Api';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PieChart } from '@mui/x-charts/PieChart';

const APIStatusHistory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [records, setRecords] = useState([
    {
      time: '2024-12-06 09:17:44',
      select: '정상',
      update: '정상',
      welcome: '정상',
      ksnet: '정상',
      wallet: '정상'
    },
    // ... 더 많은 데이터
  ]);

  const handleRefresh = () => {
    console.log('API 상태 새로고침');
  };

  const chartData = [
    { id: 0, value: 100, label: '정상', color: '#4caf50' },
    { id: 1, value: 0, label: '오류', color: '#f44336' }
  ];

  return (
    <Card sx={{ p: 3 }}>
      {/* 헤더 영역 */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ApiIcon sx={{ color: '#1976d2' }} />
          <Typography variant="h6" sx={{ fontWeight: 600, flexGrow: 1 }}>
            API 상태
          </Typography>
          <IconButton 
            onClick={handleRefresh}
            sx={{ 
              ml: 1,
              color: '#51cbce',
              bgcolor: 'rgba(81, 203, 206, 0.1)',
              '&:hover': {
                bgcolor: 'rgba(81, 203, 206, 0.2)'
              }
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      {/* 검색 필드 */}
      <TextField
        fullWidth
        placeholder="모든 필드를 검색합니다."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 3 }}
        size="small"
      />

      {/* 메인 콘텐츠 영역 */}
      <Box sx={{ display: 'flex', gap: 3 }}>
        {/* ��이블 영역 */}
        <TableContainer 
          component={Paper} 
          sx={{ 
            flexGrow: 1,
            height: 'calc(100vh - 280px)',
            '&::-webkit-scrollbar': { width: '8px' },
            '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
            '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px' }
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>시간</TableCell>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>SELECT</TableCell>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>UPDATE</TableCell>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>WELCOME</TableCell>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>KSNET</TableCell>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>WALLET</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((record, index) => (
                <TableRow key={index} hover>
                  <TableCell>{record.time}</TableCell>
                  <TableCell sx={{ color: record.select === '정상' ? '#4caf50' : '#f44336', fontWeight: 500 }}>
                    {record.select}
                  </TableCell>
                  <TableCell sx={{ color: record.update === '정상' ? '#4caf50' : '#f44336', fontWeight: 500 }}>
                    {record.update}
                  </TableCell>
                  <TableCell sx={{ color: record.welcome === '정상' ? '#4caf50' : '#f44336', fontWeight: 500 }}>
                    {record.welcome}
                  </TableCell>
                  <TableCell sx={{ color: record.ksnet === '정상' ? '#4caf50' : '#f44336', fontWeight: 500 }}>
                    {record.ksnet}
                  </TableCell>
                  <TableCell sx={{ color: record.wallet === '정상' ? '#4caf50' : '#f44336', fontWeight: 500 }}>
                    {record.wallet}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* 차트 영역 */}
        <Card sx={{ 
          width: '300px',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            API State
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography sx={{ color: '#4caf50' }}>
              정상: 1000(100.00%)
            </Typography>
            <Typography sx={{ color: '#f44336' }}>
              오류: 0(0.00%)
            </Typography>
          </Box>
          <PieChart
            series={[
              {
                data: chartData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30 },
              },
            ]}
            height={160}
            margin={{ top: -300, bottom: 0 }}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'top', horizontal: 'middle' },
                padding: 0
              }
            }}
          />
        </Card>
      </Box>
    </Card>
  );
};

export default APIStatusHistory;
