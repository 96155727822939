import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled } from '@mui/material';
import TaxIcons from '../../Common/TaxIcons';

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: color || '#495057',
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
}));

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px',
    padding: '0px',
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  }
};

const dummyData = [
  {
    taxType: '과세',
    pg: 'KG이니시스',
    businessType: '사업자',
    taxWithholding: '과세',
    paymentMethod: '신용카드',
    authMethod: '간편결제',
    agencyName: '테스트에이전시',
    merchantName: '테스트가맹점',
    businessNumber: '123-45-67890',
    residentNumber: '880101-1******',
    approvalAmount: '1,000,000',
    cancelAmount: '0',
    actualAmount: '909,091',
    actualCount: '10',
    supplyAmount: '909,091',
    vatAmount: '90,909',
    feeAmount: '33,000',
    feeRate: '3.3%',
    supply: '30,000',
    vat: '3,000',
    contact: '010-1234-5678',
    email: 'test@example.com'
  },
  {
    taxType: '과세',
    pg: '토스페이먼츠',
    businessType: '사업자',
    taxWithholding: '과세',
    paymentMethod: '신용카드',
    authMethod: '간편결제',
    agencyName: '테스트에이전시2',
    merchantName: '테스트가맹점2',
    businessNumber: '123-45-67891',
    residentNumber: '900101-1******',
    approvalAmount: '800,000',
    cancelAmount: '0',
    actualAmount: '750,000',
    actualCount: '8',
    supplyAmount: '750,000',
    vatAmount: '75,000',
    feeAmount: '26,400',
    feeRate: '3.3%',
    supply: '24,000',
    vat: '2,400',
    contact: '010-2345-6789',
    email: 'test2@example.com'
  },
  {
    taxType: '과세',
    pg: '나이스페이',
    businessType: '개인',
    taxWithholding: '과세',
    paymentMethod: '신용카드',
    authMethod: '일반결제',
    agencyName: '테스트에이전시3',
    merchantName: '테스트가맹점3',
    businessNumber: '123-45-67892',
    residentNumber: '910101-1******',
    approvalAmount: '500,000',
    cancelAmount: '0',
    actualAmount: '480,000',
    actualCount: '5',
    supplyAmount: '480,000',
    vatAmount: '48,000',
    feeAmount: '16,500',
    feeRate: '3.3%',
    supply: '15,000',
    vat: '1,500',
    contact: '010-3456-7890',
    email: 'test3@example.com'
  },
  {
    taxType: '비과세',
    pg: 'KG이니시스',
    businessType: '사업자',
    taxWithholding: '비과세',
    paymentMethod: '계좌이체',
    authMethod: '일반결제',
    agencyName: '테스트에이전시4',
    merchantName: '테스트가맹점4',
    businessNumber: '123-45-67893',
    residentNumber: '920101-1******',
    approvalAmount: '1,000,000',
    cancelAmount: '0',
    actualAmount: '1,000,000',
    actualCount: '12',
    supplyAmount: '1,000,000',
    vatAmount: '100,000',
    feeAmount: '33,000',
    feeRate: '3.3%',
    supply: '30,000',
    vat: '3,000',
    contact: '010-4567-8901',
    email: 'test4@example.com'
  },
  {
    taxType: '과세',
    pg: '토스페이먼츠',
    businessType: '개인',
    taxWithholding: '과세',
    paymentMethod: '가상계좌',
    authMethod: '일반결제',
    agencyName: '테스트에이전시5',
    merchantName: '테스트가맹점5',
    businessNumber: '123-45-67894',
    residentNumber: '930101-1******',
    approvalAmount: '300,000',
    cancelAmount: '0',
    actualAmount: '280,000',
    actualCount: '3',
    supplyAmount: '280,000',
    vatAmount: '28,000',
    feeAmount: '9,900',
    feeRate: '3.3%',
    supply: '9,000',
    vat: '900',
    contact: '010-5678-9012',
    email: 'test5@example.com'
  },
  {
    taxType: '과세',
    pg: '나이스페이',
    businessType: '사업자',
    taxWithholding: '과세',
    paymentMethod: '신용카드',
    authMethod: '간편결제',
    agencyName: '테스트에이전시6',
    merchantName: '테스트가맹점6',
    businessNumber: '123-45-67895',
    residentNumber: '940101-1******',
    approvalAmount: '650,000',
    cancelAmount: '0',
    actualAmount: '600,000',
    actualCount: '7',
    supplyAmount: '600,000',
    vatAmount: '60,000',
    feeAmount: '21,450',
    feeRate: '3.3%',
    supply: '19,500',
    vat: '1,950',
    contact: '010-6789-0123',
    email: 'test6@example.com'
  },
  
];

const tableHeaders = [
  { id: 'taxType', label: '과세/비과세', color: '#e8f4ff' },      // 파란색 그룹
  { id: 'pg', label: 'PG', color: '#e8f4ff' },
  { id: 'businessType', label: '사업자/개인', color: '#e8f4ff' },
  { id: 'taxWithholding', label: '과세/원천', color: '#e8f4ff' },
  { id: 'paymentMethod', label: '결제수단', color: '#e8f4ff' },
  { id: 'authMethod', label: '인증방식', color: '#e8f4ff' },
  
  { id: 'agencyName', label: '에이전시명', color: '#e3f2fd' },    // 하늘색 그룹
  { id: 'merchantName', label: '가맹점명', color: '#e3f2fd' },
  { id: 'businessNumber', label: '사업자번호', color: '#e3f2fd' },
  
  { id: 'residentNumber', label: '주민번호', color: '#e8f5e9' },  // 연두색 그룹
  { id: 'approvalAmount', label: '승인금액', color: '#e8f5e9' },
  { id: 'cancelAmount', label: '취소금액', color: '#e8f5e9' },
  { id: 'actualAmount', label: '실매출 금액', color: '#e8f5e9' },
  { id: 'actualCount', label: '실매출 건수', color: '#e8f5e9' },
  
  { id: 'supplyAmount', label: '공급가액', color: '#ffffff' },    // 흰색 그룹
  { id: 'vatAmount', label: '부가세액', color: '#ffffff' },
  { id: 'feeAmount', label: '수수료금액', color: '#ffffff' },
  { id: 'feeRate', label: '수수료율(VAT포함)', color: '#ffffff' },
  { id: 'supply', label: '공급가', color: '#ffffff' },
  { id: 'vat', label: '부가세', color: '#ffffff' },
  { id: 'contact', label: '연락처', color: '#ffffff' },
  { id: 'email', label: '이메일 주소', color: '#ffffff' },
  { id: 'graph', label: 'Graph', color: '#ffffff' }
];

const BarGraph = ({ value }) => {
  const maxValue = 1000000;  // 100%에 해당하는 값
  const percentage = Math.min((value || 0) / maxValue * 100, 100); // 값이 없으면 0, 최대 100%
  
  return (
    <Box sx={{ 
      width: '100px',  // 그래프 전체 너비
      height: '20px',  // 그래프 높이
      display: 'flex',
      alignItems: 'center',
      bgcolor: '#f5f5f5',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <Box sx={{
        width: `${percentage}%`,
        height: '100%',
        bgcolor: '#4acccd',
        transition: 'width 0.3s ease',
        background: 'linear-gradient(90deg, #4acccd 0%, #2ebed3 100%)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '4px'
      }} />
    </Box>
  );
};

const TaxITTable = () => {
  return (
    <Box>
      <TaxIcons onIconClick={(value) => console.log(value)} />
      <Box mb={0.5} />
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.id}
                  style={{
                    backgroundColor: header.color,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.taxType}</StyledTableCell>
                <StyledTableCell>{row.pg}</StyledTableCell>
                <StyledTableCell>{row.businessType}</StyledTableCell>
                <StyledTableCell>{row.taxWithholding}</StyledTableCell>
                <StyledTableCell>{row.paymentMethod}</StyledTableCell>
                <StyledTableCell>{row.authMethod}</StyledTableCell>
                <StyledTableCell>{row.agencyName}</StyledTableCell>
                <StyledTableCell>{row.merchantName}</StyledTableCell>
                <StyledTableCell>{row.businessNumber}</StyledTableCell>
                <StyledTableCell>{row.residentNumber}</StyledTableCell>
                <StyledTableCell>{row.approvalAmount}</StyledTableCell>
                <StyledTableCell>{row.cancelAmount}</StyledTableCell>
                <StyledTableCell>{row.actualAmount}</StyledTableCell>
                <StyledTableCell>{row.actualCount}</StyledTableCell>
                <StyledTableCell>{row.supplyAmount}</StyledTableCell>
                <StyledTableCell>{row.vatAmount}</StyledTableCell>
                <StyledTableCell>{row.feeAmount}</StyledTableCell>
                <StyledTableCell>{row.feeRate}</StyledTableCell>
                <StyledTableCell>{row.supply}</StyledTableCell>
                <StyledTableCell>{row.vat}</StyledTableCell>
                <StyledTableCell>{row.contact}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>
                  <BarGraph value={parseInt(row.actualAmount.replace(/,/g, ''))} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TaxITTable;
