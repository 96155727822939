import React, { useState } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const ErrorMessage = () => {
  const [sortOrder, setSortOrder] = useState('asc');
  const [errorMessages, setErrorMessages] = useState([
    { id: 1, message: '머천트 잔액부족', details: '머천트 잔액이 부족합니다.', date: '2024-07-30' },
    { id: 2, message: 'B012', details: '오류 코드 B012 발생', date: '2024-07-29' },
    { id: 3, message: 'asasd', details: '알 수 없는 오류', date: '2024-07-28' }
  ]);

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);

    const sortedMessages = [...errorMessages].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setErrorMessages(sortedMessages);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <ErrorIcon sx={{ color: '#d32f2f' }} />
        <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
          오류메세지
        </Typography>
      </Box>

      <Button variant="contained" sx={{ mb: 2, backgroundColor: '#d32f2f', '&:hover': { backgroundColor: '#b71c1c' } }}>
        에러 메시지 추가
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>행동</TableCell>
              <TableCell>에러 메시지</TableCell>
              <TableCell>상세 내용</TableCell>
              <TableCell onClick={handleSortClick} sx={{ cursor: 'pointer' }}>
                등록시간 {sortOrder === 'asc' ? '▲' : '▼'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errorMessages.map((error) => (
              <TableRow key={error.id}>
                <TableCell>
                  <Button variant="contained" color="success">변경</Button>
                </TableCell>
                <TableCell>{error.message}</TableCell>
                <TableCell>{error.details}</TableCell>
                <TableCell>{error.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ErrorMessage;
