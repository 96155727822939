import React from 'react';
import ButtonWidget from '../../Common/TransferButton';

const OutputTable = () => {
    const containerStyle = {
        width: '100%',
        overflowX: 'auto',
        WebkitOverflowScrolling: 'touch', // iOS 스크롤 부드럽게
        msOverflowStyle: '-ms-autohiding-scrollbar', // Windows에서 스크롤바 자동 숨김
    };

    const tableWrapperStyle = {
        minWidth: '1000px', // 테이블 최소 너비 설정
    };

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
        margin: '20px 0',
    };

    const thStyle = {
        padding: '12px 15px',
        textAlign: 'center',
        backgroundColor: '#007BFF',
        color: 'white',
        fontSize: '14px',
        whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지
    };

    const tdStyle = {
        padding: '12px 15px',
        textAlign: 'center',
        borderBottom: '1px solid #dddddd',
        fontSize: '14px',
        whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지
    };

    return (
        <div style={containerStyle}>
            <ButtonWidget showFirst={true} />
            <div style={tableWrapperStyle}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>잔액</th>
                            <th style={thStyle}>총건수</th>
                            <th style={thStyle}>총금액</th>
                            <th style={thStyle}>성공/실패 건수</th>
                            <th style={thStyle}>성공금액</th>
                            <th style={thStyle}>실패금액</th>
                            <th style={thStyle}>하이픈잔액</th>
                            <th style={thStyle}>하이픈잔액</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={tdStyle}>6,672,476</td>
                            <td style={tdStyle}>54</td>
                            <td style={tdStyle}>31,287,767</td>
                            <td style={tdStyle}>53 / 1</td>
                            <td style={tdStyle}>33,608,567</td>
                            <td style={tdStyle}>-2,320,800</td>
                            <td style={tdStyle}>170</td>
                            <td style={tdStyle}>4,097</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OutputTable;
