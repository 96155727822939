import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper
} from '@mui/material';
import styled from '@emotion/styled';

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: color || '#495057',
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
}));

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

const tableHeaders = [
  { id: 'no', label: 'No' },
  { id: 'pg', label: 'PG' },
  { id: 'mid', label: 'MID' },
  { id: 'tid', label: 'TID' },
  { id: 'transactionDate', label: '거래일시' },
  { id: 'partnerName', label: '상호명' }, // "상호명" 추가
  { id: 'amount', label: '금액' },
  { id: 'count', label: '건수' },
  { id: 'settlementAmount', label: '정산금액' },
  { id: 'supplyPrice', label: '공급가' },
  { id: 'vat', label: '부가세' },
  { id: 'total', label: '합계' },
  { id: 'amount', label: '금액' },
  { id: 'count', label: '건수' },
  { id: 'settlementAmount', label: '정산금액' },
  { id: 'supplyPrice', label: '공급가' },
  { id: 'vat', label: '부가세' },
  { id: 'total', label: '합계' },
  { id: 'grandTotal', label: '총합' }
];

const dummyData = [
  {
    no: 1,
    pg: 'KICC',
    mid: 'MID123',
    tid: 'TID123',
    transactionDate: '2024-01-01',
    amount: 500000,
    count: 5,
    settlementAmount: 480000,
    supplyPrice: 490000,
    vat: 10000,
    total: 500000,
    grandTotal: 500000,
  },
  {
    no: 2,
    pg: 'PayUp',
    mid: 'MID456',
    tid: 'TID456',
    transactionDate: '2024-01-02',
    amount: 300000,
    count: 3,
    settlementAmount: 300000,
    supplyPrice: 300000,
    vat: 0,
    total: 300000,
    grandTotal: 300000,
  },
];

const DepositHistoryTable = () => {
  return (
    <>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  align={header.id === 'no' ? 'center' : 'left'}
                  style={{ 
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.no}>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={`${row.no}-${header.id}`}
                    align={header.id === 'no' ? 'center' : 'left'}
                  >
                    {row[header.id]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default DepositHistoryTable;
