import React, { useState, useEffect } from 'react';
import { Box, Drawer, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SummaryData from '../../Common/SummaryData';
import SearchOptions from '../../Common/SearchOptions';
import CancelRequestTables from './CancelRequestTables';
import DefaultTables from './DefaultTables';
import Notice from '../../Notice/Notice';

function CancelRequestDetails() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [selectedApprovals, setSelectedApprovals] = useState([]);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (checked, approvalNo) => {
    if (checked) {
      setSelectedApprovals(prev => [...prev, approvalNo]);
    } else {
      setSelectedApprovals(prev => prev.filter(no => no !== approvalNo));
    }
  };

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  // 동적 스타일 계산
  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: 2,
    ml: 4,
    transition: 'padding 0.2s',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '0px'
    }
  };

  const styles = {
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 패널 열기 버튼 - 패널이 닫혔을 때만 표시 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            zIndex: 1199  // Drawer의 zIndex보다 1 작게 설정
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Notice />
      
      <Box sx={{ mb: '20px' }} />
      
      <CancelRequestTables 
        onCheckboxChange={handleCheckboxChange}
        selectedApprovals={selectedApprovals}
      />
      
      <Box sx={{ mb: '20px' }} />
      
      <DefaultTables 
        onCheckboxChange={handleCheckboxChange}
        selectedApprovals={selectedApprovals}
      />

      <SummaryData />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default CancelRequestDetails; 