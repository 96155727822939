import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper,
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl
} from '@mui/material';
import Notice from '../../Notice/Notice';
import NonMerchantTable from './NonMerchantTable';
import Breadcrumb from '../../Common/Breadcrumb';
import SummaryData from '../../Common/SummaryData';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '50px',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)', 
    margin: '0 auto', 
    mb: 2 
  },
  searchBox: {
    width: 'calc(100% - 20px)',
    margin: '0 auto',
    mb: 2
  }
};

function NonMerchantManagement() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [accountStatus, setAccountStatus] = useState('all');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box component="main" sx={styles.mainBox}>
      <SummaryData />

      <Box sx={styles.noticeBox}>
        <Notice />
      </Box>

      

      <Box sx={styles.searchBox}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                placeholder="로그인아이디, MID,가맹점명"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={accountStatus}
                  onChange={(e) => setAccountStatus(e.target.value)}
                >
                  <FormControlLabel 
                    value="all" 
                    control={<Radio />} 
                    label="전체" 
                    sx={{ mr: 4 }}
                  />
                  <FormControlLabel 
                    value="active" 
                    control={<Radio />} 
                    label="사용중" 
                    sx={{ mr: 4 }}
                  />
                  <FormControlLabel 
                    value="view" 
                    control={<Radio />} 
                    label="조회용" 
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box sx={{ 
        width: 'calc(100% - 20px)',
        margin: '0 auto'
      }}>
        <NonMerchantTable />
      </Box>
    </Box>
  );
}

export default NonMerchantManagement;
