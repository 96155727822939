import React from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper
} from '@mui/material';
import styled from '@emotion/styled';

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: color || '#495057',
}));

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9', 
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px', 
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    minWidth: 2000
  }
};

const tableHeaders = [
  { id: 'no', label: 'No', color: '#1976d2' },
  { id: 'accountInfo', label: '계정정보', color: '#1976d2' },
  { id: 'status', label: '상태', color: '#1976d2' },
  { id: 'pg', label: 'PG', color: '#1976d2' },
  { id: 'requestDate', label: '요청일시', color: '#1976d2' },
  { id: 'transactionDate', label: '거래일시', color: '#1976d2' },
  { id: 'merchantMID', label: '가맹점 MID', color: '#1976d2' },
  { id: 'merchantTID', label: '가맹점 TID', color: '#1976d2' },
  
  { id: 'accountStatus', label: '계정상태', color: '#90caf9' },
  { id: 'refundAmount', label: '환입금액', color: '#90caf9' },
  { id: 'walletUse', label: '월렛사용여부', color: '#90caf9' },
  { id: 'amount', label: '거래금액', color: '#90caf9' },
  { id: 'approvalNo', label: '승인번호', color: '#90caf9' },
  { id: 'merchantName', label: '가맹점명', color: '#90caf9' },
  { id: 'receipt', label: '영수증', color: '#90caf9' },
  
  { id: 'issuer', label: '발급사', color: '#ffffff' },
  { id: 'cardNumber', label: '카드번호', color: '#ffffff' },
  { id: 'installment', label: '할부', color: '#ffffff' },
  { id: 'feeRate', label: '수수료율/금액', color: '#ffffff' },
  { id: 'deposit', label: '보증금', color: '#ffffff' },
  { id: 'depositAmount', label: '입금액', color: '#ffffff' },
  { id: 'accountBalance', label: '계정잔액', color: '#ffffff' },
  { id: 'deductionAmount', label: '차감금액', color: '#ffffff' },
  { id: 'vanTrxId', label: 'vanTrxidtrxid', color: '#ffffff' }
];

const dummyData = Array(5).fill(null).map((_, index) => ({
  id: index + 1,
  no: (index + 1).toString(),
  accountInfo: `user${123 + index}`,
  status: '취소요청',
  pg: 'KG이니시스',
  requestDate: '2024-03-20 14:30:00',
  transactionDate: '2024-03-20 14:25:00',
  merchantMID: `MID${123456 + index}`,
  merchantTID: `TID${789012 + index}`,
  accountStatus: '정상',
  refundAmount: '50,000',
  walletUse: 'Y',
  amount: '50,000',
  approvalNo: `AP${123456 + index}`,
  merchantName: `테스트가맹점${index + 1}`,
  receipt: 'Y',
  issuer: '신한카드',
  cardNumber: '123456******7890',
  installment: '일시불',
  feeRate: '3.3',
  deposit: '1,000',
  depositAmount: '48,000',
  accountBalance: '100,000',
  deductionAmount: '2,000',
  vanTrxId: `VAN${123456 + index}`
}));

const DefaultTables = () => {
  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table sx={styles.table} stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <StyledTableCell 
                key={header.id}
                align="center"
                style={{ 
                  backgroundColor: header.color,
                  color: header.color === '#ffffff' ? '#000000' : '#ffffff',
                  fontWeight: 'bold',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1
                }}
              >
                {header.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyData.map((row) => (
            <TableRow key={row.id}>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={`${row.id}-${header.id}`}
                  align="center"
                >
                  {row[header.id]}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DefaultTables;