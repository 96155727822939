import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CheckCircle, Sync, Cancel } from '@mui/icons-material';

const SettlementButton = ({ onSettlementOption, selectedItems = [] }) => {
    const handleSettlementOption = (optionType) => {
        if (selectedItems.length === 0) {
            alert('정산할 항목을 선택해주세요.');
            return;
        }
        onSettlementOption?.(optionType, selectedItems);
    };

    const buttons = [
        {
            label: '보류반영',
            icon: <CheckCircle />,
            color: '#2196f3',
            onClick: () => handleSettlementOption('보류반영')
        },
        {
            label: '상계반영',
            icon: <Sync />,
            color: '#ffa000',
            onClick: () => handleSettlementOption('상계반영')
        },
        {
            label: '정산반영',
            icon: <Cancel />,
            color: '#4caf50',
            onClick: () => handleSettlementOption('정산반영')
        }
    ];

    return (
        <Box>
            <Typography
                variant="subtitle1"
                sx={{
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    color: '#495057',
                    mb: 1.5,
                    pl: '4px'
                }}
            >
                정산 옵션
            </Typography>
            <Box sx={{
                display: 'flex',
                gap: '8px',
                '& .MuiButton-root': {
                    minWidth: 'auto',
                    padding: '6px 12px',
                    borderRadius: '4px',
                    fontSize: '0.813rem',
                    textTransform: 'none',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    '&:hover': {
                        boxShadow: '0 4px 6px rgba(0,0,0,0.15)'
                    }
                }
            }}>
                {buttons.map((button) => (
                    <Button
                        key={button.label}
                        variant="contained"
                        startIcon={button.icon}
                        onClick={button.onClick}
                        sx={{
                            bgcolor: button.color,
                            '&:hover': {
                                bgcolor: button.color,
                                filter: 'brightness(0.9)'
                            }
                        }}
                    >
                        {button.label}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default SettlementButton;
