import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

function FailHistoryTable({ isMobile }) {
  const dummyData = [...Array(10)].map((_, index) => ({
    no: index + 1,
    loginId: `user${index + 1}`,
    companyName: `테스트상점${index + 1}`,
    tid: `T${String(index + 1).padStart(10, '0')}`,
    mid: `M${String(index + 1).padStart(10, '0')}`,
    authType: ['인증', '비인증'][index % 2],
    paymentType: ['수기결제', '온라인결제', '정기결제'][index % 3],
    transactionDate: `2024-01-${String(index + 1).padStart(2, '0')} ${String(14 + index).padStart(2, '0')}:30:22`,
    amount: Number((index + 1) * 100000).toLocaleString(),
    transactionStatus: ['승인', '거절'][index % 2],
    cardNumber: `${index + 1}23456******1234`,
    expiryDate: `${String((index % 12) + 1).padStart(2, '0')}/25`,
    installment: ['일시불', '3개월', '6개월'][index % 3],
    paymentMethod: '신용카드',
    buyerName: ['홍길동', '김철수', '이영희'][index % 3],
    resultMessage: ['정상처리되었습니다', '카드거절되었습니다'][index % 2],
    trackId: `TR${String(index + 1).padStart(9, '0')}`
  }));

  const columns = [
    { id: 'no', label: 'No', align: 'center', color: '#1976d2', textColor: '#fff' },
    { id: 'pg', label: 'PG', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'mid', label: 'MID', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'loginId', label: 'Login ID', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'companyName', label: '상호명', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'transactionDate', label: '거래일시', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'amount', label: '금액', align: 'right', color: '#1976d2', textColor: '#fff' },
    { id: 'resultMessage', label: '결과메시지', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'buyerName', label: '구매자명', align: 'left', color: '#1976d2', textColor: '#fff' },
    { id: 'cardCompany', label: '카드사', align: 'left', color: '#03a9f4', textColor: '#fff' },
    { id: 'cardNumber', label: '카드번호', align: 'left', color: '#03a9f4', textColor: '#fff' },
    { id: 'expiryDate', label: '유효기간', align: 'right', color: '#03a9f4', textColor: '#fff' },
    { id: 'installment', label: '할부개월수', align: 'left', color: '#03a9f4', textColor: '#fff' },
    { id: 'paymentMethod', label: '결제수단', align: 'left', color: '#ffffff', textColor: '#000' },
    { id: 'authType', label: '인증방식', align: 'left', color: '#ffffff', textColor: '#000' },
    { id: 'trackId', label: 'trackId', align: 'left', color: '#ffffff', textColor: '#000' }
  ];

  return (
    <TableContainer component={Paper} sx={{ 
      maxHeight: 650,
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      '& .MuiTableCell-root': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '8px 16px',
        fontSize: { xs: '0.813rem', md: '0.875rem' }
      },
      '&::-webkit-scrollbar': {
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px'
      }
    }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{
                  backgroundColor: column.color,
                  color: column.textColor,
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  minWidth: column.id === 'resultMessage' ? 200 : 'auto',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  fontSize: { xs: '0.813rem', md: '0.875rem' }
                }}
              >
                {column.id === 'transactionDate' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {column.label}
                    <IconButton 
                      size="small" 
                      sx={{ 
                        color: 'white',
                        padding: '2px',
                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' }
                      }}
                    >
                      <UnfoldMoreIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyData.map((row, index) => (
            <TableRow 
              key={index}
              hover
              sx={{
                '&:nth-of-type(odd)': {
                  backgroundColor: '#f5f5f5',
                },
                '&:hover': {
                  backgroundColor: '#eeeeee',
                }
              }}
            >
              {columns.map((column) => (
                <TableCell 
                  key={column.id} 
                  align={column.align}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: column.id === 'resultMessage' ? 200 : 'auto',
                  }}
                >
                  {row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FailHistoryTable;
