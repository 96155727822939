import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Card,
  CardContent,
  Drawer,
  IconButton
} from '@mui/material';
import SummaryData from '../../Common/SummaryData';
import Notice from '../../Notice/Notice';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SummaryTable from '../PaymentHistory/SummaryTable';
import PayMentAggregationTable from './PayMentAggregationTable';
import Breadcrumb from '../../Common/Breadcrumb';
import DownloadButtons from '../../Common/DownloadButtons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchOptions from '../../Common/SearchOptions';
import ResultText from '../../ResultText';
import ResultTable from '../../ResultTable';

const dummyData = [
  {
    no: 1,
    pgType: 'KICC',
    agencyName: 'Neoplus',
    storeName: '(w)Wonju Rest Area 2',
    mchtId: 'TMN200436',
    tmnId: 'm9998962',
    approvalCount: 4,
    cancelCount: 0,
    totalCount: 4,
    settlementCycle: 'Instant',
    approvalAmount: 5340000,
    cancelAmount: -150000,
    totalAmount: 5190000,
    averageAmount: 1335000,
    feeRate: '5.5%',
    fee: Math.floor(5190000 * 0.055),
    refundAmount: 69254,
    unpaidAmount: 0,
    paymentRate: '1.297%',
    paymentAmount: 23496,
    deductionAmount: 151483,
    reserveAmount: 0,
    settlementAmount: 5056932,
    riskAmount: 0,
    refundAmount2: 69254,
    unpaidAmount2: 0,
    paymentMethod: '수기(KEY_IN)',
    authType: '구인증',
    businessNumber: '720312-2332913',
    residentNumber: '720312-2332913',
    contact: '010-9998-6962',
    email: '@naver.com',
    depositRate: '0%',
    depositAmount: 0,
    riskRate: '0%',
    rmCount: 0
  },
  {
    no: 2,
    pgType: 'PayUp',
    agencyName: 'Osung',
    storeName: 'Osung Trading',
    mchtId: 'EZY560583',
    tmnId: 'm4878066',
    approvalCount: 1,
    cancelCount: 1,
    totalCount: 2,
    settlementCycle: 'D+1',
    approvalAmount: 4200000,
    cancelAmount: -200000,
    totalAmount: 4000000,
    averageAmount: 4200000,
    feeRate: '3.9%',
    fee: Math.floor(4000000 * 0.039),
    refundAmount: 0,
    unpaidAmount: 0,
    paymentRate: '0%',
    paymentAmount: 18480,
    deductionAmount: 165709,
    reserveAmount: 0,
    settlementAmount: 3996720,
    riskAmount: 0,
    refundAmount2: 0,
    unpaidAmount2: 0,
    paymentMethod: '수기(KEY_IN)',
    authType: '비인증',
    businessNumber: '505-81-87535',
    residentNumber: '505-81-87535',
    contact: '010-4878-0051',
    email: 'test@naver.com',
    depositRate: '0%',
    depositAmount: 0,
    riskRate: '0%',
    rmCount: 0
  },
  {
    no: 3,
    pgType: 'KiwoomPay',
    agencyName: 'BlueFarm',
    storeName: 'BlueFarm Mart',
    mchtId: 'KWP789012',
    tmnId: 'm7890123',
    approvalCount: 7,
    cancelCount: 1,
    totalCount: 8,
    settlementCycle: 'D+2',
    approvalAmount: 3150000,
    cancelAmount: 150000,
    totalAmount: 3000000,
    averageAmount: 375000,
    feeRate: '3.9%',
    fee: 117000,
    refundAmount: 0,
    unpaidAmount: 0,
    paymentRate: '0.8%',
    paymentAmount: 24000,
    deductionAmount: 93000,
    reserveAmount: 0,
    settlementAmount: 2883000,
    riskAmount: 0,
    refundAmount2: 0,
    unpaidAmount2: 0,
    paymentMethod: '앱카드',
    authType: '인증',
    businessNumber: '123-45-67890',
    residentNumber: '123-45-67890',
    contact: '010-1234-5678',
    email: 'test3@naver.com',
    depositRate: '0%',
    depositAmount: 0,
    riskRate: '0%',
    rmCount: 0
  },
  {
    no: 4,
    pgType: 'TossPay',
    agencyName: 'GreenMarket',
    storeName: 'GreenMarket HQ',
    mchtId: 'TOS123456',
    tmnId: 'm1234567',
    approvalCount: 12,
    cancelCount: 2,
    totalCount: 14,
    settlementCycle: 'D+1',
    approvalAmount: 8900000,
    cancelAmount: 200000,
    totalAmount: 8700000,
    averageAmount: 621428,
    feeRate: '3.5%',
    fee: 304500,
    refundAmount: 0,
    unpaidAmount: 0,
    paymentRate: '1.2%',
    paymentAmount: 104400,
    deductionAmount: 200100,
    reserveAmount: 0,
    settlementAmount: 8395500,
    riskAmount: 0,
    refundAmount2: 0,
    unpaidAmount2: 0,
    paymentMethod: '간편결제',
    authType: '인증',
    businessNumber: '234-56-78901',
    residentNumber: '234-56-78901',
    contact: '010-2345-6789',
    email: 'test4@naver.com',
    depositRate: '0%',
    depositAmount: 0,
    riskRate: '0%',
    rmCount: 0
  },
  {
    no: 5,
    pgType: 'NicePay',
    agencyName: 'DigitalPlus',
    storeName: 'DigitalMart',
    mchtId: 'PAY234567',
    tmnId: 'm2345678',
    approvalCount: 15,
    cancelCount: 3,
    totalCount: 18,
    settlementCycle: 'D+1',
    approvalAmount: 6700000,
    cancelAmount: 300000,
    totalAmount: 6400000,
    averageAmount: 355555,
    feeRate: '3.8%',
    fee: 243200,
    refundAmount: 0,
    unpaidAmount: 0,
    paymentRate: '1.1%',
    paymentAmount: 70400,
    deductionAmount: 172800,
    reserveAmount: 0,
    settlementAmount: 6156800,
    riskAmount: 0,
    refundAmount2: 0,
    unpaidAmount2: 0,
    paymentMethod: '간편결제',
    authType: '인증',
    businessNumber: '345-67-89012',
    residentNumber: '345-67-89012',
    contact: '010-3456-7890',
    email: 'test5@naver.com',
    depositRate: '0.5%',
    depositAmount: 32000,
    riskRate: '0%',
    rmCount: 0
  }
];

// 숫자 포맷팅을 위한 헬퍼 함수
const formatNumber = (value) => {
  return value ? value.toLocaleString() : '0';
};

function PaymentAggregation() {
  const [tableData, setTableData] = useState(dummyData);
  
  // 날짜 상태
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  
  // 검색 옵션 상태
  const [dateSearchType, setDateSearchType] = useState('');
  const [pgType, setPgType] = useState('');
  const [excludePg, setExcludePg] = useState(false);
  const [storeMid, setStoreMid] = useState('');
  const [walletType, setWalletType] = useState('');
  const [settlementCycle, setSettlementCycle] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [masterSearch, setMasterSearch] = useState('');
  const [branchSearch, setBranchSearch] = useState('');
  const [totalSearch, setTotalSearch] = useState('');
  const [agencySearch, setAgencySearch] = useState('');
  const [retailSearch, setRetailSearch] = useState('');
  const [sales1Search, setSales1Search] = useState('');
  const [sales2Search, setSales2Search] = useState('');
  const [sales3Search, setSales3Search] = useState('');
  const [depositCheck, setDepositCheck] = useState('');
  const [merchantSearch, setMerchantSearch] = useState('');
  const [regularPayment, setRegularPayment] = useState('');
  const [duplicateCheck, setDuplicateCheck] = useState('');
  const [customerInfo, setCustomerInfo] = useState('');
  const [mid, setMid] = useState('');
  const [tid, setTid] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [productName, setProductName] = useState('');
  const [approvalNo, setApprovalNo] = useState('');
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [authType, setAuthType] = useState('');
  const [taxType, setTaxType] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [installmentType, setInstallmentType] = useState('');
  const [cancelStatus, setCancelStatus] = useState('');
  const [highAmountSort, setHighAmountSort] = useState('');
  const [excelDownload, setExcelDownload] = useState('');
  const [individualSettlement, setIndividualSettlement] = useState('');
  const [settlementTotal, setSettlementTotal] = useState('');
  const [individualSales, setIndividualSales] = useState('');
  const [salesTotal, setSalesTotal] = useState('');
  const [reserveTotal, setReserveTotal] = useState('');
  const [cancelType, setCancelType] = useState('');
  const [depositType, setDepositType] = useState('');
  const [searchType, setSearchType] = useState('');

  // 새로운 상태 추가
  const [selectedTab, setSelectedTab] = useState('payment');

  // 테이블 데이터 state 추가
  const [data, setData] = useState([
    {
      pgType: 'KICC',
      agencyName: 'Neoplus',
      walletAccess: '5',
      storeName: '(주)테스트',
      mchtId: 'TMN200436',
      tmnId: 'm9998962',
      approvalCount: 4,
      cancelCount: 0,
      totalCount: 4,
      settlementCycle: 'Instant',
      approvalAmount: 5340000,
      cancelAmount: 0,
      totalAmount: 5340000,
      averageAmount: 1335000,
      feeRate: 5.301,
      fee: 283068,
      // ... 필요한 데이터 필드들 추가
    },
    // ... 더미 데이터 추가
  ]);

  useEffect(() => {
    // API 호출하여 실제 데이터 가져오기
    const fetchData = async () => {
      try {
        // const response = await axios.get('/api/payment-data');
        // setData(response.data);
      } catch (error) {
        console.error('데이터 로딩 실패:', error);
      }
    };

    fetchData();
  }, [/* 의존성 배열 */]);

  // 날짜 검색 핸들러
  const handleDateSearch = (value) => {
    const today = new Date();
    let start = new Date();
    
    switch(value) {
      case '1': // 오늘
        setStartDate(today.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '2': // 어제
        start.setDate(today.getDate() - 1);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(start.toISOString().split('T')[0]);
        break;
      case '3': // 지난7일
        start.setDate(today.getDate() - 7);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '5': // 이번달
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '13': // 지난3개월
        start = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      default:
        break;
    }
    setDateSearchType(value);
  };

  // 검색 효과 추가
  useEffect(() => {
    // 검색 조건이 변경될 때마다 검색 실행
    const handleSearch = () => {
      // 실제로는 API 호출이나 데이터 필링이 들어갈 자리
      console.log('Search with:', {
        dateSearchType,
        pgType,
        storeMid,
        walletType,
        settlementCycle,
        // ... 기타 검색 조건들
      });
    };

    handleSearch();
  }, [
    dateSearchType,
    pgType,
    storeMid,
    walletType,
    settlementCycle,
    // ... 검색에 사용될 state들
  ]);

  // 상단에 새로운 state 추가
  const [rowCount, setRowCount] = useState(''); // 데이터개수를 위한 별도 state

  const [searchPanelOpen, setSearchPanelOpen] = useState(true);

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  // 상태 변경 시 콘솔에 출력
  useEffect(() => {
    console.log('Search Panel State:', searchPanelOpen);
  }, [searchPanelOpen]);

  // 화면 크기 감지를 위한 상태 추가 (PaymentHistory.js와 동일하게)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  // 화면 크기 변경 감지 (PaymentHistory.js와 동일한 로직)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 동적 스타일 계산 (PaymentHistory.js와 동일한 구조)
  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: '5px',
    mx: '50px',
    height: 'auto',
    position: 'relative',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '0px'
    },
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  };

  return (
    <Box sx={mainBoxStyle}>
      {/* 패널 열기 버튼 - PaymentHistory.js와 동일한 구조 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer - PaymentHistory.js와 동일한 구조 */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={{
          '& .MuiDrawer-paper': {
            width: '320px',
            boxSizing: 'border-box',
            mt: '64px',
            height: 'calc(100% - 64px)',
            border: 'none',
            backgroundColor: 'transparent'
          }
        }}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>

      <Notice />

      <Box mb={3}/>
      <ResultText />
      <ResultTable />

      {tableData && tableData.length > 0 && (
        <PayMentAggregationTable 
          data={tableData}
          searchPanelOpen={searchPanelOpen}
        />
      )}

      {/* SummaryTable - summaryData 구조는 유지 */}
      <SummaryTable 
        summaryData={{
          basicStats: {
            dealerCount: 65,
            transactionCount: 391,
            approvalCount: 384,
            cancelCount: 7,
            netCount: 377,
            periodCancelCount: 0,
            returnCount: 0,
            netCancelCount: 0,
            
            approvalAmount: 144166926,
            cancelAmount: -673708,
            totalAmount: 143493218,
            periodCancelAmount: 0,
            outstandingAmount: 0,
            returnAmount: 0,
            netCancelAmount: 0,
            rmCount: 0,
            
            totalFee: 7238518,
            totalDeposit: 0,
            totalSettlement: 136254700,
            totalWalletWithdrawal: 0,
            totalAccountSettlement: 1068404,
            totalMasterPayment: 4907946,
            totalSeniorPayment: -2594459,
            totalDeduction: 3094862
          },
          accountPayments: {
            total: 1068404,
            master: -2698599,
            company: 33459,
            distributor: 336820,
            agency: 487943,
            retail: 207870,
            sales1: 1514,
            sales2: 20,
            sales3: 778
          },
          accountPoints: {
            total: 689997,
            master: 616575,
            company: 201,
            distributor: 73181,
            agency: 0,
            retail: 20,
            sales1: 0,
            sales2: 20,
            sales3: 0
          },
          accountSavings: {
            total: 0,
            master: 0,
            company: 0,
            distributor: 0,
            agency: 0,
            retail: 0,
            sales1: 0,
            sales2: 0,
            sales3: 0
          },
          settlementDetails: {
            totalFee: 7238518,
            supplyPrice: 6580565,
            vat: 657953,
            totalDeposit: 0,
            totalDeduction: 7238518,
            difference: 5480117,
            totalAccountSettlement: 138013101,
            totalMasterPayment: 4907946,
            totalSeniorPayment: -2594459,
            merchantSettlement: 136254700,
            walletWithdrawal: 0,
            totalSettlement: 136254700,
            retention: 2385255,
            totalOriginalDeduction: 3094862,
            retentionPayment: -2176989,
            totalDeposited: 140398356,
            merchantPoints: 0,
            pointRate: 0,
            totalAdminPayment: 417470
          }
        }}
        searchPanelOpen={searchPanelOpen}
      />

      <SummaryData />
    </Box>
  );
}

export default PaymentAggregation;