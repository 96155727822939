import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  TableSortLabel,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import VisibilityIcon from '@mui/icons-material/Visibility';

const EditHistory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('time');
  const [order, setOrder] = useState('desc');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // 임시 데이터
  const [records] = useState([
    {
      time: '2024-12-06 13:29:23',
      id: 'b19003',
      page: 'com_ok.php(edit)',
      category: 'DDD IPGGGG GGG hosid=\'EEEEEE\', ASDASD\'104703610\'...',
      content: 'UPDATE addbay.company SET hosid=\'780-86-01579\', loginid=\'104703610\'...',
      ip: '180.71.245.75'
    }
    // ... 더 많은 데이터
  ]);

  // SQL 키워드 리스트
  const sqlKeywords = ['SELECT', 'UPDATE', 'INSERT', 'DELETE', 'FROM', 'WHERE', 'SET'];

  // 텍스트를 1행으로 줄이는 함수
  const truncateText = (text) => {
    return text.length > 50 ? `${text.substring(0, 50)}...` : text;
  };

  // 쿼리문에서 SQL 키워드를 강조하는 함수
  const highlightSQLKeywords = (text) => {
    let result = text;
    sqlKeywords.forEach(keyword => {
      const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
      result = result.replace(regex, `<span style="color: #1976d2">${keyword}</span>`);
    });
    return <div dangerouslySetInnerHTML={{ __html: result }} />;
  };

  // 정렬 처리 함수
  const handleSort = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  // 정렬된 데이터 생성
  const sortedRecords = [...records].sort((a, b) => {
    if (order === 'asc') {
      return new Date(a.time) - new Date(b.time);
    }
    return new Date(b.time) - new Date(a.time);
  });

  // 상세보기 처리 함수 수정
  const handleViewDetail = (record) => {
    const width = 800;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      '',
      'EditHistoryDetail',
      `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`
    );

    // 팝업 내용 작성
    popup.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>수정 이력 상세보기</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; margin: 0; }
            .container { max-width: 100%; }
            .section { margin-bottom: 20px; }
            .label { font-weight: bold; margin-bottom: 8px; }
            .content { 
              background-color: #f5f5f5; 
              padding: 15px; 
              border-radius: 4px;
              white-space: pre-wrap;
              word-break: break-all;
            }
            .highlight { color: #1976d2; }
          </style>
        </head>
        <body>
          <div class="container">
            <div class="section">
              <div class="label">분류</div>
              <div class="content">${record.category}</div>
            </div>
            <div class="section">
              <div class="label">내용</div>
              <div class="content">${highlightSQLKeywords(record.content).props.dangerouslySetInnerHTML.__html}</div>
            </div>
          </div>
        </body>
      </html>
    `);
    popup.document.close();
  };

  return (
    <Card sx={{ p: 3 }}>
      {/* 헤더 영역 */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <HistoryIcon sx={{ color: '#1976d2' }} />
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            수정 이력
          </Typography>
        </Box>
      </Box>

      {/* 검색 필드 */}
      <TextField
        fullWidth
        placeholder="모든 필드를 검색합니다."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 3 }}
        size="small"
      />

      {/* 테이블 */}
      <TableContainer 
        component={Paper} 
        sx={{ 
          maxHeight: 'calc(100vh - 280px)',
          '&::-webkit-scrollbar': { width: '8px' },
          '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
          '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px' }
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell 
                sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}
                onClick={handleSort}
              >
                <TableSortLabel
                  active={true}
                  direction={order}
                >
                  시간
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>아이디</TableCell>
              <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>페이지</TableCell>
              <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>분류</TableCell>
              <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>내용</TableCell>
              <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>아이피</TableCell>
              <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 600 }}>상세보기</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRecords.map((record, index) => (
              <TableRow key={index} hover>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{record.time}</TableCell>
                <TableCell>{record.id}</TableCell>
                <TableCell>{record.page}</TableCell>
                <TableCell sx={{ maxWidth: 200 }}>
                  {highlightSQLKeywords(truncateText(record.category))}
                </TableCell>
                <TableCell sx={{ maxWidth: 300 }}>
                  {highlightSQLKeywords(truncateText(record.content))}
                </TableCell>
                <TableCell>{record.ip}</TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<VisibilityIcon />}
                    onClick={() => handleViewDetail(record)}
                    sx={{ 
                      color: '#1976d2',
                      '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.04)'
                      }
                    }}
                  >
                    상세보기
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default EditHistory;
