import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 6px 10px -4px rgba(0,0,0,.15)',
    marginBottom: '24px',
  },
  cardHeader: {
    padding: '15px 15px 0',
    border: '0',
    backgroundColor: 'transparent',
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: 400,
    color: '#252422',
    marginBottom: '8px',
  },
  tableContainer: {
    padding: '15px',
  },
  table: {
    minWidth: 650,
    '& thead th': {
      color: '#66615b',
      fontSize: '0.95rem',
      fontWeight: 500,
      borderBottom: '1px solid #ddd',
      padding: '12px 8px',
      textAlign: 'left',
      '&:first-child': {
        paddingLeft: '15px',
      },
    },
    '& tbody td': {
      padding: '12px 8px',
      fontSize: '0.9rem',
      color: '#66615b',
      borderBottom: '1px solid #ddd',
      '&:first-child': {
        paddingLeft: '15px',
      },
    },
    '& tbody tr:last-child td': {
      borderBottom: 'none',
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(200,200,200,.07)',
    },
  },
  rankCell: {
    width: '60px',
    color: '#51cbce',
    fontWeight: 500,
  },
  amountCell: {
    color: '#51cbce',
    fontWeight: 500,
  },
  cancelAmountCell: {
    color: '#ef8157',
    fontWeight: 500,
  },
}));