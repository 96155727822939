import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  Typography, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField,
  ButtonGroup
} from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const PGProviderOptions = () => {
  const [selectedTab, setSelectedTab] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([
    { 행동: '변경', 이름: 'country2', KEY: 'QkZJRlBDRTl1eTQc1QUtBMw==', INV: 'PRJ59QZGHPT844TQ' },
    { 행동: '변경', 이름: 'country', KEY: 'UUJQRUJCR0pBWkwyMkZRSA==', INV: 'VQX41KJXZKPF6TYD' },
    { 행동: '변경', 이름: 'happy1', KEY: 'VzJVQTlEQZJDTktEYFlSTw==', INV: 'JWS1N4VD4ARJ0V6R' },
    { 행동: '변경', 이름: 'm2248053', KEY: 'SEJRU082U09BRzNVVzg0SA==', INV: 'JTZREIXEZWDLPTMH' },
    { 행동: '변경', 이름: '1', KEY: 'QlJORJJOTUNIRVSSFZHOw==', INV: 'NSEQP0TFPFRFR9AL' },
    { 행동: '변경', 이름: 'default', KEY: 'QlJORJJOTUNIRVSSFZHOw==', INV: 'NSEQP0TFPFRFR9AL' },
    { 행동: '변경', 이름: 'test', KEY: '1', INV: '2' },
    { 행동: '변경', 이름: 'sdsa', KEY: '1231233', INV: '132132' }
  ]);

  return (
    <Card sx={{ mt: 4, borderRadius: '12px', boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)', height: 'calc(100vh - 280px)' }}>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <ReceiptLongIcon sx={{ color: '#ed6c02' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            PG사 설정
          </Typography>
        </Box>

        <ButtonGroup variant="outlined" size="small" sx={{ mb: 2 }}>
          <Button 
            onClick={() => setSelectedTab('갤럭시아')}
            variant={selectedTab === '갤럭시아' ? 'contained' : 'outlined'}
          >
            갤럭시아 세팅
          </Button>
          <Button 
            onClick={() => setSelectedTab('헥토')}
            variant={selectedTab === '헥토' ? 'contained' : 'outlined'}
          >
            헥토 코리아 세팅
          </Button>
          <Button 
            onClick={() => setSelectedTab('다페이')}
            variant={selectedTab === '다페이' ? 'contained' : 'outlined'}
          >
            다페이 세팅
          </Button>
          <Button 
            onClick={() => setSelectedTab('네스트페이')}
            variant={selectedTab === '네스트페이' ? 'contained' : 'outlined'}
          >
            네스트페이 세팅
          </Button>
        </ButtonGroup>

        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: '300px' }}
          />
          <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: '#00bcd4',
              '&:hover': {
                backgroundColor: '#00acc1'
              }
            }}
          >
            조회
          </Button>
          <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: '#4CAF50',
              '&:hover': {
                backgroundColor: '#45a049'
              }
            }}
          >
            추가
          </Button>
        </Box>

        <TableContainer sx={{ 
          height: 'calc(100vh - 450px)',
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
            '&:hover': {
              background: '#666'
            }
          }
        }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>행동</TableCell>
                <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>이름</TableCell>
                <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>KEY</TableCell>
                <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>INV</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow 
                  key={index}
                  sx={{ 
                    '&:hover': { backgroundColor: '#f5f5f5' },
                    transition: 'background-color 0.2s'
                  }}
                >
                  <TableCell>
                    <Button 
                      variant="contained" 
                      size="small" 
                      sx={{ 
                        backgroundColor: '#00bcd4',
                        '&:hover': {
                          backgroundColor: '#00acc1'
                        }
                      }}
                    >
                      {row.행동}
                    </Button>
                  </TableCell>
                  <TableCell>{row.이름}</TableCell>
                  <TableCell>{row.KEY}</TableCell>
                  <TableCell>{row.INV}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default PGProviderOptions;
