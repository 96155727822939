import React, { useState, useRef } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel
} from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const PaymentHistoryDBCheck = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');

  // 더미 데이터
  const rows = [
    {
      no: 1,
      delete_flag: '일반',
      date: '2024-03-15 10:12:11',
      order_no: 'ORD20240315001',
      approval_no: 'APV123456789',
      amount: '50,000',
      match: '일치'
    },
    {
      no: 2,
      delete_flag: '일반',
      date: '2024-03-14 15:22:43',
      order_no: 'ORD20240314002',
      approval_no: 'APV987654321',
      amount: '75,000',
      match: '불일치'
    }
  ];

  // 파일 선택 핸들러
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  // 파일 선택 버튼 클릭 핸들러
  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  // 파일 취소 핸들러
  const handleCancelFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // 정렬 핸들러
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = [...rows].sort((a, b) => {
    const isAsc = order === 'asc';
    if (orderBy === 'no') {
      return isAsc ? a.no - b.no : b.no - a.no;
    }
    return isAsc
      ? String(a[orderBy]).localeCompare(String(b[orderBy]))
      : String(b[orderBy]).localeCompare(String(a[orderBy]));
  });

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <PaymentsIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          결제내역 DB 검사
        </Typography>
      </Box>

      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Box sx={{ p: 2, borderBottom: '1px solid #e0e0e0' }}>
          {/* 파일 업로드 영역 */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mb: 3,
              border: '1px solid #e0e0e0',
              borderRadius: '8px'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2,
              mb: 2
            }}>
              {/* 파일 선택 영역 */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  style={{ display: 'none' }}
                  accept=".xlsx,.xls"
                />
                {selectedFile && (
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    backgroundColor: '#f5f5f5',
                    padding: '4px 8px',
                    borderRadius: '4px'
                  }}>
                    <Typography variant="body2" color="textSecondary">
                      {selectedFile.name}
                    </Typography>
                    <IconButton
                      onClick={handleCancelFile}
                      size="small"
                      sx={{
                        width: '20px',
                        height: '20px',
                        color: '#d32f2f',
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} size="xs" />
                    </IconButton>
                  </Box>
                )}
                <Tooltip title="파일 선택">
                  <IconButton
                    onClick={handleFileButtonClick}
                    sx={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: '1px solid #1976d2',
                      color: '#1976d2',
                      '&:hover': {
                        backgroundColor: '#1976d210',
                      }
                    }}
                  >
                    <UploadFileIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="엑셀 양식 다운로드">
                  <IconButton
                    sx={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: '1px solid #2e7d32',
                      color: '#2e7d32',
                      '&:hover': {
                        backgroundColor: '#2e7d3210',
                      }
                    }}
                  >
                    <SaveAltIcon />
                  </IconButton>
                </Tooltip>
              </Box>

            
            </Box>

            {/* 검색 옵션 영역 */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2,
              mt: 2
            }}>
              {/* 데이터 개수 선택 */}
              <RadioGroup
                row
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(Number(e.target.value))}
                sx={{ gap: 2 }}
              >
                {[10, 25, 50, 'All'].map((value) => (
                  <FormControlLabel
                    key={value}
                    value={value === 'All' ? -1 : value}
                    control={<Radio size="small" />}
                    label={value}
                  />
                ))}
              </RadioGroup>

              {/* 검색어 입력 */}
              <TextField
                size="small"
                placeholder="검색어를 입력하세요"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                sx={{ width: '300px' }}
              />

              {/* 엑셀 다운로드 */}
              <Tooltip title="엑셀 다운로드">
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#2e7d3210',
                    }
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === 'no'}
                    direction={orderBy === 'no' ? order : 'asc'}
                    onClick={() => handleRequestSort('no')}
                  >
                    No
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">삭제여부</TableCell>
                <TableCell align="center">등록일</TableCell>
                <TableCell align="center">주문번호</TableCell>
                <TableCell align="center">승인번호</TableCell>
                <TableCell align="center">결제금액</TableCell>
                <TableCell align="center">일치여부</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.map((row) => (
                <TableRow key={row.no}>
                  <TableCell align="center">{row.no}</TableCell>
                  <TableCell align="center">{row.delete_flag}</TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.order_no}</TableCell>
                  <TableCell align="center">{row.approval_no}</TableCell>
                  <TableCell align="center">{row.amount}</TableCell>
                  <TableCell 
                    align="center"
                    sx={{ 
                      color: row.match === '일치' ? '#2e7d32' : '#e91e63',
                      fontWeight: 600
                    }}
                  >
                    {row.match}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default PaymentHistoryDBCheck;
