import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField
} from '@mui/material';
import DnsIcon from '@mui/icons-material/Dns';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';

const ServerResources = () => {
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [searchText, setSearchText] = useState('');

  // 더미 데이터
  const rows = [
    {
      no: 7045,
      server_ip: '49.247.25.17',
      total_memory: '7.76',
      used_memory: '1.00',
      available_memory: '6.76',
      cpu_usage: '0.11',
      total_disk: '24.05',
      used_disk: '5.87',
      network_rx: '6256482537',
      network_tx: '2517687237',
      check_time: '2024-12-06 14:36:11'
    },
    {
      no: 7044,
      server_ip: '49.247.25.17',
      total_memory: '7.76',
      used_memory: '0.94',
      available_memory: '6.82',
      cpu_usage: '0.11',
      total_disk: '24.05',
      used_disk: '5.87',
      network_rx: '6254399113',
      network_tx: '2516712076',
      check_time: '2024-12-06 13:36:11'
    },
    // ... 더 많은 더미 데이터 추가 가능
  ];

  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2'
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32'
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2'
    }
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = React.useMemo(() => {
    const comparator = (a, b) => {
      if (order === 'asc') {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    };
    return [...rows].sort(comparator);
  }, [rows, order, orderBy]);

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3}>
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DnsIcon sx={{ color: '#00acc1' }} />
            <Typography variant="h6">서버 리소스</Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            mb: 2,
            alignItems: 'center'
          }}>
            <TextField
              size="small"
              placeholder="검색어를 입력하세요"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ 
                minWidth: '200px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '40px'
                }
              }}
            />
            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.label}>
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'no'}
                      direction={orderBy === 'no' ? order : 'asc'}
                      onClick={() => handleRequestSort('no')}
                    >
                      No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">서버 IP</TableCell>
                  <TableCell align="center">총 메모리</TableCell>
                  <TableCell align="center">사용 중 메모리</TableCell>
                  <TableCell align="center">사용 가능한 메모리</TableCell>
                  <TableCell align="center">CPU 사용률</TableCell>
                  <TableCell align="center">총 디스크 크기</TableCell>
                  <TableCell align="center">사용중 디스크</TableCell>
                  <TableCell align="center">네트워크 전송 바이트</TableCell>
                  <TableCell align="center">네트워크 수신 바이트</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'check_time'}
                      direction={orderBy === 'check_time' ? order : 'asc'}
                      onClick={() => handleRequestSort('check_time')}
                    >
                      수집 시간
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row) => (
                  <TableRow key={row.no}>
                    <TableCell align="center">{row.no}</TableCell>
                    <TableCell align="center">{row.server_ip}</TableCell>
                    <TableCell align="center">{row.total_memory}</TableCell>
                    <TableCell align="center">{row.used_memory}</TableCell>
                    <TableCell align="center">{row.available_memory}</TableCell>
                    <TableCell align="center">{row.cpu_usage}</TableCell>
                    <TableCell align="center">{row.total_disk}</TableCell>
                    <TableCell align="center">{row.used_disk}</TableCell>
                    <TableCell align="center">{row.network_rx}</TableCell>
                    <TableCell align="center">{row.network_tx}</TableCell>
                    <TableCell align="center">{row.check_time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default ServerResources;
