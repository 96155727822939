import React from 'react';
import { 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  TableContainer, 
  Paper 
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  fontFamily: 'inherit',
  textAlign: 'center',
  backgroundColor: '#fff',
  fontWeight: 600,
  color: '#495057',
  whiteSpace: 'nowrap',
  borderBottom: '1px solid #e3f2fd',
  '&:not(:last-child)': {
    borderRight: '1px solid #e3f2fd',
  },

  '&.header': {
    backgroundColor: '#e3f2fd',
    color: '#0d47a1',
    borderBottom: '2px solid #bbdefb',
  }
}));

const StyledTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: '#f8f9fa',
    transition: 'background-color 0.2s ease',
  },
});

const ResultTable = () => {
  // 중요 데이터 예시
  const data = {
    totalAmount: '1,234,567,890원',
    approvalCount: '1,234건',
    cancelAmount: '123,456,789원',
    cancelCount: '123건',
    netAmount: '1,111,111,101원',
    successRate: '99.9%',
    avgAmount: '1,234,567원',
    totalCount: '1,357건'
  };

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        mb: 3,
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.05)',
        borderRadius: '8px',
        border: '1px solid #e3f2fd',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
        '& .MuiTableCell-root': {
          width: `${100 / 8}%`,
          minWidth: '100px',
        }
      }}
    >
      <Table sx={{ minWidth: '800px', tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell className="header">총 승인금액</StyledTableCell>
            <StyledTableCell className="header">승인건수</StyledTableCell>
            <StyledTableCell className="header">취소금액</StyledTableCell>
            <StyledTableCell className="header">취소건수</StyledTableCell>
            <StyledTableCell className="header">순승인금액</StyledTableCell>
            <StyledTableCell className="header">성공률</StyledTableCell>
            <StyledTableCell className="header">평균금액</StyledTableCell>
            <StyledTableCell className="header">전체건수</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>{data.totalAmount}</StyledTableCell>
            <StyledTableCell>{data.approvalCount}</StyledTableCell>
            <StyledTableCell>{data.cancelAmount}</StyledTableCell>
            <StyledTableCell>{data.cancelCount}</StyledTableCell>
            <StyledTableCell>{data.netAmount}</StyledTableCell>
            <StyledTableCell>{data.successRate}</StyledTableCell>
            <StyledTableCell>{data.avgAmount}</StyledTableCell>
            <StyledTableCell>{data.totalCount}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultTable;
