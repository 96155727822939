import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';

// 날짜 포맷팅 함수 추가
const formatDateTime = (index) => {
  const hours = String(Math.floor(Math.random() * 24)).padStart(2, '0');
  const minutes = String(Math.floor(Math.random() * 60)).padStart(2, '0');
  const seconds = String(Math.floor(Math.random() * 60)).padStart(2, '0');
  return `2024-01-${String(index + 1).padStart(2, '0')} ${hours}:${minutes}:${seconds}`;
};

function NonMerchantTable() {
  return (
    <Paper sx={{ 
      width: 'calc(100% - 20px)',
      margin: '0 auto',
      overflow: 'hidden'
    }}>
      <TableContainer sx={{ 
        maxHeight: '65vh',
        '& .MuiTableCell-root': {
          padding: '8px 4px',
          fontSize: '0.8rem',
          whiteSpace: 'nowrap'
        },
        '& .MuiTableCell-head': {
          backgroundColor: '#f5f5f5',
          fontWeight: 'bold',
          textAlign: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 1
        },
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px'
        }
      }}>
        <Table stickyHeader sx={{ minWidth: 1600 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ color: '#1565c0', backgroundColor: '#1565c0', '& *': { color: '#1565c0' } }}>번호</TableCell>
              <TableCell align="center" sx={{ color: '#1565c0', backgroundColor: '#1565c0', '& *': { color: '#1565c0' } }}>등록일</TableCell>
              <TableCell align="center" sx={{ color: '#1565c0', backgroundColor: '#1565c0', '& *': { color: '#1565c0' } }}>수정일</TableCell>
              <TableCell align="center" sx={{ color: '#1565c0', backgroundColor: '#1565c0', '& *': { color: '#1565c0' } }}>경과일</TableCell>
              <TableCell align="center" sx={{ color: '#1565c0', backgroundColor: '#1565c0', '& *': { color: '#1565c0' } }}>가맹점명</TableCell>
              <TableCell align="center" sx={{ color: '#1565c0', backgroundColor: '#1565c0', '& *': { color: '#1565c0' } }}>아이디</TableCell>
              <TableCell align="center" sx={{ color: '#00b0ff', backgroundColor: '#00b0ff', '& *': { color: '#00b0ff' } }}>결제수단</TableCell>
              <TableCell align="center" sx={{ color: '#00b0ff', backgroundColor: '#00b0ff', '& *': { color: '#00b0ff' } }}>인증방식</TableCell>
              <TableCell align="center" sx={{ color: '#00b0ff', backgroundColor: '#00b0ff', '& *': { color: '#00b0ff' } }}>영업</TableCell>
              <TableCell align="center" sx={{ color: '#333333' }}>정산상태</TableCell>
              <TableCell align="center" sx={{ color: '#333333' }}>월렛상태</TableCell>
              <TableCell align="center" sx={{ color: '#2e7d32', backgroundColor: '#2e7d32', '& *': { color: '#2e7d32' } }}>PG</TableCell>
              <TableCell align="center" sx={{ color: '#2e7d32', backgroundColor: '#2e7d32', '& *': { color: '#2e7d32' } }}>MID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(10)].map((_, index) => (
              <TableRow 
                key={index}
                sx={{ 
                  backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                  }
                }}
              >
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#1565c020' }}>{index + 1}</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#1565c020' }}>{formatDateTime(index)}</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#1565c020' }}>{formatDateTime(index)}</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#1565c020' }}>{index + 1}일</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#1565c020' }}>가맹점_{index + 1}</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#1565c020' }}>user_{index + 1}</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#00b0ff20' }}>신용카드</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#00b0ff20' }}>일반</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#00b0ff20' }}>{`영업${index + 1}`}</TableCell>
                <TableCell align="center" sx={{ color: '#333333' }}>{['가능', '중지'][index % 2]}</TableCell>
                <TableCell align="center" sx={{ color: '#333333' }}>{['사용', '미사용'][index % 2]}</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#2e7d3220' }}>{['신한', '국민', '우리'][index % 3]}</TableCell>
                <TableCell align="center" sx={{ color: '#333', backgroundColor: '#2e7d3220' }}>{`MID${String(index).padStart(5, '0')}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default NonMerchantTable;