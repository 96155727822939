import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SummaryData from '../../Common/SummaryData';
import SearchOptions from '../../Common/SearchOptions';
import Notice from '../../Notice/Notice';
import ComplaintDetailsTables from './ComplaintDetailsTables';

function ComplaintDetails() {
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  const mainBoxStyle = {
    flexGrow: 1,
    p: 3,
    mt: 2,
    transition: 'padding 0.2s',
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '10px'
    },
    ml: {
      xs: '10px',
      md: '50px'
    },
    '@media (max-width: 960px)': {
      ml: '10px',
      pr: '10px'
    }
  };

  const styles = {
    drawer: {
      '& .MuiDrawer-paper': {
        width: '320px',
        boxSizing: 'border-box',
        mt: '64px',
        height: 'calc(100% - 64px)',
        border: 'none',
        backgroundColor: 'transparent',
        '@media (max-width: 768px)': {
          width: '100%',
          mt: '56px',
          height: 'calc(100% - 56px)'
        }
      }
    }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            zIndex: 1199
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      <Notice />
      
      <Box sx={{ mb: '20px' }} />
      
      <ComplaintDetailsTables 
        searchPanelOpen={searchPanelOpen}
        setSearchPanelOpen={setSearchPanelOpen}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      <Box sx={{ mb: '20px' }} />

      <SummaryData />

      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch}
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>
    </Box>
  );
}

export default ComplaintDetails;