import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  IconButton, 
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  styled
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SearchIcon from '@mui/icons-material/Search';

// StyledBox 추가
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '16px',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  marginBottom: '16px'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px 16px',
  whiteSpace: 'nowrap',
  ['&.MuiTableCell-head']: {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
  }
}));

const TransferHistory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  // 정렬 처리
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 테이블 헤더 정의
  const headers = [
    { id: 'merchantName', label: '상호명' },
    { id: 'merchantId', label: '로그인아이디' },
    { id: 'tid', label: 'TID' },
    { id: 'amount', label: '출금액', align: 'right' },
    { id: 'bank', label: '은행' },
    { id: 'accountNumber', label: '계좌번호' },
    { id: 'accountHolder', label: '예금주' },
    { id: 'transferDate', label: '출금일자', align: 'center' }
  ];

  // 샘플 데이터
  const rows = [
    {
      merchantName: '농부고급3',
      merchantId: 'm87965090',
      tid: '203818606',
      amount: '1,056,000',
      bank: 'KB국민은행',
      accountNumber: '54650104154329',
      accountHolder: '한영수',
      transferDate: '2024-12-11 14:52:02'
    }
  ];

  return (
    <Box sx={{ p: 2 }}>
      {/* 제목 영역 */}
      <StyledBox>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontSize: '1.1rem',
            fontWeight: 600,
            color: '#1976d2'
          }}
        >
          <AccountBalanceIcon sx={{ fontSize: '1.4rem' }} />
          이체내역조회
        </Typography>
      </StyledBox>

      {/* 검색 영역 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2, 
        mb: 2,
        flexWrap: 'wrap'
      }}>
        <TextField
          size="small"
          placeholder="검색어를 입력하세요"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1, maxWidth: '300px' }}
        />

        <Tooltip title="검색">
          <IconButton
            sx={{
              backgroundColor: '#1976d2',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#1565c0',
              }
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="엑셀 다운로드">
          <IconButton
            sx={{
              backgroundColor: '#fff',
              border: '1px solid #2e7d32',
              color: '#2e7d32',
              '&:hover': {
                backgroundColor: '#2e7d3210',
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* 테이블 */}
      <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)' }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell
                  key={header.id}
                  align={header.align || 'left'}
                  sortDirection={orderBy === header.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={() => handleSort(header.id)}
                  >
                    {header.label}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} hover>
                <StyledTableCell>{row.merchantName}</StyledTableCell>
                <StyledTableCell>{row.merchantId}</StyledTableCell>
                <StyledTableCell>{row.tid}</StyledTableCell>
                <StyledTableCell align="right">{row.amount}</StyledTableCell>
                <StyledTableCell>{row.bank}</StyledTableCell>
                <StyledTableCell>{row.accountNumber}</StyledTableCell>
                <StyledTableCell>{row.accountHolder}</StyledTableCell>
                <StyledTableCell align="center">{row.transferDate}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TransferHistory;