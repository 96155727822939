import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: '24px',
  },
  card: {
    background: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 6px 10px rgba(0,0,0,.08)',
    marginBottom: '24px',
  },
  cardHeader: {
    padding: '20px',
    borderBottom: '1px solid rgba(0,0,0,.1)',
  },
  chartTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#2c2c2c',
    margin: 0,
  },
  cardBody: {
    padding: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    '& .MuiTableCell-root': {
      padding: '12px',
      textAlign: 'center',
      borderBottom: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      '&:last-child': {
        borderRight: 'none'
      }
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        fontWeight: 700,
        fontSize: '1.1rem',
        color: 'rgba(0, 0, 0, 0.7)',
        backgroundColor: '#f8f9fa',
        borderBottom: '2px solid #666'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root:first-child': {
        '& .MuiTableCell-root': {
          borderTop: '2px solid #666'
        }
      }
    },
    '& .MuiTableRow-root': {
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        cursor: 'default'
      },
      '&:last-child .MuiTableCell-root': {
        borderBottom: 'none'
      }
    }
  },
  sortButton: {
    background: 'none',
    border: 'none',
    padding: '0 8px',
    cursor: 'pointer',
    color: '#51cbce',
    '&:hover': {
      color: '#3a9598',
    },
  },
  totalRow: {
    fontWeight: 600,
    backgroundColor: 'rgba(81,203,206,.05)',
    '&:hover': {
      backgroundColor: 'rgba(81,203,206,.08) !important'
    }
  },
  chartContainer: {
    height: '300px',
    position: 'relative',
    padding: '10px'
  },
  cardChart: {
    background: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 6px 10px rgba(0,0,0,.08)',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 12px 20px rgba(0,0,0,.12)'
    }
  }
}));