import React, { useState } from 'react';
import { Box, Typography, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import DateRangePicker from '../../Common/DateRangePicker';
import DownloadIcon from '@mui/icons-material/Download';

const SMSVerificationHistory = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [records, setRecords] = useState([
    {
      no: 1,
      date: '2024-12-05 17:13:20',
      type: '가맹점',
      id: 'm90745089',
      name: '안경선',
      ip: '106.101.196.221',
      registeredPhone: '010-9074-5089',
      loginPhone: '010-9074-5089'
    },
    {
      no: 2,
      date: '2024-12-05 16:12:17',
      type: '가맹점',
      id: 'm48780064',
      name: '오성상사',
      ip: '211.235.97.144',
      registeredPhone: '010-4878-0051',
      loginPhone: '010-3766-0071'
    },
    // ... more records ...
  ]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountType, setAccountType] = useState('전체');

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedRecords = [...records].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setRecords(sortedRecords);
  };

  return (
    <Card sx={{ mt: 4, borderRadius: '12px', boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)', height: 'calc(100vh - 280px)' }}>
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SmsIcon sx={{ color: '#d32f2f' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            문자 인증내역
          </Typography>
        </Box>
      </Box>

      <Box sx={{ px: 3, pb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
        />
        <TextField
          size="small"
          placeholder="로그인 아이디, IP, 휴대번호"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          sx={{ width: '300px' }}
        />
        <RadioGroup
          row
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
        >
          <FormControlLabel value="가맹점" control={<Radio />} label="가맹점" />
          <FormControlLabel value="영업" control={<Radio />} label="영업" />
        </RadioGroup>
        <Tooltip title="엑셀 다운로드">
          <IconButton
            sx={{
              width: '40px',
              height: '40px',
              backgroundColor: '#fff',
              border: '1px solid #2e7d32',
              color: '#2e7d32',
              '&:hover': {
                backgroundColor: '#e8f5e9',
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer sx={{ height: 'calc(100vh - 430px)', '&::-webkit-scrollbar': { width: '8px' }, '&::-webkit-scrollbar-track': { background: '#f1f1f1', borderRadius: '4px' }, '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px', '&:hover': { background: '#666' } } }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>No</TableCell>
              <TableCell 
                sx={{ 
                  backgroundColor: '#f7f7f7', 
                  fontWeight: 600, 
                  color: '#252631',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#eaeaea'
                  }
                }}
                onClick={handleSortClick}
              >
                등록일시
                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
              </TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>가맹점/영업</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>ID</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>상호명</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>IP</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>등록 휴대번호</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>로그인 휴대번호</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' }, transition: 'background-color 0.2s' }}>
                <TableCell>{record.no}</TableCell>
                <TableCell>{record.date}</TableCell>
                <TableCell>{record.type}</TableCell>
                <TableCell>{record.id}</TableCell>
                <TableCell>{record.name}</TableCell>
                <TableCell>{record.ip}</TableCell>
                <TableCell>{record.registeredPhone}</TableCell>
                <TableCell>{record.loginPhone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SMSVerificationHistory;
