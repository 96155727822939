import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  useMediaQuery,
  useTheme,
  Paper
} from '@mui/material';
import Notice from '../../Notice/Notice'; // 공지사항 컴포넌트
import DateRangePicker from '../../Common/DateRangePicker'; // DateRangePicker 추가
import SummaryData from '../../Common/SummaryData'; // 퀵메뉴 추가
import ScSummaryTable from './ScSummaryTable'; // 테이블 컴포넌트 추가

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#fff',
    margin: 0,
  },
  content: {
    flexGrow: 1,
    padding: '20px',
    transition: 'margin 0.3s ease',
  },
  noticeBox: { 
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    mb: 3,
    overflow: 'hidden',
    zIndex: 0,
  },
  quickMenu: {
    display: { xs: 'none', md: 'flex' },
    flexDirection: 'column',
    position: 'fixed',
    left: 20,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1000,
  }
};

function ScheduledSettlementSummary() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const handleResize = () => {
      // 모바일 처리 로직
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
      backgroundColor: '#fff',
      margin: 0,
    }}>
      {/* 퀵메뉴 */}
      <Box sx={{
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        position: 'fixed',
        left: 20,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1000,
      }}>
        <SummaryData />
      </Box>

      <Box sx={{ 
        flexGrow: 1,
        padding: '20px',
        overflow: 'hidden',
        pl: isMobile ? '10px' : '100px'
      }}>
        {/* 공지사항 */}
        <Box sx={{ 
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          mb: 3,
          overflow: 'hidden',
          zIndex: 0,
        }}>
          <Notice />
        </Box>

        {/* 날짜 범위 선택 */}
        <Box mb={2}>
          <DateRangePicker 
            startDate={startDate} 
            endDate={endDate} 
            onStartDateChange={setStartDate} 
            onEndDateChange={setEndDate} 
          />
        </Box>

        {/* 테이블 출력 */}
        <Box sx={{ 
          height: 'calc(100vh - 250px)',
          overflow: 'hidden'
        }}>
          <ScSummaryTable startDate={startDate} endDate={endDate} />
        </Box>
      </Box>
    </Box>
  );
}

export default ScheduledSettlementSummary;