import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Button
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DownloadIcon from '@mui/icons-material/Download';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';

const ActionButton = styled(IconButton)(({ theme }) => ({
  width: '32px',
  height: '32px',
  border: '1px solid',
  padding: '4px',
  marginRight: '8px',
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 8px',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  ['&.MuiTableCell-head']: {
    backgroundColor: '#f8f9fa',
    fontWeight: 600,
  }
}));

const ActionIconButton = styled(Button)(({ color = 'primary' }) => ({
  minWidth: 'auto',
  padding: '6px 12px',
  fontSize: '0.75rem',
  borderRadius: '4px',
  textTransform: 'none',
  backgroundColor: color === 'primary' ? '#51cbce' : 
                 color === 'warning' ? '#fbc658' : 
                 '#6bd098',
  color: '#fff',
  '&:hover': {
    backgroundColor: color === 'primary' ? '#34b5b8' : 
                   color === 'warning' ? '#f7b03c' : 
                   '#44c97d',
  }
}));

const WithdrawalHold = () => {
  // 더미 데이터
  const rows = [
    {
      no: 375,
      amount: '2698920',
      bankName: '카카오뱅크',
      accountNumber: '3333113208760',
      accountHolder: '전우희',
      merchantId: 'm99986962',
      requestDate: '2024-12-10 10:07:21'
    },
    // ... 더 많은 데이터
  ];

  return (
    <Box sx={{ p: 3 }}>
      {/* 제목 영역 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <AssignmentIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          월렛 출금대기 리스트
        </Typography>
      </Box>

      {/* 액션 버튼 영역 */}
      <Box sx={{ mb: 3, display: 'flex', gap: 1 }}>
        <Tooltip title="Copy">
          <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
            <FontAwesomeIcon icon={faCopy} />
          </ActionButton>
        </Tooltip>
        <Tooltip title="Excel">
          <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
            <DownloadIcon />
          </ActionButton>
        </Tooltip>
        <Tooltip title="Print">
          <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
            <FontAwesomeIcon icon={faPrint} />
          </ActionButton>
        </Tooltip>
      </Box>

      {/* 테이블 */}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell>금금액</StyledTableCell>
              <StyledTableCell>은행명</StyledTableCell>
              <StyledTableCell>계좌번호</StyledTableCell>
              <StyledTableCell>예금주</StyledTableCell>
              <StyledTableCell>로그인아이디</StyledTableCell>
              <StyledTableCell>출금요청일시</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.no} hover>
                <StyledTableCell>{row.no}</StyledTableCell>
                <StyledTableCell>{row.amount}</StyledTableCell>
                <StyledTableCell>{row.bankName}</StyledTableCell>
                <StyledTableCell>{row.accountNumber}</StyledTableCell>
                <StyledTableCell>{row.accountHolder}</StyledTableCell>
                <StyledTableCell>{row.merchantId}</StyledTableCell>
                <StyledTableCell>{row.requestDate}</StyledTableCell>
                <StyledTableCell align="center" sx={{ display: 'flex', gap: 1 }}>
                  <ActionIconButton
                    startIcon={<NotificationsIcon />}
                    color="primary"
                  >
                    알림 전송
                  </ActionIconButton>
                  <ActionIconButton
                    startIcon={<CancelIcon />}
                    color="warning"
                  >
                    취소
                  </ActionIconButton>
                  <ActionIconButton
                    startIcon={<AccountBalanceWalletIcon />}
                    color="success"
                  >
                    출금
                  </ActionIconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WithdrawalHold;