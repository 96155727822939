import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Paper,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  Tooltip
} from '@mui/material';
import { Search as SearchIcon, Download as DownloadIcon } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import Sidebar from '../../Sidebar';
import Notice from '../../Notice/Notice';
import FailHistoryTable from './FailHistoryTable';
import DateRangePicker from '../../Common/DateRangePicker';
import Breadcrumb from '../../Common/Breadcrumb';
import SummaryData from '../../Common/SummaryData';

const dummyData = [
  {
    no: 1,
    loginId: "user123",
    company: "테스트상점1",
    tid: "T1234567890",
    mid: "M987654321",
    authType: "인증",
    paymentType: "수기결제",
    transactionDate: "2024-01-15 14:30:22",
    amount: "1,000,000",
    transactionStatus: "승인",
    cardNumber: "123456******1234",
    expiryDate: "12/25",
    installment: "일시불",
    paymentMethod: "신용카드",
    buyerName: "홍길동",
    resultMessage: "정상처리되었습니다",
    trackId: "TR123456789"
  },
  {
    no: 2,
    loginId: "user456",
    company: "테스트상점2",
    tid: "T2345678901",
    mid: "M876543210",
    authType: "비인증",
    paymentType: "온라인결제",
    transactionDate: "2024-01-15 15:45:33",
    amount: "750,000",
    transactionStatus: "승인",
    cardNumber: "987654******4321",
    expiryDate: "11/26",
    installment: "3개월",
    paymentMethod: "신용카드",
    buyerName: "김철수",
    resultMessage: "정상처리되었습니다",
    trackId: "TR234567890"
  },
  {
    no: 3,
    loginId: "user789",
    company: "테스트상점3",
    tid: "T3456789012",
    mid: "M765432109",
    authType: "인증",
    paymentType: "정기결제",
    transactionDate: "2024-01-15 16:20:45",
    amount: "500,000",
    transactionStatus: "승인",
    cardNumber: "234567******5678",
    expiryDate: "08/27",
    installment: "일시불",
    paymentMethod: "신용카드",
    buyerName: "이영희",
    resultMessage: "정상처리되었습니다",
    trackId: "TR345678901"
  },
  {
    no: 4,
    loginId: "user101",
    company: "테스트상점4",
    tid: "T4567890123",
    mid: "M654321098",
    authType: "비인증",
    paymentType: "수기결제",
    transactionDate: "2024-01-15 17:10:15",
    amount: "1,500,000",
    transactionStatus: "승인",
    cardNumber: "345678******6789",
    expiryDate: "03/26",
    installment: "6개월",
    paymentMethod: "신용카드",
    buyerName: "박민수",
    resultMessage: "정상처리되었습니다",
    trackId: "TR456789012"
  },
  {
    no: 5,
    loginId: "user202",
    company: "테스트상점5",
    tid: "T5678901234",
    mid: "M543210987",
    authType: "인증",
    paymentType: "온라인결제",
    transactionDate: "2024-01-15 18:05:33",
    amount: "2,000,000",
    transactionStatus: "승인",
    cardNumber: "456789******7890",
    expiryDate: "07/25",
    installment: "12개월",
    paymentMethod: "신용카드",
    buyerName: "최지은",
    resultMessage: "정상처리되었습니다",
    trackId: "TR567890123"
  },
  {
    no: 6,
    loginId: "user303",
    company: "테스트상점6",
    tid: "T6789012345",
    mid: "M432109876",
    authType: "비인증",
    paymentType: "정기결제",
    transactionDate: "2024-01-15 19:25:50",
    amount: "800,000",
    transactionStatus: "승인",
    cardNumber: "567890******8901",
    expiryDate: "10/26",
    installment: "일시불",
    paymentMethod: "신용카드",
    buyerName: "강동원",
    resultMessage: "정상처리되었습니다",
    trackId: "TR678901234"
  }
];

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '20px', 
    width: 'calc(100% - 40px)',
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '20px',
    pr: '20px',
    ml: '0px'
  },
  noticeBox: { 
    width: 'calc(100% - 20px)', 
    margin: '0 auto', 
    mb: 2 
  }
};

function FailHistory() {
  const [sortColumn, setSortColumn] = useState('transactionDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [startDate, setStartDate] = useState('2024-01-01');
  const [endDate, setEndDate] = useState('2024-11-28');
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [cardCompany, setCardCompany] = useState('');
  const [installment, setInstallment] = useState('');
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 800 && window.innerWidth <= 1025);
  const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 800 && window.innerWidth <= 1025);
      setIsMobile(window.innerWidth < 800);
      setIsSmallMobile(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedData = [...dummyData].sort((a, b) => {
    if (sortDirection === 'asc') {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    }
    return a[sortColumn] < b[sortColumn] ? 1 : -1;
  });

  const handleRowClick = (row) => {
    if (isSmallScreen) {
      setSelectedRow(row);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const mainBoxStyle = {
    ...styles.mainBox,
    mx: { xs: '20px', md: '20px' },
    pl: { xs: '40px', md: '40px' },
    pr: { xs: '40px', md: '40px' },
    width: { xs: 'calc(100% - 40px)', md: 'calc(100% - 40px)' }
  };

  return (
    <Box component="main" sx={mainBoxStyle}>
      <Box sx={styles.noticeBox}>
        <Notice />
      </Box>

      <Paper sx={{ 
        p: 1,
        mx: 1,
        width: 'calc(100% - 20px)'
      }}>
        {!isSmallMobile ? (
          <Box sx={{ 
            display: 'flex',
            flexDirection: (isTablet || isMobile) ? 'column' : 'row',
            alignItems: 'center',
            gap: '8px',
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              width: (isTablet || isMobile) ? '100%' : 'auto',
              flexWrap: 'nowrap'
            }}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
              />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography component="span" sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                  카드사:
                </Typography>
                <FormControl size="small" sx={{ minWidth: 100 }}>
                  <Select value={cardCompany || ''} onChange={(e) => setCardCompany(e.target.value)}>
                    <MenuItem value="">전체</MenuItem>
                    <MenuItem value="samsung">삼성</MenuItem>
                    <MenuItem value="hyundai">현대</MenuItem>
                    <MenuItem value="kb">국민</MenuItem>
                    <MenuItem value="shinhan">신한</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography component="span" sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                  할부:
                </Typography>
                <FormControl size="small" sx={{ minWidth: 90 }}>
                  <Select value={installment || ''} onChange={(e) => setInstallment(e.target.value)}>
                    <MenuItem value="">전체</MenuItem>
                    <MenuItem value="0">일시불</MenuItem>
                    <MenuItem value="2">2개월</MenuItem>
                    <MenuItem value="3">3개월</MenuItem>
                    <MenuItem value="4">4개월</MenuItem>
                    <MenuItem value="5">5개월</MenuItem>
                    <MenuItem value="6">6개월</MenuItem>
                    <MenuItem value="7">7개월</MenuItem>
                    <MenuItem value="8">8개월</MenuItem>
                    <MenuItem value="9">9개월</MenuItem>
                    <MenuItem value="10">10개월</MenuItem>
                    <MenuItem value="11">11개월</MenuItem>
                    <MenuItem value="12">12개월</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {!isTablet && !isMobile && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography component="span" sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                      인증방식:
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup row value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                        <FormControlLabel value="auth" control={<Radio size="small" />} label="인증" />
                        <FormControlLabel value="non-auth" control={<Radio size="small" />} label="비인증" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <TextField
                    placeholder="MID,TID,가맹점명,상품명,승인번호,예금주"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    size="small"
                    sx={{
                      width: '260px',
                      '& .MuiOutlinedInput-root': {
                        height: '36px',
                      }
                    }}
                  />

                  <Tooltip title="엑셀 다운로드">
                    <IconButton
                      sx={{
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#fff',
                        border: '1px solid #2e7d32',
                        color: '#2e7d32',
                        '&:hover': {
                          backgroundColor: '#2e7d3210',
                        }
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>

            {(isTablet || isMobile) && (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1,
                width: '100%',
                mt: 2,
                flexWrap: isMobile ? 'wrap' : 'nowrap'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                  width: isMobile ? '100%' : 'auto'
                }}>
                  <Typography component="span" sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                    인증방식:
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup row value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                      <FormControlLabel value="auth" control={<Radio size="small" />} label="인증" />
                      <FormControlLabel value="non-auth" control={<Radio size="small" />} label="비인증" />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <TextField
                  placeholder="MID,TID,가맹점명,상품명,승인번호,예금주"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  size="small"
                  sx={{
                    width: isMobile ? '100%' : '260px',
                    '& .MuiOutlinedInput-root': {
                      height: '36px',
                    }
                  }}
                />

                <Tooltip title="엑셀 다운로드">
                  <IconButton
                    sx={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: '1px solid #2e7d32',
                      color: '#2e7d32',
                      '&:hover': {
                        backgroundColor: '#2e7d3210',
                      }
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%'
          }}>
            <Box sx={{ width: '100%' }}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
              />
            </Box>

            <FormControl size="small" sx={{ width: '100%' }}>
              <InputLabel>카드사</InputLabel>
              <Select value={cardCompany || ''} onChange={(e) => setCardCompany(e.target.value)}>
                
                <MenuItem value="bc">BC카드</MenuItem>
                <MenuItem value="kb">KB국민카드</MenuItem>
                <MenuItem value="nh">NH농협카드</MenuItem>
                <MenuItem value="lotte">롯데카드</MenuItem>
                <MenuItem value="samsung">삼성카드</MenuItem>
                <MenuItem value="shinhan">신한카드</MenuItem>
                <MenuItem value="hana">하나카드</MenuItem>
                <MenuItem value="hyundai">현대카드</MenuItem>
                <MenuItem value="woori">우리카드</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: '100%' }}>
              <InputLabel>할부</InputLabel>
              <Select value={installment || ''} onChange={(e) => setInstallment(e.target.value)}>
                
                <MenuItem value="0">일시불</MenuItem>
                <MenuItem value="2">2개월</MenuItem>
                <MenuItem value="3">3개월</MenuItem>
                <MenuItem value="4">4개월</MenuItem>
                <MenuItem value="5">5개월</MenuItem>
                <MenuItem value="6">6개월</MenuItem>
                <MenuItem value="7">7개월</MenuItem>
                <MenuItem value="8">8개월</MenuItem>
                <MenuItem value="9">9개월</MenuItem>
                <MenuItem value="10">10개월</MenuItem>
                <MenuItem value="11">11개월</MenuItem>
                <MenuItem value="12">12개월</MenuItem>
              </Select>
            </FormControl>

            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <RadioGroup 
                row 
                value={searchType} 
                onChange={(e) => setSearchType(e.target.value)}
              >
                <FormControlLabel value="auth" control={<Radio size="small" />} label="인증" />
                <FormControlLabel value="non-auth" control={<Radio size="small" />} label="비인증" />
              </RadioGroup>
            </FormControl>

            <TextField
              placeholder="MID,TID,가맹점명,상품명,승인번호,예금주"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  height: '36px',
                }
              }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Tooltip title="엑셀 다운로드">
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#2e7d3210',
                    }
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Paper>

      <Box sx={{ 
        width: { xs: '100%', md: 'calc(100% - 20px)' }, 
        margin: '0 auto',
        overflowX: 'auto' 
      }}>

        <Box mb={3}/>
        <FailHistoryTable 
          data={sortedData}
          isSmallScreen={isSmallScreen}
          selectedRow={selectedRow}
          openModal={openModal}
          handleRowClick={handleRowClick}
          handleCloseModal={handleCloseModal}
        />
      </Box>

      <SummaryData />
    </Box>
  );
}

export default FailHistory;
