import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  IconButton, 
  Tooltip, 
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';

const ExcelManagement = () => {
  const [tableType, setTableType] = useState('');
  const [records, setRecords] = useState([
    {
      id: 1,
      date: '2024-03-15 14:30:22',
      type: '가맹점',
      fileName: 'merchant_data_20240315.xlsx',
      status: '성공',
      admin: '관리자1'
    },
    {
      id: 2,
      date: '2024-03-15 13:25:17',
      type: '영업계정',
      fileName: 'sales_account_20240315.xlsx',
      status: '실패',
      admin: '관리자2'
    },
    // ... 더 많은 샘플 데이터
  ]);

  return (
    <Card sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <ListAltIcon sx={{ mr: 1, color: '#1976d2' }} />
        <Typography variant="h6" sx={{ fontWeight: 600, flexGrow: 1 }}>
          엑셀 관리
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <RadioGroup
            row
            value={tableType}
            onChange={(e) => setTableType(e.target.value)}
          >
            <FormControlLabel value="가맹점" control={<Radio />} label="가맹점" />
            <FormControlLabel value="영업계정" control={<Radio />} label="영업계정" />
            <FormControlLabel value="입출금내역" control={<Radio />} label="입출금내역" />
          </RadioGroup>
          
          <Tooltip title="엑셀 다운로드">
            <IconButton
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#fff',
                border: '1px solid #2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  backgroundColor: '#e8f5e9',
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="엑셀 업로드">
            <IconButton
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#fff',
                border: '1px solid #00796b',
                color: '#00796b',
                '&:hover': {
                  backgroundColor: '#e0f2f1',
                }
              }}
            >
              <CloudUploadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <TableContainer sx={{ 
        height: 'calc(100vh - 430px)', 
        '&::-webkit-scrollbar': { width: '8px' }, 
        '&::-webkit-scrollbar-track': { background: '#f1f1f1', borderRadius: '4px' }, 
        '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px', '&:hover': { background: '#666' } } 
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>No</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>일시</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>구분</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>파일명</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>상태</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>관리자</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' }, transition: 'background-color 0.2s' }}>
                <TableCell>{record.id}</TableCell>
                <TableCell>{record.date}</TableCell>
                <TableCell>{record.type}</TableCell>
                <TableCell>{record.fileName}</TableCell>
                <TableCell sx={{ 
                  color: record.status === '성공' ? '#2e7d32' : '#d32f2f',
                  fontWeight: 600
                }}>
                  {record.status}
                </TableCell>
                <TableCell>{record.admin}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ExcelManagement;
