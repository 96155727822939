import React, { useState } from 'react';
import { Box, Typography, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DnsIcon from '@mui/icons-material/Dns';

const SystemNotice = () => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [notices, setNotices] = useState([
    {
      action: '변경',
      group: '이트빈',
      public: 1,
      title: '고객센터 업무시간 안내',
      content: '업무시간 09:30~17:30\n(점심 12:00~13:00) 토,일 공휌일 휴무\n\n1566-9805 로 연락주시기 바랍니다.\nhelp@b19inc.com',
      date: '2022-07-20'
    },
    {
      action: '추가',
      group: '이트빈',
      public: 1,
      title: '업데이트 안내 ver.2.1.1',
      content: 'Payment management\n- 사용자 UI/UX 개선\n- 마이페이지 오픈\n- 플러그인 고객센터 상시 오픈\n- 정기결제 기능 개선\n- 환불 모듈 기능 개\n- 디자인에 관련승급 오픈\n- 기타 버그 수정',
      date: '20240306'
    },
    {
      action: '변경',
      group: '이트빈',
      public: 0,
      title: '팝업 점검 작업 공지',
      content: '안녕하세요.\n2023년 10월 3일 10:00 ~ 16:00 팝업 점검 작업이 예정되어 있습니다.\n업무에 참고하시기 바랍니다.',
      date: '2023-10-03'
    }
  ]);

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedNotices = [...notices].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setNotices(sortedNotices);
  };

  return (
    <Card sx={{ 
      mt: 4, 
      borderRadius: '12px', 
      boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
      height: 'calc(100vh - 280px)'
    }}>
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DnsIcon sx={{ color: '#d32f2f' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            본전산 공지사항
          </Typography>
        </Box>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />} 
          sx={{ 
            backgroundColor: '#d32f2f',
            '&:hover': {
              backgroundColor: '#9a0007'
            },
            boxShadow: '0 2px 4px rgba(211, 47, 47, 0.3)',
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          공지추가
        </Button>
      </Box>
      <TableContainer sx={{ 
        height: 'calc(100vh - 380px)',
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
          '&:hover': {
            background: '#666'
          }
        }
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>행동</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>그룹</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>공개/비공개</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>제목</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>내용</TableCell>
              <TableCell 
                sx={{ 
                  backgroundColor: '#f7f7f7', 
                  fontWeight: 600, 
                  color: '#252631',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#eaeaea'
                  }
                }}
                onClick={handleSortClick}
              >
                등록시간
                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
              </TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>작업</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notices.map((notice, index) => (
              <TableRow 
                key={index}
                sx={{ 
                  '&:hover': { backgroundColor: '#f5f5f5' },
                  transition: 'background-color 0.2s'
                }}
              >
                <TableCell>{notice.action}</TableCell>
                <TableCell>{notice.group}</TableCell>
                <TableCell>{notice.public ? '공개' : '비공개'}</TableCell>
                <TableCell>{notice.title}</TableCell>
                <TableCell sx={{ fontSize: '0.8rem', maxWidth: '300px' }}>{notice.content}</TableCell>
                <TableCell>{notice.date}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="보기">
                      <IconButton size="small" sx={{ color: '#51cbce' }}>
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="수정">
                      <IconButton size="small" sx={{ color: '#fbc658' }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="삭제">
                      <IconButton size="small" sx={{ color: '#ef8157' }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SystemNotice;
