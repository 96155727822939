import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Paper
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchOptions from '../../Common/SearchOptions'; // 검색 패널 컴포넌트
import Notice from '../../Notice/Notice'; // 공지사항 컴포넌트
import QuickMenu from '../../Common/SummaryData'; // 좌측 퀵메뉴 컴포넌트
import ScheduledOffsetTable from './ScheduledOffsetTable'; // ScheduledOffsetTable 컴포넌트
import ResultText from '../../ResultText'; // ResultText 컴포넌트
import ResultTable from '../../ResultTable'; // ResultTable 컴포넌트
import DefaultTable from './DefaultTables';//기본 산출 테이블

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#fff',
    margin: 0,
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: '20px',
    transition: 'margin 0.3s ease',
    overflow: 'hidden',
    pr: { xs: '10px', md: '10px' },
    marginBottom: '0px',
    marginTop: '0px',
  },
  noticeBox: { 
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    mb: 3,
    marginLeft: { xs: '10px', md: '40px' },
    marginRight: { xs: '10px', md: '40px' },
    padding: '10px'
  },
  infoText: {
    marginBottom: '10px',
    fontSize: { xs: '1.2rem', md: '1.5rem' },
    fontWeight: 'bold',
    color: '#ff3d00',
    marginLeft: { xs: '10px', md: '40px' },
    marginRight: { xs: '10px', md: '40px' },
    borderBottom: '2px solid #e3f2fd',
    paddingBottom: '5px',
  },
  tableContainer: {
    marginLeft: { xs: '10px', md: '40px' },
    marginRight: { xs: '10px', md: '40px' },
    maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 400px)' },
    overflowY: 'auto',
    overflowX: 'auto',
    width: '100%',
    backgroundColor: '#fff', 
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)', 
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '320px',
      boxSizing: 'border-box',
      mt: '64px',
      height: 'calc(100% - 64px)',
      border: 'none',
      backgroundColor: 'transparent',
      '@media (max-width: 768px)': {
        width: '100%',
        mt: '56px',
        height: 'calc(100% - 56px)'
      }
    }
  }
};

function ScheduledSettlementDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchPanelOpen, setSearchPanelOpen] = useState(!isMobile);

  useEffect(() => {
    const handleResize = () => {
      setSearchPanelOpen(window.innerWidth >= 960);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
  };

  return (
    <Box sx={styles.root}>
      {/* 좌측 퀵메뉴 */}
      <QuickMenu />

      <Box sx={{ 
        ...styles.content, 
        pr: { 
          xs: '10px', 
          md: searchPanelOpen ? '320px' : '10px' 
        }
      }}>
        {/* 공지사항 */}
        <Box sx={styles.noticeBox}>
          <Notice />
        </Box>

        {/* 정보 텍스트 */}
        <Typography 
          sx={{
            textAlign: 'center', // 중앙 정렬
            fontSize: { xs: '1.2rem', md: '1.5rem' }, // 폰트 크기
            fontWeight: 'bold', // 두껍게
            color: '#1976d2', // 색상
            mb: 1, // 아래 마진
          }}
        >
          상계금액(총합) 0원
        </Typography>
        <Typography 
          sx={{
            textAlign: 'center', // 중앙 정렬
            fontSize: { xs: '1.0rem', md: '1.2rem' }, // 폰트 크기
            color: '#555', // 색상
            mb: 3, // 아래 마진
          }}
        >
          정산예정 정상산출 기준일 : 2023-11-01
        </Typography>

        {/* ResultText 컴포넌트 삽입 */}
        <Box sx={{ ml: { xs: '10px', md: '40px' } }}>
          <ResultText />
        </Box>

        {/* ResultTable 컴포넌트 삽입 */}
        <Box sx={{ ml: { xs: '10px', md: '40px' } }}>
          <ResultTable />
        </Box>

        {/* ScheduledOffsetTable 컴포넌트 삽입 */}
        
        


        <Box sx={{ ml: '40px', mb: '20px' }}> {/* 아래쪽 마진 추가 */}
            <ScheduledOffsetTable />
        </Box>
        
        <Box sx={{ ml: '40px', mt: '20px' }}> {/* 위쪽 마진 추가 */}
            <DefaultTable />
        </Box>
        
        

        {/* 검색 패널 */}
        <Drawer
          anchor={isMobile ? 'left' : 'right'}
          open={searchPanelOpen}
          onClose={() => setSearchPanelOpen(false)}
          variant={isMobile ? 'temporary' : 'persistent'}
          sx={styles.drawer}
        >
          <SearchOptions 
            onSearch={handleSearch}
            onClose={() => setSearchPanelOpen(false)}
            isMobile={isMobile}
          />
        </Drawer>

        {/* 검색 패널 열기 버튼 */}
        {!searchPanelOpen && (
          <IconButton
            onClick={() => setSearchPanelOpen(true)}
            sx={{
              position: 'fixed',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#fff',
              boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
              width: '20px',
              height: '40px',
              borderRadius: '4px 0 0 4px',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              }
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export default ScheduledSettlementDetails;
