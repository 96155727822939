import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const MerchantIcons = ({ onIconClick }) => {
  const buttons = [
    {
      label: '금지',
      icon: <BlockIcon />,
      value: 'block',
      color: '#dc3545'
    },
    {
      label: '실적문자',
      icon: <TextsmsIcon />,
      value: 'sms',
      color: '#4caf50'
    },
    {
      label: '정기결제',
      icon: <AutorenewIcon />,
      value: 'recurring',
      color: '#2196f3'
    }
  ];

  return (
    <Box>
      <Typography 
        variant="subtitle1" 
        sx={{ 
          fontSize: '0.875rem', 
          fontWeight: 600,
          color: '#495057',
          mb: 1.5,
          pl: '4px'
        }}
      >
        가맹점 아이콘
      </Typography>
      <Box sx={{ 
        display: 'flex',
        gap: 1
      }}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            startIcon={button.icon}
            onClick={() => onIconClick?.(button.value)}
            sx={{
              bgcolor: '#fff',
              color: button.color,
              border: `1px solid ${button.color}`,
              fontSize: '0.75rem',
              height: '32px',
              padding: '4px 12px',
              minWidth: 'auto',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: `${button.color}10`,
                border: `1px solid ${button.color}`,
                boxShadow: 'none'
              },
              '& .MuiButton-startIcon': {
                marginRight: '4px',
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem'
                }
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default MerchantIcons;