import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  IconButton
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';

const WalletPendingList = () => {
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // 상단 버튼 설정
  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2'
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32'
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2'
    }
  ];

  // 더미 데이터
  const rows = [
    {
      no: 372,
      amount: '1825960',
      bank_name: '기업은행',
      account_no: '01064019977',
      owner_name: '김동열',
      merchant_id: 'm76640459',
      request_time: '2024-12-06 13:11:51',
      status: '입금 건중'
    },
    {
      no: 371,
      amount: '1825960',
      bank_name: '기업은행',
      account_no: '01064019977',
      owner_name: '김동열',
      merchant_id: 'm76640459',
      request_time: '2024-12-06 13:11:00',
      status: '입금 건중'
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      {/* 헤더 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <AccountBalanceIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          출금 신청 목록
        </Typography>
      </Box>

      {/* 검색 및 필터 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap'
        }}>
          

          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ width: '300px' }}
          />

          {buttons.map((button, index) => (
            <Tooltip key={index} title={button.label}>
              <IconButton
                sx={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#fff',
                  border: `1px solid ${button.color}`,
                  color: button.color,
                  '&:hover': {
                    backgroundColor: `${button.color}10`,
                  }
                }}
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </Paper>

      {/* 테이블 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f8f9fa' }}>
                <th style={tableHeaderStyle}>No</th>
                <th style={tableHeaderStyle}>출금액</th>
                <th style={tableHeaderStyle}>은행명</th>
                <th style={tableHeaderStyle}>계좌번호</th>
                <th style={tableHeaderStyle}>예금주</th>
                <th style={tableHeaderStyle}>로그인아이디</th>
                <th style={tableHeaderStyle}>출금요청일</th>
                <th style={tableHeaderStyle}>알림발송</th>
                <th style={tableHeaderStyle}>취소</th>
                <th style={tableHeaderStyle}>출금</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.no}>
                  <td style={tableCellStyle}>{row.no}</td>
                  <td style={tableCellStyle}>{row.amount}</td>
                  <td style={tableCellStyle}>{row.bank_name}</td>
                  <td style={tableCellStyle}>{row.account_no}</td>
                  <td style={tableCellStyle}>{row.owner_name}</td>
                  <td style={tableCellStyle}>{row.merchant_id}</td>
                  <td style={tableCellStyle}>{row.request_time}</td>
                  <td style={tableCellStyle}>
                    <ActionButton
                      title="알림발송"
                      icon={<NotificationsIcon />}
                      color="#51cbce"
                      hoverColor="#34b5b8"
                    />
                  </td>
                  <td style={tableCellStyle}>
                    <ActionButton
                      title="취소"
                      icon={<CancelIcon />}
                      color="#6bd098"
                      hoverColor="#44c47d"
                    />
                  </td>
                  <td style={tableCellStyle}>
                    <ActionButton
                      title="출금"
                      icon={<AccountBalanceWalletIcon />}
                      color="#ef8157"
                      hoverColor="#eb6532"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Paper>
    </Box>
  );
};

// 스타일 상수
const tableHeaderStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  color: '#344767',
  fontWeight: 600,
  fontSize: '0.75rem',
  textTransform: 'uppercase'
};

const tableCellStyle = {
  padding: '12px 8px',
  textAlign: 'center',
  fontSize: '0.875rem'
};

// 액션 버튼 컴포넌트
const ActionButton = ({ title, icon, color, hoverColor }) => (
  <Tooltip title={title}>
    <IconButton
      size="medium"
      sx={{
        backgroundColor: color,
        color: '#fff',
        width: '36px',
        height: '36px',
        '&:hover': {
          backgroundColor: hoverColor
        }
      }}
    >
      {React.cloneElement(icon, { sx: { fontSize: '1.2rem' } })}
    </IconButton>
  </Tooltip>
);

export default WalletPendingList;
