import React, { useState } from 'react';
import { 
  Box, 
  Card, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RefreshIcon from '@mui/icons-material/Refresh';

const MerchantBalance = () => {
  const [orderBy, setOrderBy] = useState('date');  // 기본 정렬 필드를 date로 변경
  const [order, setOrder] = useState('desc');      // 날짜는 최신순이 기본
  
  // 임시 데이터 - 날짜 형식으로 변경
  const [rows, setRows] = useState([
    { 
      id: 1, 
      date: '2024-03-15', 
      tid: 'T123456', 
      inputBalance: 1000000, 
      outputBalance: 900000, 
      balance: 100000 
    },
    { 
      id: 2, 
      date: '2024-03-14', 
      tid: 'T234567', 
      inputBalance: 2000000, 
      outputBalance: 1500000, 
      balance: 500000 
    },
  ]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRefresh = () => {
    // API 호출 로직 구현
    console.log('잔액 정보 갱신');
  };

  // 정렬 함수
  const sortData = (data) => {
    if (!orderBy) return data;

    return [...data].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      
      if (order === 'asc') {
        if (typeof aValue === 'number') {
          return aValue - bValue;
        }
        return aValue.toString().localeCompare(bValue.toString());
      } else {
        if (typeof aValue === 'number') {
          return bValue - aValue;
        }
        return bValue.toString().localeCompare(aValue.toString());
      }
    });
  };

  // 날짜 포맷팅 함수 추가
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const headCells = [
    { id: 'no', label: 'No', sortable: false },
    { id: 'date', label: '날짜', sortable: true },
    { id: 'tid', label: 'TID', sortable: false },
    { id: 'inputBalance', label: '입금액', sortable: true },
    { id: 'outputBalance', label: '출금액', sortable: true },
    { id: 'balance', label: '잔액', sortable: true },
  ];

  return (
    <Card sx={{ mt: 4, borderRadius: '12px', boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)' }}>
      <Box sx={{ p: 3 }}>
        {/* 제목 및 갱신 버튼 */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AccountBalanceIcon sx={{ color: '#9c27b0' }} />
            <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
              머천트 잔액
            </Typography>
            <IconButton 
              onClick={handleRefresh}
              sx={{ 
                ml: 1,
                color: '#51cbce',
                bgcolor: 'rgba(81, 203, 206, 0.1)',
                '&:hover': {
                  bgcolor: 'rgba(81, 203, 206, 0.2)'
                }
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
        </Box>

        {/* 테이블 */}
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="center"
                    sx={{ 
                      fontWeight: 600,
                      bgcolor: '#f8f9fa'
                    }}
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleRequestSort(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortData(rows).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{formatDate(row.date)}</TableCell>
                  <TableCell align="center">{row.tid}</TableCell>
                  <TableCell align="center">{row.inputBalance.toLocaleString()}</TableCell>
                  <TableCell align="center">{row.outputBalance.toLocaleString()}</TableCell>
                  <TableCell align="center">{row.balance.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default MerchantBalance;
