import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    padding: '20px'
  },
  cardChart: {
    background: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 6px 10px rgba(0,0,0,.08)',
    transition: 'all 0.3s ease',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 12px 20px rgba(0,0,0,.12)',
    },
  },
  cardHeader: {
    padding: '20px 20px 0',
    borderBottom: 'none'
  },
  chartTitle: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: '#2c2c2c',
    marginBottom: '4px'
  },
  chartSubtitle: {
    fontSize: '0.9rem',
    color: '#999',
    marginBottom: '20px'
  },
  chartBody: {
    padding: '20px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  chartContainer: {
    flex: 1,
    minHeight: '300px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));