import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  TableSortLabel
} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeleteIcon from '@mui/icons-material/Delete';

const UserNotificationLogs = () => {
  const [notificationText, setNotificationText] = useState('');
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [sendMessage, setSendMessage] = useState('');

  // 더미 데이터
  const rows = [
    {
      no: 1,
      id: 'm92610082',
      message: '계정 보안에서 일부로 중가 가능을 안내드립니다.',
      send_time: '2024-12-06 10:09:48',
      end_time: '2024-12-06 11:09:48',
    },
    {
      no: 2,
      id: '1047036610',
      message: '관리자에 의해 설정이 변경되었습니다. 로그아웃후 재접속 바랍니다.',
      send_time: '2024-12-05 13:29:23',
      end_time: '2024-12-06 13:29:23',
    },
    // ... 더 많은 더미 데이터 추가 ...
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSendNotification = () => {
    if (notificationText.trim()) {
      setSendMessage('알림을 전송하시겠습니까?');
      // 여기에 실제 전송 로직 추가
    }
  };

  // 정렬된 데이터 반환
  const sortedRows = React.useMemo(() => {
    const comparator = (a, b) => {
      if (order === 'asc') {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    };

    return [...rows].sort(comparator);
  }, [rows, order, orderBy]);

  return (
    <Box sx={{ p: 3 }}>
      {/* 제목 영역 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <NotificationsActiveIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          유저 알림 내역
        </Typography>
      </Box>

      {/* 알림 입력 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2
        }}>
          <TextField
            fullWidth
            size="small"
            placeholder="전송할 알림 내용을 입력하세요"
            value={notificationText}
            onChange={(e) => setNotificationText(e.target.value)}
          />
          <Tooltip title="알림 전송">
            <IconButton
              onClick={handleSendNotification}
              sx={{
                backgroundColor: '#51cbce',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#34b5b8'
                }
              }}
            >
              <NotificationsActiveIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {sendMessage && (
          <Typography sx={{ mt: 1, color: '#1976d2' }}>
            {sendMessage}
          </Typography>
        )}
      </Paper>

      {/* 테이블 영역 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f8f9fa' }}>
                <th style={{ padding: '12px 8px', textAlign: 'center' }}>No</th>
                <th style={{ padding: '12px 8px' }}>아이디</th>
                <th style={{ padding: '12px 8px' }}>내용</th>
                <th style={{ padding: '12px 8px' }}>
                  <TableSortLabel
                    active={orderBy === 'send_time'}
                    direction={orderBy === 'send_time' ? order : 'asc'}
                    onClick={() => handleRequestSort('send_time')}
                  >
                    발송시간
                  </TableSortLabel>
                </th>
                <th style={{ padding: '12px 8px' }}>
                  <TableSortLabel
                    active={orderBy === 'end_time'}
                    direction={orderBy === 'end_time' ? order : 'asc'}
                    onClick={() => handleRequestSort('end_time')}
                  >
                    종료시간
                  </TableSortLabel>
                </th>
                <th style={{ padding: '12px 8px', textAlign: 'center' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedRows.map((row) => (
                <tr key={row.no}>
                  <td style={{ padding: '12px 8px', textAlign: 'center' }}>{row.no}</td>
                  <td style={{ padding: '12px 8px' }}>{row.id}</td>
                  <td style={{ padding: '12px 8px' }}>{row.message}</td>
                  <td style={{ padding: '12px 8px' }}>{row.send_time}</td>
                  <td style={{ padding: '12px 8px' }}>{row.end_time}</td>
                  <td style={{ 
                    padding: '12px 8px', 
                    textAlign: 'center',
                    backgroundColor: '#fafbfc'
                  }}>
                    <Tooltip title="삭제">
                      <IconButton
                        size="medium"
                        sx={{
                          backgroundColor: '#ef8157',
                          color: '#fff',
                          width: '36px',
                          height: '36px',
                          '&:hover': {
                            backgroundColor: '#eb6532'
                          }
                        }}
                      >
                        <DeleteIcon sx={{ fontSize: '1.2rem' }} />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Paper>
    </Box>
  );
};

export default UserNotificationLogs;
