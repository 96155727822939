import { useState, useEffect } from 'react';
import { Box, Grid, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { Bar, Pie } from 'react-chartjs-2';
import { useStyles } from './styles';

const PGStats = () => {
  const classes = useStyles();
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedData, setSortedData] = useState([]);

  // PG사 결제 데이터 확장
  const pgData = [
    {
      company: '페이업',
      paymentCount: 6310,
      actualAmount: 694365231,
      cancelAmount: -25920342,
      paymentAmount: 720285573
    },
    {
      company: '다날',
      paymentCount: 40,
      actualAmount: 1260340,
      cancelAmount: -403401,
      paymentAmount: 1663741
    },
    {
      company: '다페이',
      paymentCount: 345,
      actualAmount: 488650000,
      cancelAmount: -5138521,
      paymentAmount: 493788521
    },
    {
      company: '페이허브',
      paymentCount: 19,
      actualAmount: 11701004,
      cancelAmount: -260020,
      paymentAmount: 11961024
    },
    {
      company: 'SPC',
      paymentCount: 430,
      actualAmount: 138892890,
      cancelAmount: -10857100,
      paymentAmount: 149749990
    },
    {
      company: '갤럭시아',
      paymentCount: 5661,
      actualAmount: 452415021,
      cancelAmount: -12472282,
      paymentAmount: 464887303
    }
  ];

  // 합계 계산
  const totals = {
    paymentCount: pgData.reduce((sum, item) => sum + item.paymentCount, 0),
    actualAmount: pgData.reduce((sum, item) => sum + item.actualAmount, 0),
    cancelAmount: pgData.reduce((sum, item) => sum + item.cancelAmount, 0),
    paymentAmount: pgData.reduce((sum, item) => sum + item.paymentAmount, 0)
  };

  // 정렬 처리 함수
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const getSortedData = (data) => {
    if (!sortColumn) return data;
    
    return [...data].sort((a, b) => {
      const aValue = Math.abs(a[sortColumn]);
      const bValue = Math.abs(b[sortColumn]);
      
      if (sortDirection === 'asc') {
        return aValue - bValue;
      }
      return bValue - aValue;
    });
  };

  useEffect(() => {
    setSortedData(getSortedData(pgData));
  }, [sortColumn, sortDirection]);

  // 공통 옵션
  const commonOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif",
            weight: '500'
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: 12,
        titleFont: {
          size: 13,
          family: "'Noto Sans KR', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 12,
          family: "'Noto Sans KR', sans-serif"
        },
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `${context.dataset.label}: ${value.toLocaleString()}원`;
          }
        }
      }
    }
  };

  // 막대 차트 옵션
  const barOptions = {
    ...commonOptions,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif"
          },
          padding: 10,
          callback: value => value.toLocaleString() + '원'
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Noto Sans KR', sans-serif"
          }
        }
      }
    }
  };

  // 파이 차트 옵션
  const pieOptions = {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      legend: {
        ...commonOptions.plugins.legend,
        position: 'right',
        labels: {
          ...commonOptions.plugins.legend.labels,
          generateLabels: (chart) => {
            const data = chart.data;
            const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
            return data.labels.map((label, i) => ({
              text: `${label}: ${((data.datasets[0].data[i] / total) * 100).toFixed(1)}%`,
              fillStyle: data.datasets[0].backgroundColor[i]
            }));
          }
        }
      }
    }
  };

  const barChartData = {
    labels: sortedData.map(item => item.company),
    datasets: [
      {
        label: '결제금액',
        data: sortedData.map(item => item.paymentAmount),
        backgroundColor: 'rgba(88, 130, 250, 0.8)',
        borderColor: 'rgba(88, 130, 250, 1)',
        borderWidth: 1,
        borderRadius: 8,
        barPercentage: 0.7
      },
      {
        label: '취소금액',
        data: sortedData.map(item => Math.abs(item.cancelAmount)),
        backgroundColor: 'rgba(255, 165, 0, 0.8)',
        borderColor: 'rgba(255, 165, 0, 1)',
        borderWidth: 1,
        borderRadius: 8,
        barPercentage: 0.7
      },
      {
        label: '실 매출금액',
        data: sortedData.map(item => item.actualAmount),
        backgroundColor: 'rgba(128, 128, 128, 0.8)',
        borderColor: 'rgba(128, 128, 128, 1)',
        borderWidth: 1,
        borderRadius: 8,
        barPercentage: 0.7
      }
    ]
  };

  const pieChartData = {
    labels: ['실 매출금액', '취소금액', '결제금액'],
    datasets: [{
      data: [
        pgData.reduce((sum, item) => sum + item.actualAmount, 0),
        Math.abs(pgData.reduce((sum, item) => sum + item.cancelAmount, 0)),
        pgData.reduce((sum, item) => sum + item.paymentAmount, 0)
      ],
      backgroundColor: ['gray', 'orange', '#5882FA']
    }]
  };

  return (
    <Box className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                PG사별 결제금액
              </Typography>
              <Typography variant="subtitle2" className={classes.cardCategory}>
                PG사별 결제/취소/실매출 현황
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Bar data={barChartData} options={barOptions} />
              </div>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.cardChart}>
            <Box className={classes.cardHeader}>
              <Typography variant="h5" className={classes.chartTitle}>
                결제금액 분포
              </Typography>
              <Typography variant="subtitle2" className={classes.cardCategory}>
                전체 결제금액 비율
              </Typography>
            </Box>
            <Box className={classes.cardBody}>
              <div className={classes.chartContainer}>
                <Pie data={pieChartData} options={pieOptions} />
              </div>
            </Box>
          </Card>
        </Grid>

        {/* 테이블 영역 */}
        <Grid item xs={12}>
          <Card>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell width="100px">PG사</TableCell>
                    <TableCell width="100px" align="center">
                      결제건수
                      <IconButton size="small" onClick={() => handleSort('paymentCount')}>
                        {sortColumn === 'paymentCount' ? 
                          (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />) : 
                          <ArrowUpward />}
                      </IconButton>
                    </TableCell>
                    <TableCell width="100px" align="center">
                      실 매출금액
                      <IconButton size="small" onClick={() => handleSort('actualAmount')}>
                        {sortColumn === 'actualAmount' ? 
                          (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />) : 
                          <ArrowUpward />}
                      </IconButton>
                    </TableCell>
                    <TableCell width="100px" align="center" style={{ color: 'red' }}>
                      취소금액
                      <IconButton size="small" onClick={() => handleSort('cancelAmount')}>
                        {sortColumn === 'cancelAmount' ? 
                          (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />) : 
                          <ArrowUpward />}
                      </IconButton>
                    </TableCell>
                    <TableCell width="100px" align="center">
                      결제금액
                      <IconButton size="small" onClick={() => handleSort('paymentAmount')}>
                        {sortColumn === 'paymentAmount' ? 
                          (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />) : 
                          <ArrowUpward />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell>{row.company}</TableCell>
                      <TableCell align="center">{row.paymentCount.toLocaleString()}</TableCell>
                      <TableCell align="center">{row.actualAmount.toLocaleString()}</TableCell>
                      <TableCell align="center" style={{ color: 'red' }}>
                        {row.cancelAmount.toLocaleString()}
                      </TableCell>
                      <TableCell align="center">{row.paymentAmount.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow className={classes.totalRow}>
                    <TableCell>합계</TableCell>
                    <TableCell align="center">{totals.paymentCount.toLocaleString()}</TableCell>
                    <TableCell align="center">{totals.actualAmount.toLocaleString()}</TableCell>
                    <TableCell align="center" style={{ color: 'red' }}>
                      {totals.cancelAmount.toLocaleString()}
                    </TableCell>
                    <TableCell align="center">{totals.paymentAmount.toLocaleString()}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PGStats;