import React, { useState } from 'react';
import { 
  Box, 
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Modal,
  Typography,
  Tooltip
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import ButtonWidget from '../../Common/TransferButton';

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: color || '#495057',
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
}));

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px',
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

const tableHeaders = [
  { id: 'checkbox', label: '일괄 체크', color: '#bbdefb' },
  { id: 'no', label: 'NO', color: '#bbdefb' },
  { id: 'status', label: '상태', color: '#bbdefb' },
  { id: 'merchantMID', label: 'MID', color: '#bbdefb' },
  { id: 'merchantTID', label: 'TID', color: '#bbdefb' },
  { id: 'merchantName', label: '가맹점명', color: '#bbdefb' },
  { id: 'settlementCycle', label: '정산주기', color: '#bbdefb' },
  { id: 'transactionDate', label: '결제일시', color: '#2196f3' },
  { id: 'expectedSettlementDate', label: '정산예정일', color: '#2196f3' },
  { id: 'settlementTargetAmount', label: '정산 대상액', color: '#2196f3' },
  { id: 'detail', label: '세부내역', color: '#2196f3' },
  { id: 'offsetAmount', label: '상계금액', color: '#2196f3' },
  { id: 'remittanceAmount', label: '송금예정액', color: '#2196f3' },
  { id: 'approvalCount', label: '승인건수', color: '#2196f3' },
  { id: 'cancelCount', label: '취소건수', color: '#2196f3' },
  { id: 'approvalAmount', label: '승인금액', color: '#c8e6c9' },
  { id: 'cancelAmount', label: '취소금액', color: '#c8e6c9' },
  { id: 'commissionAmount', label: '수수료금액', color: '#c8e6c9' },
  { id: 'guaranteeAmount', label: '보증금액', color: '#c8e6c9' },
  { id: 'accountHolder', label: '예금주', color: '#f0f0f0' },
  { id: 'merchantPhone', label: '가맹점 휴대폰', color: '#f0f0f0' },
  { id: 'bankName', label: '은행명', color: '#f0f0f0' },
  { id: 'accountNumber', label: '계좌번호', color: '#f0f0f0' },
];

const dummyData = [
  {
    no: 1,
    status: '상태1',
    merchantMID: 'MID123',
    merchantTID: 'TID123',
    merchantName: '가맹점명1',
    settlementCycle: '정산주기1',
    transactionDate: '2024-01-01',
    expectedSettlementDate: '2024-01-02',
    settlementTargetAmount: 100000,
    detail: '세부내역1',
    offsetAmount: 10000,
    remittanceAmount: 90000,
    approvalCount: 5,
    cancelCount: 1,
    approvalAmount: 500000,
    cancelAmount: -10000,
    commissionAmount: 7200,
    guaranteeAmount: 0,
    accountHolder: '예금주1',
    merchantPhone: '010-1234-5678',
    bankName: '은행명1',
    accountNumber: '1234567890',
  },
  {
    no: 2,
    status: '상태2',
    merchantMID: 'MID456',
    merchantTID: 'TID456',
    merchantName: '가맹점명2',
    settlementCycle: '정산주기2',
    transactionDate: '2024-01-02',
    expectedSettlementDate: '2024-01-04',
    settlementTargetAmount: 200000,
    detail: '세부내역2',
    offsetAmount: 20000,
    remittanceAmount: 180000,
    approvalCount: 3,
    cancelCount: 0,
    approvalAmount: 300000,
    cancelAmount: 0,
    commissionAmount: 6000,
    guaranteeAmount: 0,
    accountHolder: '예금주2',
    merchantPhone: '010-9012-3456',
    bankName: '은행명2',
    accountNumber: '9876543210',
  },
];

function WalletTable({ 
  searchPanelOpen, 
  setSearchPanelOpen, 
  selectedRows, 
  setSelectedRows 
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('하이픈');

  const handleCheckboxChange = (checked, rowId) => {
    if (checked) {
      setSelectedRows(prev => [...prev, rowId]);
    } else {
      setSelectedRows(prev => prev.filter(id => id !== rowId));
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      <Box mb={2}>
        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <label>
            <input type="radio" value="하이픈" checked={selectedOption === '하이픈'} onChange={handleOptionChange} /> 하이픈
          </label>
          <label style={{ marginLeft: '10px' }}>
            <input type="radio" value="웰컴" checked={selectedOption === '웰컴'} onChange={handleOptionChange} /> 웰컴
          </label>
          <div style={{ marginLeft: '20px' }}>
            <ButtonWidget showFirst={false} showSecond={true} />
          </div>
        </div>
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table sx={styles.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={header.id}
                    align={header.id === 'checkbox' ? 'center' : 'left'}
                    style={{ 
                      backgroundColor: header.color,
                      position: 'sticky',
                      top: 0,
                      zIndex: 1
                    }}
                  >
                    {header.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dummyData.map((row) => (
                <StyledTableRow key={row.no}>
                  {tableHeaders.map((header) => (
                    <StyledTableCell 
                      key={`${row.no}-${header.id}`}
                      align={header.id === 'checkbox' ? 'center' : 'left'}
                    >
                      {header.id === 'checkbox' ? (
                        <Checkbox 
                          checked={selectedRows.includes(row.no)}
                          onChange={(e) => handleCheckboxChange(e.target.checked, row.no)}
                        />
                      ) : (
                        row[header.id]
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 1,
          width: '400px',
          maxWidth: '90vw'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            사용자 정보
          </Typography>
          {selectedUserData && (
            <>
              <Typography>고객명: {selectedUserData.customerName}</Typography>
              <Typography>휴대폰 번호: {selectedUserData.phoneNumber}</Typography>
              <Typography>승인금액: {selectedUserData.approvalAmount}</Typography>
              <Typography>승인번호: {selectedUserData.approvalNo}</Typography>
              <Typography>결제일자: {selectedUserData.paymentDate}</Typography>
              <Typography>취소일자: {selectedUserData.cancelDate || '-'}</Typography>
              <Typography>첨부파일: {selectedUserData.hasAttachments ? '있음' : '없음'}</Typography>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default WalletTable;
