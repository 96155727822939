import React, { useState } from "react";
import { Calendar as ReactBigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/ko";
import { Card, CardBody } from "reactstrap";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./SettlementCalendar.scss";

moment.locale('ko');
const localizer = momentLocalizer(moment);

// 색상 상수 추가
const COLORS = {
  primary: '#51cbce',
  success: '#6bd098',
  info: '#51bcda',
  warning: '#fbc658',
  danger: '#ef8157'
};

function SettlementCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('month');
  
  const events = [
    {
      title: 'All Day Event',
      start: new Date(2024, 11, 1),
      end: new Date(2024, 11, 1),
      color: 'green'
    },
    {
      title: 'Meeting',
      start: new Date(2024, 11, 8),
      end: new Date(2024, 11, 8),
      color: 'green'
    },
    {
      title: 'Birthday Party',
      start: new Date(2024, 11, 10),
      end: new Date(2024, 11, 10),
      color: 'blue'
    },
    {
      title: 'Lunch',
      start: new Date(2024, 11, 16),
      end: new Date(2024, 11, 16),
      color: 'red'
    }
  ];

  const settlementData = {
    '2024-12-02': {
      정산예정: 119475224,
      정산완료: 82655034,
      정산보류: 25267760,
      상계: 111479467,
      결제액: 63008820,
      정산금지: 86211603
    },
    '2024-12-03': {
      정산예정: 40800970,
      정산완료: 25267760,
      정산보류: 0,
      상계: 63008820,
      결제액: 44999700,
      정산금지: 30234897
    },
    '2024-12-04': {
      정산예정: 39201400,
      정산완료: 22526760,
      정산보류: 0,
      상계: 63269699,
      결제액: 44327829,
      정산금지: 0
    }
  };

  const formatAmount = (amount) => {
    if (amount >= 100000000) {
      return `${(amount / 100000000).toFixed(1)}억`;
    }
    return `${(amount / 10000).toFixed(0)}만`;
  };

  const CustomToolbar = (toolbar) => (
    <div className="calendar-toolbar">
      <div className="toolbar-left">
        <button 
          onClick={() => toolbar.onNavigate('TODAY')}
          className="toolbar-btn today-btn"
        >
          Today
        </button>
        <div className="nav-buttons">
          <button onClick={() => toolbar.onNavigate('PREV')}>
            <ChevronLeftIcon />
          </button>
          <button onClick={() => toolbar.onNavigate('NEXT')}>
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      <h2 className="toolbar-title">{moment(toolbar.date).format('MMMM YYYY')}</h2>
      <div className="toolbar-right">
        <button 
          onClick={() => setView('month')} 
          className={`toolbar-btn ${view === 'month' ? 'active' : ''}`}
        >
          Month
        </button>
        <button 
          onClick={() => setView('week')} 
          className={`toolbar-btn ${view === 'week' ? 'active' : ''}`}
        >
          Week
        </button>
        <button 
          onClick={() => setView('day')} 
          className={`toolbar-btn ${view === 'day' ? 'active' : ''}`}
        >
          Day
        </button>
        <button 
          onClick={() => setView('agenda')} 
          className={`toolbar-btn ${view === 'agenda' ? 'active' : ''}`}
        >
          Agenda
        </button>
      </div>
    </div>
  );

  const CustomDateCell = ({ date }) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const data = settlementData[formattedDate];
    const isWeekend = moment(date).day() === 0 || moment(date).day() === 6;
    
    return (
      <div className="custom-date-cell">
        <div className={`date-number ${isWeekend ? 'weekend' : ''}`}>
          {moment(date).format('D')}
        </div>
        {data && (
          <div className="settlement-grid">
            {data.정산예정 > 0 && (
              <div className="grid-item primary">
                <span className="label">예정</span>
                <span className="amount">{formatAmount(data.정산예정)}</span>
              </div>
            )}
            {data.정산완료 > 0 && (
              <div className="grid-item success">
                <span className="label">완료</span>
                <span className="amount">{formatAmount(data.정산완료)}</span>
              </div>
            )}
            {data.정산보류 > 0 && (
              <div className="grid-item info">
                <span className="label">보류</span>
                <span className="amount">{formatAmount(data.정산보류)}</span>
              </div>
            )}
            {data.상계 > 0 && (
              <div className="grid-item warning">
                <span className="label">상계</span>
                <span className="amount">{formatAmount(data.상계)}</span>
              </div>
            )}
            {data.결제액 > 0 && (
              <div className="grid-item secondary">
                <span className="label">결제</span>
                <span className="amount">{formatAmount(data.결제액)}</span>
              </div>
            )}
            {data.정산금지 > 0 && (
              <div className="grid-item danger">
                <span className="label">금지</span>
                <span className="amount">{formatAmount(data.정산금지)}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const LegendItem = ({ color, label }) => (
    <div className="legend-item">
      <span className="legend-dot" style={{ backgroundColor: color }}></span>
      <span className="legend-label">{label}</span>
    </div>
  );

  return (
    <div className="calendar-container">
      <div className="calendar-legend">
        <LegendItem color={COLORS.primary} label="정산예정" />
        <LegendItem color={COLORS.success} label="정산완료" />
        <LegendItem color={COLORS.info} label="정산보류" />
        <LegendItem color={COLORS.warning} label="상계" />
        <LegendItem color="#666" label="결제액" />
        <LegendItem color={COLORS.danger} label="정산금지" />
      </div>
      <Card className="calendar-card">
        <CardBody>
          <ReactBigCalendar
            localizer={localizer}
            events={events}
            defaultView="month"
            view={view}
            onView={setView}
            date={currentDate}
            onNavigate={date => setCurrentDate(date)}
            components={{
              toolbar: CustomToolbar,
            }}
            eventPropGetter={(event) => ({
              className: `event-${event.color}`
            })}
            formats={{
              monthHeaderFormat: 'YYYY년 MM월',
              weekdayFormat: 'ddd',
              dayFormat: 'D'
            }}
            style={{ height: 'calc(100vh - 240px)' }}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default SettlementCalendar;