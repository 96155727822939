import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';
import DownloadIcon from '@mui/icons-material/Download';
import DateRangePicker from '../../Common/DateRangePicker';
import SummaryData from '../../Common/SummaryData';
import Notice from '../../Notice/Notice';
import styled from '@emotion/styled';

// 스타일드 컴포넌트
const StyledTableCell = styled(TableCell)({
  padding: '12px 16px',
  borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
  '&.header': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#1976d2'
  }
});

const ActionButton = styled(IconButton)({
  width: '40px',
  height: '40px',
  backgroundColor: '#fff',
  marginRight: '8px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
});

// ErrorLog에서 가져온 모바일 관련 스타일 추가
const MobileTableRow = styled(TableRow)({
  '& td': {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px',
    whiteSpace: 'nowrap',
    '& > *': {
      marginRight: '8px'
    }
  }
});

const MobileTableCell = styled(TableCell)({
  padding: '12px 16px',
  whiteSpace: 'nowrap',
  '&.header': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    color: '#1976d2'
  }
});

// 모바일 테이블 스타일 수정
const columnStyles = {
  no: { minWidth: '40px', marginRight: '16px' },
  pg: { minWidth: '80px', marginRight: '16px' },
  merchantNo: { minWidth: '120px', marginRight: '16px' },
  mid: { minWidth: '100px', marginRight: '16px' },
  tmnId: { minWidth: '100px', marginRight: '16px' },
  merchantName: { minWidth: '120px', marginRight: '16px' },
  transactionTime: { minWidth: '150px', marginRight: '16px' },
  amount: { minWidth: '100px', marginRight: '16px' },
  cardNo: { minWidth: '120px', marginRight: '16px' },
  approvalNo: { minWidth: '100px', marginRight: '16px' },
  status: { minWidth: '80px', marginRight: '16px' }
};

// 금액 포맷팅 함수 추가
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// 카드번호 마스킹 함수 수정
const maskCardNumber = (cardNumber) => {
  // 하이픈 제거 후 마스킹 처리
  const numbers = cardNumber.replace(/-/g, '');
  const maskedNumber = numbers.replace(/(\d{6})(\d{6})(\d{4})/, '$1******$3');
  
  // 4자리마다 하이픈 추가
  return maskedNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
};

function DuplicateCheck() {
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 800 && window.innerWidth <= 1025);
  const [isCompact, setIsCompact] = useState(window.innerWidth <= 1025);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
      setIsTablet(window.innerWidth >= 800 && window.innerWidth <= 1025);
      setIsCompact(window.innerWidth <= 1025);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 더미 데이터
  const rows = [
    {
      id: 1,
      no: 290,
      pg: 'Galaxia',
      merchantNo: '760827-1168316',
      mid: 'M2140389',
      tmnId: '104695803',
      merchantName: '레드피그',
      transactionTime: '2024-12-06 16:08:31',
      amount: '20000',
      cardNo: '4370-3339-6896-1845',
      approvalNo: '30081967',
      status: '승인'
    },
    // ... 많은 더미 데이터
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box sx={{ p: 3 }}>
      <SummaryData />
      
      <Box sx={{ flex: 1, mx: { xs: '10px', sm: '90px' } }}>
        <Box sx={{ mb: 3 }}>
          <Notice />
        </Box>

        <Paper sx={{ p: 3, borderRadius: '12px' }}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              mb: 2,
              alignItems: isMobile ? 'flex-start' : 'center',
              justifyContent: 'space-between'
            }}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
              />
              
              <Box sx={{ 
                display: 'flex', 
                gap: 1, 
                alignItems: 'center',
                mt: isMobile ? 1 : 0
              }}>
                <Tooltip title="Copy">
                  <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
                    <FontAwesomeIcon icon={faCopy} />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="Excel">
                  <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
                    <DownloadIcon />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="Print">
                  <ActionButton sx={{ borderColor: '#1976d2', color: '#1976d2' }}>
                    <FontAwesomeIcon icon={faPrint} />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="엑셀 다운로드">
                  <ActionButton sx={{ 
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: '1px solid #2e7d32',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#2e7d3210',
                    }
                  }}>
                    <DownloadIcon />
                  </ActionButton>
                </Tooltip>
              </Box>
            </Box>

            <TableContainer 
              sx={{
                overflowX: 'auto',
                '& .MuiTable-root': {
                  minWidth: '800px'
                },
                ...(isCompact && {
                  '& .MuiTableCell-root': {
                    whiteSpace: 'nowrap',
                    minWidth: {
                      no: '60px',
                      pg: '80px',
                      merchantNo: '120px',
                      mid: '100px',
                      tmnId: '100px',
                      merchantName: '120px',
                      transactionTime: '150px',
                      amount: '100px',
                      cardNo: '140px',
                      approvalNo: '100px',
                      status: '80px'
                    }
                  }
                })
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="header" sx={{ minWidth: '60px' }}>No</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '80px' }}>PG</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '120px' }}>사업자번호</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '100px' }}>MID</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '100px' }}>TMNID</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '120px' }}>가맹점명</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '150px' }}>거래일시</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '100px' }}>금액</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '140px' }}>카드번호</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '100px' }}>승인번호</StyledTableCell>
                    <StyledTableCell className="header" sx={{ minWidth: '80px' }}>취소여부</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id} hover>
                      <StyledTableCell sx={{ minWidth: '60px' }}>{row.no}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '80px' }}>{row.pg}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '120px' }}>{row.merchantNo}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '100px' }}>{row.mid}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '100px' }}>{row.tmnId}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '120px' }}>{row.merchantName}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '150px' }}>{row.transactionTime}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '100px', textAlign: 'right' }}>{formatNumber(row.amount)}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '140px' }}>{maskCardNumber(row.cardNo)}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '100px' }}>{row.approvalNo}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: '80px' }}>{row.status}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default DuplicateCheck;
