import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Paper, 
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Drawer,
  IconButton
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CancelIcon from '@mui/icons-material/Cancel';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// 로컬 컴포넌트 import
import Notice from '../../Notice/Notice';
import PaymentHistoryTable from './PaymentHistoryTable';
import SummaryTable from './SummaryTable';
import CancelOptions from '../../Common/CancelOptions';
import MerchantIcons from '../../Common/MerchantIcons';
import Breadcrumb from '../../Common/Breadcrumb';
import DownloadButtons from '../../Common/DownloadButtons';
import SummaryData from '../../Common/SummaryData';
import SearchOptions from '../../Common/SearchOptions';
import ResultText from '../../ResultText';
import ResultTable from '../../ResultTable';
import ReceiptModal from '../../Common/receiptModal';
import RMquestion from '../../Common/RMquestion';

// 스타일 import
const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '50px',
    pr: '280px',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },

  closeButton: {
    position: 'absolute',
    left: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  sideOptions: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    borderRadius: '12px 0 0 12px',
    boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
    padding: '15px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  sideButton: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
    width: '20px',
    height: '40px',
    borderRadius: '4px 0 0 4px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '@media (max-width: 768px)': {
      width: '30px',
      height: '50px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)', 
    margin: '0 auto', 
    mb: 2 
  },
  searchPaper: { 
    width: 'calc(100% - 20px)',
    margin: '0 auto',
    p: 2,
    mb: 2,
    overflowX: 'auto',
    borderRadius: '12px',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.05)',
    border: '1px solid #edf2f9',
    '@media (max-width: 768px)': {
      width: '100%'
    }
  },
  searchContainer: { 
    minWidth: { xs: '100%', md: '1500px' }
  },
  searchField: {
    fontSize: '0.8rem'
  },
  formControl: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      backgroundColor: '#fff',
      '&:hover fieldset': {
        borderColor: '#51cbce'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#51cbce'
      }
    }
  },
  inputLabel: { 
    fontSize: '0.8rem',
    backgroundColor: 'white',
    px: 0.5,
    color: '#9A9A9A',
    '&.Mui-focused': {
      color: '#51cbce'
    }
  },
  select: { 
    fontSize: '0.8rem',
    '& .MuiSelect-select': {
      pt: 1,
      pb: 0.5
    }
  },
  button: {
    textTransform: 'none',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0 3px 8px rgba(81, 203, 206, 0.1)'
    }
  },
  checkbox: {
    '& .MuiCheckbox-root': {
      color: '#9A9A9A',
      '&.Mui-checked': {
        color: '#51cbce'
      }
    }
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '320px',
      boxSizing: 'border-box',
      mt: '64px',
      height: 'calc(100% - 64px)',
      border: 'none',
      backgroundColor: 'transparent',
      '@media (max-width: 768px)': {
        width: '100%',
        mt: '56px',
        height: 'calc(100% - 56px)'
      }
    }
  }
};

function PaymentHistory() {
  // 날짜 상태
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  
  // 검색 옵션 상태
  const [dateSearchType, setDateSearchType] = useState('');
  const [pgType, setPgType] = useState('');
  const [excludePg, setExcludePg] = useState(false);
  const [storeMid, setStoreMid] = useState('');
  const [walletType, setWalletType] = useState('');
  const [settlementCycle, setSettlementCycle] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [masterSearch, setMasterSearch] = useState('');
  const [branchSearch, setBranchSearch] = useState('');
  const [totalSearch, setTotalSearch] = useState('');
  const [agencySearch, setAgencySearch] = useState('');
  const [retailSearch, setRetailSearch] = useState('');
  const [sales1Search, setSales1Search] = useState('');
  const [sales2Search, setSales2Search] = useState('');
  const [sales3Search, setSales3Search] = useState('');
  const [depositCheck, setDepositCheck] = useState('');
  const [merchantSearch, setMerchantSearch] = useState('');
  const [regularPayment, setRegularPayment] = useState('');
  const [duplicateCheck, setDuplicateCheck] = useState('');
  const [customerInfo, setCustomerInfo] = useState('');
  const [mid, setMid] = useState('');
  const [tid, setTid] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [productName, setProductName] = useState('');
  const [approvalNo, setApprovalNo] = useState('');
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [authType, setAuthType] = useState('');
  const [taxType, setTaxType] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [installmentType, setInstallmentType] = useState('');
  const [cancelStatus, setCancelStatus] = useState('');
  const [highAmountSort, setHighAmountSort] = useState('');
  const [excelDownload, setExcelDownload] = useState('');
  const [individualSettlement, setIndividualSettlement] = useState('');
  const [settlementTotal, setSettlementTotal] = useState('');
  const [individualSales, setIndividualSales] = useState('');
  const [salesTotal, setSalesTotal] = useState('');
  const [reserveTotal, setReserveTotal] = useState('');
  const [cancelType, setCancelType] = useState('');
  const [depositType, setDepositType] = useState('');
  const [searchType, setSearchType] = useState('');

  // 새로운 상태 추가
  const [selectedTab, setSelectedTab] = useState('payment');

  // 취소 옵션 섹션에 대한 ref 생성
  const cancelOptionRef = useRef(null);
  
  // 선택된 승인번호들을 관리하는 상태 추가
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  
  // 취소 옵션 버튼 클릭 핸들러
  const handleCancelOption = (optionType, selectedItems) => {
    if (optionType === 'RM') {
      if (selectedItems.length === 0) {
        alert('선택된 항목이 없습니다.');
        return;
      }
      if (selectedItems.length > 1) {
        alert('RM 문의는 한 건만 선택 가능합니다.');
        return;
      }

      // 단일 선택된 경우에만 모달 열기
      setSelectedMerchantData({
        merchantName: '선택된 가맹점',
        loginId: 'm20230926',
        phone: '010-4117-5805',
        mobile: '010-4117-5805',
        address: '서울 서대문구 신촌로 25',
        branchName: '에픽하우스',
        branchLoginId: 'b19006',
        branchPhone: '010-5500-5487',
        branchMobile: '010-5500-5487',
        branchAddress: '서울 동작구 동작대로27다길 29 2층'
      });
      setIsRMModalOpen(true);
    }
    // 다른 옵션들(강제취소, 상계)에 대한 처리...
  };

  // 가맹점 아이콘 버튼 클릭 핸들러
  const handleMerchantIconClick = (iconType) => {
    console.log(`선택된 아이콘:`, iconType);
    // 여기에 나중에 각 아이콘별 처리 로직이 들어갈 예정
  };

  // 체크박스 선택 시 실행될 핸들러
  const handleCheckboxChange = (checked, approvalNo) => {
    if (checked) {
      setSelectedApprovals(prev => [...prev, approvalNo]);
      // 취소 옵션 섹션으로 스크롤
      const cancelOptionsElement = document.querySelector('.cancel-options-section');
      if (cancelOptionsElement) {
        cancelOptionsElement.scrollIntoView({ 
          behavior: 'smooth',
          block: 'center'
        });
      }
    } else {
      setSelectedApprovals(prev => prev.filter(no => no !== approvalNo));
    }
  };

  // 날짜 검색 핸들러
  const handleDateSearch = (value) => {
    const today = new Date();
    let start = new Date();
    
    switch(value) {
      case '1': // 오늘
        setStartDate(today.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '2': // 어제
        start.setDate(today.getDate() - 1);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(start.toISOString().split('T')[0]);
        break;
      case '3': // 지난7일
        start.setDate(today.getDate() - 7);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '5': // 이번달
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      case '13': // 지난3개월
        start = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        setStartDate(start.toISOString().split('T')[0]);
        setEndDate(today.toISOString().split('T')[0]);
        break;
      default:
        break;
    }
    setDateSearchType(value);
  };

  // 검색 효과 추가
  useEffect(() => {
    // 검색 조건이 변경될 마다 색 실행
    const handleSearch = () => {
      // 실제로는 API 호나 데이터 필터링이 들어갈 자리
      console.log('Search with:', {
        dateSearchType,
        pgType,
        storeMid,
        walletType,
        settlementCycle,
        // ... 기타 검색 조건들
      });
    };

    handleSearch();
  }, [
    dateSearchType,
    pgType,
    storeMid,
    walletType,
    settlementCycle,
    // ... 검색에 사용될 state들
  ]);

  // 다운로드 버튼 부분 수정
  const downloadButtons = [
    { label: '개별정산', value: 'individual', color: '#2196f3' },
    { label: '정산합계', value: 'settlement', color: '#4caf50' },
    { label: '개별영업', value: 'sales', color: '#ff9800' },
    { label: '영업합계', value: 'salesTotal', color: '#f44336' },
    { label: '유보합계', value: 'reserve', color: '#9c27b0' },
  ];

  // 상단 새로운 state 추가
  const [rowCount, setRowCount] = useState(''); // 데이터개수를 위한 별도 state

  // SummaryTable용 더미 데이터
  const summaryData = {
    basicStats: {
      dealerCount: 150,
      transactionCount: 1000,
      approvalCount: 900,
      cancelCount: 100,
      netCount: 800,
      periodCancelCount: 50,
      returnCount: 20,
      netCancelCount: 30,
      approvalAmount: 10000000,
      cancelAmount: 1000000,
      totalAmount: 9000000,
      periodCancelAmount: 500000,
      outstandingAmount: 100000,
      returnAmount: 200000,
      netCancelAmount: 300000,
      rmCount: 5,
      totalFee: 500000,
      totalDeposit: 1000000,
      totalSettlement: 8500000,
      totalWalletWithdrawal: 2000000,
      totalAccountSettlement: 6500000,
      totalMasterPayment: 1500000,
      totalSeniorPayment: -500000,
      totalDeduction: 300000,
      aynilApprovalTotal: 8827000,
      aynilCancelTotal: 621700,
      aynilDeductionTotal: 0,
      aynilDepositTotal: 8205300,
      aynilTotal: 10189250
    },
    accountPayments: {
      total: 6500000,
      master: 1500000,
      company: 1000000,
      distributor: 1000000,
      agency: 800000,
      retail: 800000,
      sales1: 500000,
      sales2: 500000,
      sales3: 400000
    },
    accountPoints: {
      total: 1000000,
      master: 200000,
      company: 150000,
      distributor: 150000,
      agency: 100000,
      retail: 100000,
      sales1: 100000,
      sales2: 100000,
      sales3: 100000
    },
    accountSavings: {
      total: 2000000,
      master: 400000,
      company: 300000,
      distributor: 300000,
      agency: 250000,
      retail: 250000,
      sales1: 200000,
      sales2: 200000,
      sales3: 100000
    },
    settlementDetails: {
      totalFee: 5000000,
      supplyPrice: 4545455,
      vat: 454545,
      totalDeposit: 1000000,
      totalDeduction: 6000000,
      difference: 4000000,
      totalAccountSettlement: 3500000,
      totalMasterPayment: 1500000,
      totalSeniorPayment: -500000,
      merchantSettlement: 8000000,
      walletWithdrawal: 2000000,
      totalSettlement: 10000000,
      retention: 1500000,
      totalOriginalDeduction: 500000,
      retentionPayment: -200000,
      totalDeposited: 9800000,
      merchantPoints: 100000,
      pointRate: 1.5,
      totalAdminPayment: 300000
    }
  };

  // 사이드 옵션 핸들러
  const handleSideOptionClick = (action) => {
    switch(action) {
      case 'summary':
        // 하단 합계표로 스크롤
        const summaryElement = document.querySelector('.summary-table');
        if (summaryElement) {
          summaryElement.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'pg':
        // PG사 관련 액션
        console.log('PG사 옵션 클릭');
        break;
      // ... 필요한 다른 액션들
    }
  };

  // 검색 핸들러
  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
    // 검색 로직 구현
  };

  // searchPanelOpen의 초기값을 true로 설정
  const [searchPanelOpen, setSearchPanelOpen] = useState(true);

  // 화면 크기 감지를 위한 상태 추가
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
      // 모바일에서 데스크톱으로 전환시 서치패널 자동으로 열기
      if (window.innerWidth >= 960) {
        setSearchPanelOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 동적 스타일 계산 수정
  const mainBoxStyle = {
    ...styles.mainBox,
    pr: {
      xs: '10px',
      md: searchPanelOpen ? '280px' : '0px'
    }
  };

  // 영수증 모달 상태 추가
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);
  const [receiptData, setReceiptData] = useState(null);

  // 영수증 아이콘 클릭 핸들러
  const handleReceiptClick = (rowData) => {
    // rowData에서 필요한 데이터 매핑
    const receiptInfo = {
      paymentType: rowData.paymentType,
      cardType: rowData.cardCompany,
      transactionDate: rowData.transactionDate,
      cardNumber: rowData.cardNumber,
      installment: rowData.installment,
      approvalNo: rowData.approvalNo,
      taxAmount: rowData.amount,
      vat: rowData.fee,
      totalAmount: rowData.paymentAmount,
      storeName: rowData.merchantName,
      businessNo: rowData.mid,
      ownerName: '대표자명',  // 데이터에 있다면 매핑
      phoneNumber: rowData.phoneNumber,
      address: '가맹점 주소'  // 데이터에 있다면 매핑
    };

    setReceiptData(receiptInfo);
    setIsReceiptOpen(true);
  };

  const [isRMModalOpen, setIsRMModalOpen] = useState(false);
  const [selectedMerchantData, setSelectedMerchantData] = useState(null);

  return (
    <Box component="main" sx={mainBoxStyle}>
      {/* 패널 열기 버튼 - 패널이 닫혔을 때만 표시 */}
      {!searchPanelOpen && (
        <IconButton
          onClick={() => setSearchPanelOpen(true)}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#fff',
            boxShadow: '-4px 0 8px rgba(0,0,0,0.1)',
            width: '20px',
            height: '40px',
            borderRadius: '4px 0 0 4px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {/* SearchOptions Drawer 수정 */}
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={searchPanelOpen}
        onClose={() => setSearchPanelOpen(false)}
        variant={isMobile ? 'temporary' : 'persistent'}
        sx={styles.drawer}
      >
        <SearchOptions 
          onSearch={handleSearch} 
          onClose={() => setSearchPanelOpen(false)}
          isMobile={isMobile}
        />
      </Drawer>

      {/* Notice 영역 */}
      <Box sx={styles.noticeBox}>
        <Notice />
      </Box>

      {/* 테이블과 버튼 사이에 추가 여백을 위한 Box */}
      <Box sx={{ mb: 2 }} />

      {/* ResultText 추가 */}
      <ResultText />

      {/* ResultTable 추가 */}
      <ResultTable />

      {/* 테이블 부분만 컴포넌트로 교체 */}
      <PaymentHistoryTable 
        onCheckboxChange={handleCheckboxChange}
        selectedApprovals={selectedApprovals}
        onReceiptClick={handleReceiptClick}
        onCancelOption={handleCancelOption}
      />
      
      {/* 하단 합계 표 추가 */}
      <SummaryTable summaryData={summaryData} />

      {/* 마지막에 SummaryData 추가 */}
      <SummaryData />

      {/* 영수증 모달 추가 */}
      <ReceiptModal
        open={isReceiptOpen}
        onClose={() => setIsReceiptOpen(false)}
        receiptData={receiptData}
      />

      <CancelOptions 
        onCancelOption={handleCancelOption}
        selectedItems={selectedApprovals}
      />

      <RMquestion
        open={isRMModalOpen}
        onClose={() => setIsRMModalOpen(false)}
        data={selectedMerchantData}
      />
    </Box>
  );
}

export default PaymentHistory;
