import React from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Checkbox 
} from '@mui/material';
import styled from '@emotion/styled';
import SettlementButton from '../../Common/SettlementButton';

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  borderBottom: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: color || '#495057',
}));

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9', // 변경: #fff -> #f9f9f9
    width: '100%',
    maxWidth: '1800px', 
    padding: '0px', // 여백을 0으로 설정
    '@media (max-width: 600px)': {
      maxHeight: 'calc(100vh - 150px)',
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 600px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  }
};

const tableHeaders = [
  { id: 'checkbox', label: '체크박스', color: '#bbdefb' },
  { id: 'no', label: 'NO', color: '#bbdefb' },
  { id: 'pg', label: 'PG', color: '#bbdefb' },
  { id: 'accountInfo', label: '계정정보', color: '#bbdefb' },
  { id: 'detail', label: '상세팝업', color: '#bbdefb' },
  { id: 'walletStatus', label: '월렛여부', color: '#2196f3' },
  { id: 'settlementPossible', label: '정산가능', color: '#2196f3' },
  { id: 'merchantTID', label: '가맹점TID', color: '#2196f3' },
  { id: 'merchantMID', label: '가맹점 MID', color: '#2196f3' },
  { id: 'merchantName', label: '가맹점명', color: '#2196f3' },
  { id: 'transactionDate', label: '결제일시', color: '#c8e6c9' },
  { id: 'settlementCycle', label: '정산주기', color: '#c8e6c9' },
  { id: 'expectedSettlementDate', label: '정산예정일', color: '#c8e6c9' },
  { id: 'approvalCount', label: '승인건수', color: '#c8e6c9' },
  { id: 'cancelCount', label: '취소건수', color: '#c8e6c9' },
  { id: 'approvalAmount', label: '승인금액', color: '#c8e6c9' },
  { id: 'cancelAmount', label: '취소금액', color: '#c8e6c9' },
  { id: 'detailCount', label: '세부내역', color: '#c8e6c9' },
  { id: 'actualSalesAmount', label: '실매출금액', color: '#ffffff' },
  { id: 'settlementAmount', label: '정산액', color: '#ffffff' },
  { id: 'merchantCommissionRate', label: '가맹점수수료율', color: '#ffffff' },
  { id: 'commission', label: '수수료', color: '#ffffff' },
  { id: 'rmCount', label: 'RM건수', color: '#ffffff' },
  { id: 'unpaidAmount', label: '미수금액', color: '#ffffff' },
  { id: 'retentionRate', label: '유보율', color: '#ffffff' },
  { id: 'paymentRate', label: '지급률', color: '#ffffff' },
  { id: 'depositRate', label: '보증금률', color: '#ffffff' },
];

const dummyData = [
  {
    no: 1,
    pg: 'KICC',
    accountInfo: 'Account 1',
    detail: '상세보기',
    walletStatus: 'Yes',
    settlementPossible: 'Yes',
    merchantTID: 'TID123',
    merchantMID: 'MID123',
    merchantName: 'Merchant 1',
    transactionDate: '2024-01-01',
    settlementCycle: 'D+1',
    expectedSettlementDate: '2024-01-02',
    approvalCount: 5,
    cancelCount: 1,
    approvalAmount: 500000,
    cancelAmount: -10000,
    detailCount: 1,
    actualSalesAmount: 490000,
    settlementAmount: 480000,
    merchantCommissionRate: '1.5%',
    commission: 7200,
    rmCount: 0,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
  {
    no: 2,
    pg: 'PayUp',
    accountInfo: 'Account 2',
    detail: '상세보기',
    walletStatus: 'No',
    settlementPossible: 'No',
    merchantTID: 'TID456',
    merchantMID: 'MID456',
    merchantName: 'Merchant 2',
    transactionDate: '2024-01-02',
    settlementCycle: 'D+2',
    expectedSettlementDate: '2024-01-04',
    approvalCount: 3,
    cancelCount: 0,
    approvalAmount: 300000,
    cancelAmount: 0,
    detailCount: 0,
    actualSalesAmount: 300000,
    settlementAmount: 300000,
    merchantCommissionRate: '2%',
    commission: 6000,
    rmCount: 1,
    unpaidAmount: 0,
    retentionRate: '0%',
    paymentRate: '100%',
    depositRate: '0%',
  },
];

function ScheduledOffsetTable() {
  return (
    <Box>
      <Box mb={2}>
        <SettlementButton />
      </Box>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  align={header.id === 'checkbox' ? 'center' : 'left'}
                  style={{ 
                    backgroundColor: header.color,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <TableRow key={row.no}>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={`${row.no}-${header.id}`}
                    align={header.id === 'checkbox' ? 'center' : 'left'}
                  >
                    {header.id === 'checkbox' ? (
                      <Checkbox />
                    ) : (
                      row[header.id]
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ScheduledOffsetTable;
