import React from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const UserNotices = () => {
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [notices, setNotices] = React.useState([
    {
      action: '변경',
      group: '결제사이트',
      fixed: 1,
      public: 1,
      title: '고객센터 업무시간 안내',
      content: '업무시간 09:30~17:30\n(점심 12:00~13:00) 토,일 공휴일 휴무\n1566-9805 로 연락주시기 바랍니다.',
      date: '2022-07-20'
    },
    {
      action: '추가',
      group: '메인사이트',
      fixed: 0,
      public: 1,
      title: '시스템 점검 안내',
      content: '2024년 3월 15일 02:00~06:00 시스템 점검이 진행됩니다. 이용에 참고 부탁드립니다.',
      date: '2024-03-14'
    },
    {
      action: '삭제',
      group: '결제사이트',
      fixed: 1,
      public: 0,
      title: '결제 시스템 업데이트',
      content: '결제 시스템 2.0 버전 업데이트 진행 예정입니다. 자세한 내용은 공지사항을 참고해주세요.',
      date: '2024-03-13'
    },
    {
      action: '변경',
      group: '관리자사이트',
      fixed: 0,
      public: 1,
      title: '신규 서비스 오픈',
      content: '간편결제 서비스가 추가되었습니다. 결제 방식 추가 및 기능 개선이 있었습니다.',
      date: '2024-03-12'
    },
    {
      action: '추가',
      group: '메인사이트',
      fixed: 1,
      public: 1,
      title: '휴면계정 전환 안내',
      content: '1년 이상 미접속 계정은 휴면상태로 전환됩니다. 로그인 시 본인인증이 필요합니다.',
      date: '2024-03-10'
    },
    {
      action: '변경',
      group: '결제사이트',
      fixed: 0,
      public: 1,
      title: '카드결제 서비스 점검',
      content: '카드결제 서비스 점검으로 인해 3월 20일 새벽 2시부터 4시까지 일시 중단됩니다.',
      date: '2024-03-09'
    },
    {
      action: '추가',
      group: '관리자사이트',
      fixed: 1,
      public: 1,
      title: '관리자 페이지 개편',
      content: '관리자 페이지 UI/UX 개선 작업이 완료되었습니다. 새로운 기능이 추가되었습니다.',
      date: '2024-03-08'
    },
    {
      action: '삭제',
      group: '메인사이트',
      fixed: 0,
      public: 0,
      title: '이벤트 종료 안내',
      content: '3월 신규가입 이벤트가 종료되었습니다. 많은 참여 감사드립니다.',
      date: '2024-03-07'
    },
    {
      action: '변경',
      group: '결제사이트',
      fixed: 1,
      public: 1,
      title: '보안 업데이트 안내',
      content: 'SSL 인증서 갱신 및 보안 강화 작업이 진행되었습니다. 더욱 안전한 서비스를 제공하겠습니다.',
      date: '2024-03-06'
    },
    {
      action: '추가',
      group: '메인사이트',
      fixed: 0,
      public: 1,
      title: '모바일 앱 출시',
      content: '신규 모바일 앱이 출시되었습니다. 앱스토어에서 다운로드 가능합니다.',
      date: '2024-03-05'
    }
  ]);

  const handleSortClick = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    
    const sortedNotices = [...notices].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return newOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    
    setNotices(sortedNotices);
  };

  return (
    <Card sx={{ 
      mt: 4, 
      borderRadius: '12px', 
      boxShadow: '0 6px 10px -4px rgba(0,0,0,0.15)',
      height: 'calc(100vh - 280px)'  // 전체 높이 설정
    }}>
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <NotificationsActiveIcon sx={{ color: '#1976d2' }} />
          <Typography variant="h6" sx={{ color: '#252631', fontWeight: 600 }}>
            유저 공지사항
          </Typography>
        </Box>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />} 
          sx={{ 
            backgroundColor: '#4a90e2',
            '&:hover': {
              backgroundColor: '#357abd'
            },
            boxShadow: '0 2px 4px rgba(74, 144, 226, 0.3)',
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          공지추가
        </Button>
      </Box>
      <TableContainer sx={{ 
        height: 'calc(100vh - 380px)',  // 테이블 컨테이너 높이
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
          '&:hover': {
            background: '#666'
          }
        }
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>행동</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>그룹</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>고정여부</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>공개/비공개</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>제목</TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>내용</TableCell>
              <TableCell 
                sx={{ 
                  backgroundColor: '#f7f7f7', 
                  fontWeight: 600, 
                  color: '#252631',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#eaeaea'
                  }
                }}
                onClick={handleSortClick}
              >
                등록시간
                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
              </TableCell>
              <TableCell sx={{ backgroundColor: '#f7f7f7', fontWeight: 600, color: '#252631' }}>작업</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notices.map((notice, index) => (
              <TableRow 
                key={index}
                sx={{ 
                  '&:hover': { backgroundColor: '#f5f5f5' },
                  transition: 'background-color 0.2s'
                }}
              >
                <TableCell>{notice.action}</TableCell>
                <TableCell>{notice.group}</TableCell>
                <TableCell>{notice.fixed ? '고정' : '일반'}</TableCell>
                <TableCell>{notice.public ? '공개' : '비공개'}</TableCell>
                <TableCell>{notice.title}</TableCell>
                <TableCell sx={{ fontSize: '0.8rem', maxWidth: '300px' }}>{notice.content}</TableCell>
                <TableCell>{notice.date}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="보기">
                      <IconButton size="small" sx={{ color: '#51cbce' }}>
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="수정">
                      <IconButton size="small" sx={{ color: '#fbc658' }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="삭제">
                      <IconButton size="small" sx={{ color: '#ef8157' }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default UserNotices;
