import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField
} from '@mui/material';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import DownloadIcon from '@mui/icons-material/Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint } from '@fortawesome/free-solid-svg-icons';

const ServerModule = () => {
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');
  const [searchText, setSearchText] = useState('');

  // 더미 데이터
  const rows = [
    {
      no: 172,
      type: 'update',
      module: 'p_balance_division',
      description: '포인트 잔액을 분할하여 업데이트합니다.'
    },
    {
      no: 171,
      type: 'update',
      module: 'balance_reject',
      description: '잔액 거부 상태를 업데이트합니다.'
    },
    {
      no: 170,
      type: 'update',
      module: 'shop_delete',
      description: '상점 데이터를 삭제합니다.'
    },
    // ... 더 많은 더미 데이터 추가 가능
  ];

  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2'
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32'
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2'
    }
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = React.useMemo(() => {
    const comparator = (a, b) => {
      if (order === 'asc') {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    };
    return [...rows].sort(comparator);
  }, [rows, order, orderBy]);

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3}>
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IntegrationInstructionsIcon sx={{ color: '#00acc1' }} />
            <Typography variant="h6">서버 모듈 정보</Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            mb: 2,
            alignItems: 'center'
          }}>
            <TextField
              size="small"
              placeholder="검색어를 입력하세요"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ 
                minWidth: '200px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  height: '40px'
                }
              }}
            />
            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.label}>
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'no'}
                      direction={orderBy === 'no' ? order : 'asc'}
                      onClick={() => handleRequestSort('no')}
                    >
                      No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">TYPE</TableCell>
                  <TableCell align="center">MODULE</TableCell>
                  <TableCell align="center">상세내용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row) => (
                  <TableRow key={row.no}>
                    <TableCell align="center">{row.no}</TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell align="center">{row.module}</TableCell>
                    <TableCell>{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default ServerModule;
