import React from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const iconConfig = {
  block: {
    icon: BlockIcon,
    tooltip: '금지된 가맹점',
    color: '#dc3545'
  },
  sms: {
    icon: TextsmsIcon,
    tooltip: '실적문자 발송',
    color: '#4caf50'
  },
  recurring: {
    icon: AutorenewIcon,
    tooltip: '정기결제 가맹점',
    color: '#2196f3'
  }
};

const MerchantStatusIcons = ({ 
  statuses = [], // ['block', 'sms', 'recurring'] 형태로 전달
  size = 'small',
  onClick // 아이콘 클릭 이벤트 핸들러 (선택적)
}) => {
  return (
    <Box sx={{ display: 'inline-flex', gap: 0.5, alignItems: 'center' }}>
      {statuses.map((status) => {
        const config = iconConfig[status];
        if (!config) return null;

        const Icon = config.icon;
        return (
          <Tooltip key={status} title={config.tooltip}>
            <IconButton
              size={size}
              onClick={() => onClick?.(status)}
              sx={{
                padding: size === 'small' ? '2px' : '4px',
                color: config.color,
                '&:hover': {
                  backgroundColor: `${config.color}10`
                }
              }}
            >
              <Icon sx={{ 
                fontSize: size === 'small' ? '1rem' : '1.25rem'
              }} />
            </IconButton>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default MerchantStatusIcons;