import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Grow } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SummarizeIcon from '@mui/icons-material/Summarize';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

function DownloadButtons() {
  const [showOptions, setShowOptions] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const buttons = [
    { 
      label: '엑셀 다운로드', 
      icon: <DownloadIcon />,
      color: '#2e7d32'
    },
    { 
      label: '개별정산', 
      icon: <AccountBalanceWalletIcon />,
      color: '#1976d2'
    },
    { 
      label: '정산합계', 
      icon: <SummarizeIcon />,
      color: '#1976d2'
    },
    { 
      label: '개별영업', 
      icon: <StorefrontIcon />,
      color: '#ed6c02'
    },
    { 
      label: '영업합계', 
      icon: <ReceiptLongIcon />,
      color: '#ed6c02'
    },
    { 
      label: '유보합계', 
      icon: <SaveAltIcon />,
      color: '#9c27b0'
    }
  ];

  return (
    <Box 
      sx={{ 
        width: isMobile ? 'auto' : '260px',
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        flexWrap: 'wrap'
      }}
      onMouseEnter={() => !isMobile && setShowOptions(true)}
      onMouseLeave={() => !isMobile && setShowOptions(false)}
    >
      {isMobile ? (
        // 모바일: 모든 버튼 바로 표시
        buttons.map((button, index) => (
          <Tooltip key={index} title={button.label}>
            <IconButton
              sx={{
                width: index === 0 ? '40px' : '36px',
                height: index === 0 ? '40px' : '36px',
                backgroundColor: '#fff',
                border: `1px solid ${button.color}`,
                color: button.color,
                '&:hover': {
                  backgroundColor: `${button.color}10`,
                }
              }}
            >
              {button.icon}
            </IconButton>
          </Tooltip>
        ))
      ) : (
        // 웹: hover 시 옵션 표시
        <>
          <Tooltip title={buttons[0].label}>
            <IconButton
              sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#fff',
                border: `1px solid ${buttons[0].color}`,
                color: buttons[0].color,
                '&:hover': {
                  backgroundColor: `${buttons[0].color}10`,
                }
              }}
            >
              {buttons[0].icon}
            </IconButton>
          </Tooltip>
          
          {showOptions && buttons.slice(1).map((button, index) => (
            <Grow key={index} in={showOptions}>
              <Tooltip title={button.label}>
                <IconButton
                  sx={{
                    width: '36px',
                    height: '36px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            </Grow>
          ))}
        </>
      )}
    </Box>
  );
}

export default DownloadButtons;