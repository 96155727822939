import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  IconButton,
  Tooltip,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

const DefaultTables = () => {
  const tableHeaders = [
    { 
      id: 'delete', 
      label: (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Tooltip title="내역삭제">
            <IconButton size="small" color="error">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ), 
      color: '#ffebee'
    },
    { 
      id: 'cancel', 
      label: (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Tooltip title="취소승인">
            <IconButton size="small" color="error">
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ), 
      color: '#ffebee'
    },
    
    { id: 'no', label: 'No', color: '#1976d2' },
    { id: 'accountInfo', label: '계정정보', color: '#1976d2' },
    { id: 'status', label: '상태', color: '#1976d2' },
    { id: 'pg', label: 'PG', color: '#1976d2' },
    { id: 'requestDate', label: '요청일시', color: '#1976d2' },
    { id: 'transactionDate', label: '거래일시', color: '#1976d2' },
    { id: 'merchantMID', label: '가맹점 MID', color: '#1976d2' },
    { id: 'merchantTID', label: '가맹점 TID', color: '#1976d2' },
    
    { id: 'accountStatus', label: '계정상태', color: '#90caf9' },
    { id: 'refundAmount', label: '환입금액', color: '#90caf9' },
    { id: 'walletUse', label: '월렛사용여부', color: '#90caf9' },
    { id: 'amount', label: '거래금액', color: '#90caf9' },
    { id: 'approvalNo', label: '승인번호', color: '#90caf9' },
    { id: 'merchantName', label: '가맹점명', color: '#90caf9' },
    { id: 'receipt', label: '영수증', color: '#90caf9' },
    
    { id: 'issuer', label: '발급사', color: '#ffffff' },
    { id: 'cardNumber', label: '카드번호', color: '#ffffff' },
    { id: 'installment', label: '할부', color: '#ffffff' },
    { id: 'feeRate', label: '수수료율/금액', color: '#ffffff' },
    { id: 'deposit', label: '보증금', color: '#ffffff' },
    { id: 'depositAmount', label: '입금액', color: '#ffffff' },
    { id: 'accountBalance', label: '계정잔액', color: '#ffffff' },
    { id: 'deductionAmount', label: '차감금액', color: '#ffffff' },
    { id: 'vanTrxId', label: 'vanTrxidtrxid', color: '#ffffff' }
  ];

  const dummyData = Array(5).fill(null).map((_, index) => ({
    id: index + 1,
    no: (index + 1).toString(),
    accountInfo: `user${123 + index}`,
    status: '취소요청',
    pg: 'KG이니시스',
    requestDate: '2024-03-20 14:30:00',
    transactionDate: '2024-03-20 14:25:00',
    merchantMID: `MID${123456 + index}`,
    merchantTID: `TID${789012 + index}`,
    accountStatus: '정상',
    refundAmount: '50,000',
    walletUse: 'Y',
    amount: '50,000',
    approvalNo: `AP${123456 + index}`,
    merchantName: `테스트가맹점${index + 1}`,
    receipt: 'Y',
    issuer: '신한카드',
    cardNumber: '123456******7890',
    installment: '일시불',
    feeRate: '3.3',
    deposit: '1,000',
    depositAmount: '48,000',
    accountBalance: '100,000',
    deductionAmount: '2,000',
    vanTrxId: `VAN${123456 + index}`
  }));

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell
                key={header.id}
                align="center"
                sx={{
                  backgroundColor: header.color,
                  color: header.color === '#ffffff' ? '#000000' : '#ffffff',
                  fontWeight: 'bold',
                  padding: '12px 16px',
                  whiteSpace: 'nowrap'
                }}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyData.map((row) => (
            <TableRow key={row.id}>
              {tableHeaders.map((header) => (
                <TableCell
                  key={`${row.id}-${header.id}`}
                  align="center"
                  sx={{
                    padding: '8px 16px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {row[header.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DefaultTables;