import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';
import styled from '@emotion/styled';

// 스타일 정의
const StyledTableCell = styled(TableCell)(() => ({
  padding: '12px 8px',
  borderBottom: '1px solid #e9ecef',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  fontSize: '0.813rem',
  '&.header': {
    backgroundColor: '#f8f9fa',
    borderBottom: '2px solid #dee2e6',
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:hover': {
    backgroundColor: '#f8f9fa',
    transition: 'background-color 0.2s ease',
  },
}));

const styles = {
  tableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'auto',
    border: '1px solid #dee2e6',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    width: '100%',
    maxWidth: '1800px',
    padding: '0px',
    '@media (max-width: 1025px)': {
      maxHeight: 'calc(100vh - 250px)',
      '& table': {
        minWidth: '1200px'
      }
    },
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666'
      }
    }
  },
  table: {
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      overflow: 'visible',
      textOverflow: 'clip',
      '@media (max-width: 1025px)': {
        padding: '8px 4px',
        fontSize: '0.75rem'
      }
    }
  },
  blueSection: {
    backgroundColor: '#EBF3FF !important',
  },
  skySection: {
    backgroundColor: '#E3FAFC !important',
  }
};

const ScSummaryTable = ({ startDate, endDate }) => {
  const dummyData = [
    {
      transactionDate: '2024-12-10 00:00:00 ~ 2024-12-11 00:00:00',
      creator: 'admin',
      approvalAmount: 36082940,
      cancelAmount: -2703500,
      offsetAmount: 33379440,
      holdAmount: 0,
      previousHoldAmount: 0,
      settlementCount: 270,
      settlementAmount: 2091667,
      commission: 0,
      depositAmount: 0,
      executionAmount: 0,
      completionDate: '2024-12-10 14:40:20',
      reportCreatorId: 'admin',
    },
    // 추가 데이터...
  ];

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table stickyHeader sx={styles.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell className="header" sx={styles.blueSection}>거래일시</StyledTableCell>
            <StyledTableCell className="header" sx={styles.blueSection}>생성자</StyledTableCell>
            <StyledTableCell className="header" sx={styles.blueSection}>승인금액</StyledTableCell>
            <StyledTableCell className="header" sx={styles.blueSection}>취소금액</StyledTableCell>
            <StyledTableCell className="header" sx={styles.blueSection}>상계금액</StyledTableCell>
            <StyledTableCell className="header" sx={styles.blueSection}>보류금액</StyledTableCell>
            <StyledTableCell className="header" sx={styles.blueSection}>이전보류반영금액</StyledTableCell>
            <StyledTableCell className="header" sx={styles.skySection}>정산대상건수</StyledTableCell>
            <StyledTableCell className="header" sx={styles.skySection}>정산반영금액</StyledTableCell>
            <StyledTableCell className="header" sx={styles.skySection}>수수료(VAT포함)</StyledTableCell>
            <StyledTableCell className="header" sx={styles.skySection}>보증금액</StyledTableCell>
            <StyledTableCell className="header" sx={styles.skySection}>정산집행액</StyledTableCell>
            <StyledTableCell className="header" sx={styles.skySection}>정산완료일시</StyledTableCell>
            <StyledTableCell className="header" sx={styles.skySection}>정산보고생성아이디</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyData.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell sx={styles.blueSection}>{row.transactionDate}</StyledTableCell>
              <StyledTableCell sx={styles.blueSection}>{row.creator}</StyledTableCell>
              <StyledTableCell sx={styles.blueSection}>{row.approvalAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.blueSection}>{row.cancelAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.blueSection}>{row.offsetAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.blueSection}>{row.holdAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.blueSection}>{row.previousHoldAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.skySection}>{row.settlementCount}</StyledTableCell>
              <StyledTableCell sx={styles.skySection}>{row.settlementAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.skySection}>{row.commission.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.skySection}>{row.depositAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.skySection}>{row.executionAmount.toLocaleString()}</StyledTableCell>
              <StyledTableCell sx={styles.skySection}>{row.completionDate}</StyledTableCell>
              <StyledTableCell sx={styles.skySection}>{row.reportCreatorId}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScSummaryTable;