import React, { useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, Checkbox, TablePagination, Box, Button, IconButton, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import MerchantStatusIcons from '../../Common/MerchantStatusIcons';
import CancelOptions from '../../Common/CancelOptions';
import MerchantIcons from '../../Common/MerchantIcons';
import { ReceiptIconButton } from '../../Common/PlusIcons';
import RMquestion from '../../Common/RMquestion';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import UserInfoOne from '../../Common/PayModal/UserInfoOne';
import UserInfoTwo from '../../Common/PayModal/UserInfoTwo';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '6px 8px',
  fontSize: '0.813rem',
  fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
  letterSpacing: '-0.03em',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  whiteSpace: 'nowrap',
  color: '#495057',
  height: '32px',
  
  '&.MuiTableCell-head': {
    color: '#000',
    fontWeight: 600,
    borderBottom: '2px solid #e9ecef',
    textAlign: 'center',
    height: '32px',
    lineHeight: '1.2',
    padding: '6px 8px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    
    '&.primary-header, &.secondary-header': {
      backgroundColor: '#a4c9ef',
      whiteSpace: 'nowrap',
      minWidth: 'max-content',
      padding: '6px 8px'
    },
    '&.secondary-header': {
      backgroundColor: '#e8f3ff'
    }
  },

  '&.amount-cell': {
    fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
    fontFeatureSettings: 'tnum',
    textAlign: 'right',
    color: '#495057',
    fontWeight: 500
  },

  '&.status-cell': {
    textAlign: 'center',
    '& .success': { color: '#2185d0' },
    '& .pending': { color: '#ffa000' },
    '& .fail': { color: '#f44336' }
  },

  '&.date-cell': {
    fontFamily: 'Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif',
    color: '#666',
    fontSize: '0.813rem',
    letterSpacing: 0
  },

  '&:last-child': {
    borderRight: 'none'
  },

  '@media (max-width: 600px)': {
    whiteSpace: 'nowrap',
    overflow: 'visible',
    textOverflow: 'clip',
    minWidth: 'fit-content'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '32px',
  '&:nth-of-type(even)': {
    backgroundColor: '#fafbfc',
  },
  '&:hover': {
    backgroundColor: '#f5f9ff',
    transition: 'background-color 0.2s ease'
  },
  '&:last-child td': {
    borderBottom: 'none'
  }
}));

const StyledTableWrapper = styled(Box)({
  overflow: 'auto',
  position: 'relative',
  '& .sticky-column': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08), transparent)'
    }
  },
  '& .sticky-header': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#a4c9ef',
    zIndex: 3
  }
});

const StyledPagination = styled(TablePagination)({
  '.MuiTablePagination-select': {
    fontSize: '0.813rem'
  },
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.813rem',
    color: '#495057'
  }
});

const IconButtonStyle = {
  padding: '4px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
};

function PaymentHistoryTable({ 
  onCheckboxChange, 
  selectedApprovals, 
  searchPanelOpen,
  onCancelOption,
  onReceiptClick,
  onCardDetailClick,
  onUserDetailClick
}) {
  const [isRMModalOpen, setIsRMModalOpen] = useState(false);
  const [selectedMerchantData, setSelectedMerchantData] = useState(null);
  const [userInfoOneOpen, setUserInfoOneOpen] = useState(false);
  const [userInfoTwoOpen, setUserInfoTwoOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRMClick = (row) => {
    setSelectedMerchantData({
      merchantName: row.merchantName,
      loginId: 'm20230926',
      phone: row.phoneNumber,
      mobile: '010-4117-5805',
      address: '서울 서대문구 신촌로 25',
      branchName: '에픽하우스',
      branchLoginId: 'b19006',
      branchPhone: '010-5500-5487',
      branchMobile: '010-5500-5487',
      branchAddress: '서울 동작구 동작대로27다길 29 2층'
    });
    setIsRMModalOpen(true);
  };

  const formatCardDetailData = (row) => ([
    {
      type: '마스터',
      businessName: row.merchantName,
      feeRate: '1.485',
      feeAmount: 756,
      paymentAmount: 358,
      withholding: '0%',
      pointFee: '0',
      rewardFee: '0',
      accountNumber: '257-223937-04-030'
    },
    {
      type: '지사',
      businessName: row.merchantName,
      feeRate: '-',
      feeAmount: '-',
      paymentAmount: '-',
      withholding: '0%',
      pointFee: '0',
      rewardFee: '0',
      accountNumber: '666201-01-767587'
    },
    // ... 필요한 만큼 데이터 추가
  ]);

  const handleCardDetailClick = (row) => {
    setSelectedRowData({
      merchantName: row.merchantName
    });
    setUserInfoTwoOpen(true);
  };

  const handleUserDetailClick = (row) => {
    setSelectedRowData({
      amount: row.amount,
      approvalNo: row.approvalNo,
      transactionDate: row.transactionDate,
      cardNumber: row.cardNumber,
      installment: row.installment,
      productName: row.productName
    });
    setUserInfoOneOpen(true);
  };

  const dummyData = [...Array(10)].map((_, index) => ({
    no: index + 1,
    merchantName: `가맹점${index + 1}`,
    merchantStatus: [
      ['block'],
      ['sms'],
      ['sms', 'recurring'],
      ['block', 'recurring'],
      [],
      ['recurring'],
      ['block', 'sms'],
      ['sms'],
      ['recurring'],
      ['block']
    ][index],
    transactionDate: `2024-01-${String(index + 1).padStart(2, '0')} 14:${index}0:00`,
    amount: index % 3 === 0 ? -(1000 * (index + 1)) : (1000 * (index + 1)),
    installment: ['일시불', '3개월', '60000개월'][index % 3],
    approvalNo: `${Math.floor(60000000 + Math.random() * 10000000)}`,
    cardCompany: ['국민카드', '신한카드', '삼성카드', '하나카드'][index % 4],
    cardNumber: `${9410 + index}-${String(93 + index).padStart(2, '0')}**-****-${String(2700 + index).padStart(4, '0')}`,
    productName: `상품${index + 1}`,
    phoneNumber: `010-1234-${String(index + 1).padStart(4, '0')}`,
    paymentType: ['단말기', '수기(구인증)', '수기(구인증)', '수기(구인증)', '단말기'][index % 5],
    detailButton: '상세보기',
    fee: `${(2000 * (index + 1)).toLocaleString()} / ${(0.3 + (index * 0.1)).toFixed(1)}%`,
    walletBalance: (index + 1) * 1000,
    settlementAmount: `${(3000 * (index + 1)).toLocaleString()} / ${(90 - index).toFixed(1)}%`,
    paymentAmount: `${(4000 * (index + 1)).toLocaleString()} / ${(85 - index).toFixed(1)}%`,
    reserveAmount: `${(5000 * (index + 1)).toLocaleString()} / ${(5 + index).toFixed(1)}%`,
    refundAmount: index * 1000,
    unpaidAmount: index * 500,
    depositAmount: `${(6000 * (index + 1)).toLocaleString()} / ${(10 + index).toFixed(1)}%`,
    pgName: `PG${index + 1}`,
    mid: `MID${String(index + 1).padStart(8, '0')}`,
    tid: `TID${String(index + 1).padStart(8, '0')}`,
  }));

  const tableWidth = '100%';

  return (
    <Paper sx={{ 
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: 'none',
      border: '1px solid #e9ecef',
      borderRadius: '4px',
      overflow: 'hidden'
    }}>
      <Box 
        className="cancel-options-section"
        sx={{ 
          display: 'flex',
          gap: 4,
          backgroundColor: '#f8f9fa',
          padding: '20px',
          borderRadius: '8px',
          mb: 3,
          width: '100%',
          '@media (max-width: 600px)': {
            overflowX: 'auto',
            WebkitOverflowScrolling: 'touch',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.2)',
              borderRadius: '3px'
            }
          }
        }}
      >
        <Box sx={{ 
          display: 'flex',
          gap: 2,
          '@media (max-width: 600px)': {
            minWidth: 'max-content'  // 내용이 잘리지 않도록 최소 너비 설정
          }
        }}>
          <CancelOptions 
            onCancelOption={onCancelOption}
            selectedItems={selectedApprovals}
          />
          <Box sx={{ borderLeft: '1px solid #ddd' }} />
          <MerchantIcons 
            onIconClick={onReceiptClick}
          />
        </Box>
      </Box>

      <StyledTableWrapper>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell className="primary-header" padding="checkbox">선택</StyledTableCell>
              <StyledTableCell className="primary-header" align="center">NO</StyledTableCell>
              <StyledTableCell className="sticky-header" align="left">가맹점명</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">거래일시</StyledTableCell>
              <StyledTableCell className="primary-header" align="right">결제금액</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">할부</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">승인번호</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">카드사</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">카드번호</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">상품명</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">연락처</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">영수증</StyledTableCell>
              <StyledTableCell className="primary-header" align="left">결제형식</StyledTableCell>
              
              <StyledTableCell className="secondary-header" align="left">계정/상세</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">수수료(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">월렛잔액</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">정산금(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">지급액(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">유보액(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">환입금</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">미수금</StyledTableCell>
              <StyledTableCell className="secondary-header" align="right">보증금(%)</StyledTableCell>
              <StyledTableCell className="secondary-header" align="left">PG</StyledTableCell>
              <StyledTableCell className="secondary-header" align="left">MID</StyledTableCell>
              <StyledTableCell className="secondary-header" align="left">TID</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell padding="checkbox">
                  <Checkbox 
                    size="small"
                    checked={selectedApprovals.includes(row.approvalNo)}
                    onChange={(e) => onCheckboxChange(e.target.checked, row.approvalNo)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{row.no}</StyledTableCell>
                <StyledTableCell className="sticky-column" align="left">
                  {row.merchantName}
                  {' '}
                  <MerchantStatusIcons statuses={row.merchantStatus} />
                </StyledTableCell>
                <StyledTableCell align="left">{row.transactionDate}</StyledTableCell>
                <StyledTableCell align="right" className="amount-cell">
                  {row.amount < 0 ? (
                    <span style={{ color: '#f44336' }}>
                      {row.amount.toLocaleString()}
                    </span>
                  ) : (
                    `+${row.amount.toLocaleString()}`
                  )}
                </StyledTableCell>
                <StyledTableCell align="left">{row.installment}</StyledTableCell>
                <StyledTableCell align="left">{row.approvalNo}</StyledTableCell>
                <StyledTableCell align="left">{row.cardCompany}</StyledTableCell>
                <StyledTableCell align="left">{row.cardNumber}</StyledTableCell>
                <StyledTableCell align="left">{row.productName}</StyledTableCell>
                <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                <StyledTableCell align="center">
                  <ReceiptIconButton 
                    onClick={() => onReceiptClick(row)} 
                    tooltipTitle={`영수증 상세보기 - ${row.approvalNo}`}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">{row.paymentType}</StyledTableCell>
                <StyledTableCell align="center">
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                    <Tooltip title="결제 상세정보">
                      <IconButton 
                        size="small" 
                        onClick={() => handleCardDetailClick(row)}
                        sx={IconButtonStyle}
                      >
                        <CreditCardIcon sx={{ fontSize: 20, color: '#2185d0' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="사용자 정보">
                      <IconButton 
                        size="small" 
                        onClick={() => handleUserDetailClick(row)}
                        sx={IconButtonStyle}
                      >
                        <PersonOutlineIcon sx={{ fontSize: 20, color: '#21ba45' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="right">{row.fee}</StyledTableCell>
                <StyledTableCell align="right">{row.walletBalance.toLocaleString()}</StyledTableCell> 
                <StyledTableCell align="right">{row.settlementAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.paymentAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.reserveAmount}</StyledTableCell>
                <StyledTableCell align="right">{row.refundAmount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.unpaidAmount.toLocaleString()}</StyledTableCell>
                <StyledTableCell align="right">{row.depositAmount}</StyledTableCell>
                <StyledTableCell align="left">{row.pgName}</StyledTableCell>
                <StyledTableCell align="left">{row.mid}</StyledTableCell>
                <StyledTableCell align="left">{row.tid}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableWrapper>

      <RMquestion
        open={isRMModalOpen}
        onClose={() => setIsRMModalOpen(false)}
        data={selectedMerchantData}
      />
      
      <UserInfoOne
        open={userInfoOneOpen}
        onClose={() => setUserInfoOneOpen(false)}
        data={selectedRowData}
      />
      
      <UserInfoTwo
        open={userInfoTwoOpen}
        onClose={() => setUserInfoTwoOpen(false)}
        merchantName={selectedRowData?.merchantName}
      />
    </Paper>
  );
}

PaymentHistoryTable.propTypes = {
  onCheckboxChange: PropTypes.func,
  selectedApprovals: PropTypes.array,
  onReceiptClick: PropTypes.func,
  onCardDetailClick: PropTypes.func,
  onUserDetailClick: PropTypes.func
};

export default PaymentHistoryTable;