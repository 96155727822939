import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, TextField, Popover, Alert, Select, MenuItem, Button, Menu } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

const DateRangePicker = ({ startDate, endDate, onStartDateChange, onEndDateChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(startDate ? dayjs(startDate) : null);
  const [tempEndDate, setTempEndDate] = useState(endDate ? dayjs(endDate) : null);
  const [error, setError] = useState('');
  const [currentMonth, setCurrentMonth] = useState(tempStartDate ? tempStartDate.month() + 1 : null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setError('');  // 팝업 열 때 에러 메시지 초기화
  };

  const handleClose = () => {
    setAnchorEl(null);
    setError('');  // 팝업 닫을 때 에러 메시지 초기화
  };

  const formatDateRange = () => {
    const formatDate = (date) => {
      if (!date) return '';
      // YYYY-MM-DD 형식에서 하이픈 앞뒤로 작은 간격 추가
      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`.replace(/-/g, ' - ');
    };

    if (!startDate && !endDate) return '';
    if (!endDate) return `${formatDate(startDate)} ~`;
    if (!startDate) return `~ ${formatDate(endDate)}`;
    return `${formatDate(startDate)} ~ ${formatDate(endDate)}`;
  };

  const validateDateRange = (start, end) => {
    if (!start || !end) return true;
    return start.isBefore(end) || start.isSame(end);
  };

  const handleStartDateChange = (newDate) => {
    if (tempEndDate && !validateDateRange(newDate, tempEndDate)) {
      setError('시작일은 종료일보다 이후일 수 없습니다.');
      return;
    }
    setError('');
    setTempStartDate(newDate);
    const formattedDate = newDate.format('YYYY-MM-DD');
    onStartDateChange(formattedDate);
    
    // 검색 파라미터 콘솔 출력
    console.log('Search Parameters:', {
      startDate: formattedDate,
      endDate: endDate || '',
      type: 'startDateChange'
    });
  };

  const handleEndDateChange = (newDate) => {
    if (tempStartDate && !validateDateRange(tempStartDate, newDate)) {
      setError('종료일은 시작일보다 이전일 수 없습니다.');
      return;
    }
    setError('');
    setTempEndDate(newDate);
    const formattedDate = newDate.format('YYYY-MM-DD');
    onEndDateChange(formattedDate);
    
    // 검색 파라미터 콘솔 출력
    console.log('Search Parameters:', {
      startDate: startDate || '',
      endDate: formattedDate,
      type: 'endDateChange'
    });
  };

  const handleMonthChange = (event) => {
    setCurrentMonth(event.target.value);
    if (tempStartDate) {
      const newDate = tempStartDate.month(event.target.value - 1);
      setTempStartDate(newDate);
      onStartDateChange(newDate.format('YYYY-MM-DD'));
    }
  };

  const handleYearClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleYearClose = () => {
    setAnchorEl(null);
  };

  const handleYearSelect = (year) => {
    if (tempEndDate) {
      const newDate = tempEndDate.year(year);
      setTempEndDate(newDate);
      onEndDateChange(newDate.format('YYYY-MM-DD'));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <Box>
        <TextField
          size="small"
          value={formatDateRange()}
          onClick={handleClick}
          placeholder="날짜 범위 선택"
          InputProps={{
            readOnly: true,
          }}
          sx={{
            width: '260px',
            cursor: 'pointer',
            '& .MuiOutlinedInput-root': {
              height: '36px',
              fontSize: '0.875rem',
              backgroundColor: '#fff',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#f8f9fa'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e0e0e0'
              },
              '& .MuiOutlinedInput-input': {
                cursor: 'pointer'
              }
            }
          }}
        />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 2, 
            p: 2, 
            bgcolor: 'background.paper',
            '@media (max-width: 600px)': {
              overflowX: 'auto',
              WebkitOverflowScrolling: 'touch',
              maxWidth: '100vw',
              '&::-webkit-scrollbar': {
                height: '6px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.2)',
                borderRadius: '3px'
              }
            }
          }}>
            {error && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {error}
              </Alert>
            )}
            <Box sx={{ 
              display: 'flex', 
              gap: 2,
              '@media (max-width: 600px)': {
                minWidth: 'max-content'  // 내용이 잘리지 않도록 최소 너비 설정
              }
            }}>
              <Box>
                <Box sx={{ mb: 1, fontWeight: 500, color: '#666' }}>시작일</Box>
                <DateCalendar 
                  value={tempStartDate}
                  onChange={handleStartDateChange}
                  views={['year', 'day']}
                  openTo="day"
                  showDaysOutsideCurrentMonth={false}
                  slots={{
                    calendarHeader: () => (
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        padding: '8px'
                      }}>
                        <Select
                          value={tempStartDate ? tempStartDate.year() : new Date().getFullYear()}
                          onChange={(event) => {
                            const newYear = event.target.value;
                            const newDate = tempStartDate ? tempStartDate.year(newYear) : dayjs().year(newYear);
                            setTempStartDate(newDate);
                            onStartDateChange(newDate.format('YYYY-MM-DD'));
                          }}
                          size="small"
                          sx={{
                            height: '28px',
                            fontSize: '0.875rem',
                            minWidth: '70px',
                            '.MuiSelect-select': {
                              padding: '4px 8px'
                            }
                          }}
                        >
                          {Array.from({ length: new Date().getFullYear() - 2000 + 1 }, (_, i) => {
                            const year = new Date().getFullYear() - i;
                            return (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Select
                          value={currentMonth || new Date().getMonth() + 1}
                          onChange={handleMonthChange}
                          size="small"
                          sx={{
                            height: '28px',
                            fontSize: '0.875rem',
                            minWidth: '45px',
                            '.MuiSelect-select': {
                              padding: '4px 8px'
                            }
                          }}
                        >
                          {Array.from({ length: 12 }, (_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )
                  }}
                  sx={{ 
                    width: '280px',
                    '& .MuiPickersArrowSwitcher-root': {
                      display: 'none'
                    },
                    '& .MuiDayCalendar-header': {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    },
                    '& .MuiDayCalendar-monthContainer': {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    }
                  }}
                />
              </Box>
              <Box>
                <Box sx={{ mb: 1, fontWeight: 500, color: '#666' }}>종료일</Box>
                <DateCalendar 
                  value={tempEndDate}
                  onChange={handleEndDateChange}
                  views={['year', 'day']}
                  openTo="day"
                  showDaysOutsideCurrentMonth={false}
                  slots={{
                    calendarHeader: () => (
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        padding: '8px'
                      }}>
                        <Select
                          value={tempEndDate ? tempEndDate.year() : new Date().getFullYear()}
                          onChange={(event) => {
                            const newYear = event.target.value;
                            const newDate = tempEndDate ? tempEndDate.year(newYear) : dayjs().year(newYear);
                            setTempEndDate(newDate);
                            onEndDateChange(newDate.format('YYYY-MM-DD'));
                          }}
                          size="small"
                          sx={{
                            height: '28px',
                            fontSize: '0.875rem',
                            minWidth: '70px',
                            '.MuiSelect-select': {
                              padding: '4px 8px'
                            }
                          }}
                        >
                          {Array.from({ length: new Date().getFullYear() - 2000 + 1 }, (_, i) => {
                            const year = new Date().getFullYear() - i;
                            return (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Select
                          value={currentMonth || new Date().getMonth() + 1}
                          onChange={handleMonthChange}
                          size="small"
                          sx={{
                            height: '28px',
                            fontSize: '0.875rem',
                            minWidth: '45px',
                            '.MuiSelect-select': {
                              padding: '4px 8px'
                            }
                          }}
                        >
                          {Array.from({ length: 12 }, (_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )
                  }}
                  sx={{ 
                    width: '280px',
                    '& .MuiPickersArrowSwitcher-root': {
                      display: 'none'
                    },
                    '& .MuiDayCalendar-header': {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    },
                    '& .MuiDayCalendar-monthContainer': {
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;