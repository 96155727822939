import React from 'react';
import { Box } from '@mui/material';

import Notice from '../../Notice/Notice';
import SummaryData from '../../Common/SummaryData';
import RegularPaymentTable from './RegularPaymentTable';

const styles = {
  mainBox: { 
    flexGrow: 1, 
    p: 3, 
    mt: '5px',
    mx: '50px', 
    height: 'auto', 
    overflowY: 'auto',
    position: 'relative',
    pl: '50px',
    pr: '50px',
    '@media (max-width: 768px)': {
      mx: '10px',
      pl: '10px',
      pr: '10px'
    }
  },
  noticeBox: { 
    width: 'calc(100% - 20px)', 
    margin: '0 auto', 
    mb: 2 
  }
};

function RegularPayment() {
  return (
    <Box component="main" sx={styles.mainBox}>
      <SummaryData />

      <Box sx={styles.noticeBox}>
        <Notice />
      </Box>

      <Box sx={{ mb: 2 }} />
      
      <RegularPaymentTable />
    </Box>
  );
}

export default RegularPayment;
