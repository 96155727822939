import React, { useState, useRef } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const AllAccountsVerification = () => {
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState('no');
  const [order, setOrder] = useState('asc');

  // 더미 데이터
  const rows = [
    {
      no: 1,
      merchant_id: '1046440397',
      merchant_name: '중지 컬리브리 0397',
      bank_name: '우리은행',
      owner_name: '윤준혁',
      account_no: '1002-155-298023',
      verification_status: '검증완료'
    },
    {
      no: 2,
      merchant_id: '1046428671',
      merchant_name: '중지 고양시부시장3구 8671',
      bank_name: '신한퍼스트',
      owner_name: '정금숙',
      account_no: '132-081-382219',
      verification_status: '검증대기'
    },
    // ... 더 많은 더미 데이터 추가 가능
  ];

  // 정렬 핸들러
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = [...rows].sort((a, b) => {
    const isAsc = order === 'asc';
    if (orderBy === 'no') {
      return isAsc ? a.no - b.no : b.no - a.no;
    }
    return isAsc
      ? String(a[orderBy]).localeCompare(String(b[orderBy]))
      : String(b[orderBy]).localeCompare(String(a[orderBy]));
  });

  return (
    <Box sx={{ p: 3 }}>
      {/* 헤더 */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1,
        mb: 3 
      }}>
        <AccountBalanceIcon sx={{ fontSize: 28, color: '#00acc1' }} />
        <Typography variant="h5" sx={{ 
          fontWeight: 600,
          fontFamily: "'Pretendard', 'Noto Sans KR', sans-serif"
        }}>
          가맹 전계좌 검증
        </Typography>
      </Box>

      {/* 검색 및 필터 영역 */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3,
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          mb: 2
        }}>
          {/* 데이터 개수 선택 */}
          <RadioGroup
            row
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            sx={{ gap: 2 }}
          >
            {[10, 25, 50, 100].map((value) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio size="small" />}
                label={value}
              />
            ))}
          </RadioGroup>

          {/* 검색어 입력 */}
          <TextField
            size="small"
            placeholder="검색어를 입력하세요"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ width: '300px' }}
          />

          {/* 전체 가맹점 계좌 검증 버튼 */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#00acc1',
              '&:hover': {
                backgroundColor: '#007c91',
              }
            }}
          >
            전체 가맹점 계좌 검증
          </Button>
        </Box>
      </Paper>

      {/* 테이블 */}
      <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === 'no'}
                    direction={orderBy === 'no' ? order : 'asc'}
                    onClick={() => handleRequestSort('no')}
                  >
                    No
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">로그인아이디</TableCell>
                <TableCell align="center">가맹점명</TableCell>
                <TableCell align="center">은행명</TableCell>
                <TableCell align="center">예금주</TableCell>
                <TableCell align="center">계좌번호</TableCell>
                <TableCell align="center">검증여부</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.map((row) => (
                <TableRow key={row.no}>
                  <TableCell align="center">{row.no}</TableCell>
                  <TableCell align="center">{row.merchant_id}</TableCell>
                  <TableCell align="center">{row.merchant_name}</TableCell>
                  <TableCell align="center">{row.bank_name}</TableCell>
                  <TableCell align="center">{row.owner_name}</TableCell>
                  <TableCell align="center">{row.account_no}</TableCell>
                  <TableCell 
                    align="center"
                    sx={{ 
                      color: row.verification_status === '검증완료' ? '#d32f2f' : 'inherit',
                      fontWeight: row.verification_status === '검증완료' ? 600 : 400
                    }}
                  >
                    {row.verification_status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default AllAccountsVerification;
