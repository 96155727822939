import React, { useState } from "react";
import { Table, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { styled } from '@mui/material/styles';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Box } from '@mui/material';

// 스위치 컴포넌트 스타일
const SwitchButton = styled('div')`
  width: 80px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  background-color: ${props => props.isOn ? '#51cbce' : '#cccccc'};
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  margin: 0 auto;
  
  &:hover {
    opacity: 0.9;
  }
`;

const SwitchText = styled('span')`
  color: white;
  font-weight: 500;
  font-size: 14px;
`;

// 테이블 스타일
const StyledTable = styled(Table)`
  th, td {
    text-align: center;
    vertical-align: middle !important;
    padding: 12px 8px !important;
  }
  
  th:nth-child(2), 
  td:nth-child(2) {
    text-align: left;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

// CustomSwitch 컴포넌트
function CustomSwitch({ defaultValue = false, onChange }) {
  const [isOn, setIsOn] = useState(defaultValue);

  const handleClick = () => {
    const newValue = !isOn;
    setIsOn(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <SwitchButton isOn={isOn} onClick={handleClick}>
      <SwitchText>{isOn ? 'ON' : 'OFF'}</SwitchText>
    </SwitchButton>
  );
}

function UserMenuOnOff() {
  // handleSwitchChange 함수 추가
  const handleSwitchChange = (itemNo, key, value) => {
    console.log(`Domain ${itemNo} ${key} changed to: ${value}`);
  };

  const menuItems = [
    { 
      no: 1, 
      domain: "w.mainpay.me",
      permissions: {
        간편결제: true,
        결제요청: true,
        QR결제: true,
        정기결제: true,
        니어결제: true,
        실물통장: true,
        결제내역: true,
        정기결제내역: false,
        결제실패내역: true
      }
    },
    { 
      no: 2, 
      domain: "mainpay.me",
      permissions: {
        간편결제: true,
        결제요청: true,
        QR결제: false,
        정기결제: true,
        니어결제: true,
        실물통장: false,
        결제내역: true,
        정기결제내역: true,
        결제실패내역: true
      }
    },
    { 
      no: 3, 
      domain: "platbill.kr",
      permissions: {
        간편결제: true,
        결제요청: true,
        QR결제: false,
        정기결제: true,
        니어결제: true,
        실물통장: false,
        결제내역: true,
        정기결제내역: true,
        결제실패내역: true
      }
    },
    { 
      no: 4, 
      domain: "cashbill.kr",
      permissions: {
        간편결제: true,
        결제요청: false,
        QR결제: false,
        정기결제: true,
        니어결제: true,
        실물통장: true,
        결제내역: true,
        정기결제내역: false,
        결제실패내역: true
      }
    }
  ];

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PersonOffIcon sx={{ color: '#2e7d32', fontSize: 28 }} />
            <CardTitle tag="h4">유저 메뉴 On/Off 설정</CardTitle>
          </Box>
        </CardHeader>
        <CardBody>
          <StyledTable responsive>
            <thead className="text-primary">
              <tr>
                <th style={{width: '5%'}}>No</th>
                <th style={{width: '15%'}}>도메인</th>
                <th>간편결제 ON/OFF</th>
                <th>결제요청 ON/OFF</th>
                <th>QR결제 ON/OFF</th>
                <th>정기결제 ON/OFF</th>
                <th>니어결제 ON/OFF</th>
                <th>실물통장 ON/OFF</th>
                <th>결제내역 ON/OFF</th>
                <th>정기결제내역 ON/OFF</th>
                <th>결제실패내역 ON/OFF</th>
              </tr>
            </thead>
            <tbody>
              {menuItems.map((item) => (
                <tr key={item.no}>
                  <td>{item.no}</td>
                  <td>{item.domain}</td>
                  {Object.entries(item.permissions).map(([key, value]) => (
                    <td key={key}>
                      <CustomSwitch 
                        defaultValue={value}
                        onChange={(value) => handleSwitchChange(item.no, key, value)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </CardBody>
      </Card>
    </div>
  );
}

export default UserMenuOnOff;
