import React, { useState } from 'react';
import { 
  Box, 
  IconButton,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Checkbox,
  Modal,
  Typography
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import MerchantStatusIcons from '../../Common/MerchantStatusIcons';
import CancelOptions from '../../Common/CancelOptions';
import MerchantIcons from '../../Common/MerchantIcons';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 8px',
  fontSize: '0.813rem',
  whiteSpace: 'nowrap',
  borderBottom: '1px solid #e9ecef',
  borderRight: '1px solid #e9ecef',
  color: '#000',
  '&:last-child': {
    borderRight: 'none',
  },
  '@media (max-width: 960px)': {
    padding: '8px 4px',
    fontSize: '0.75rem',
  }
}));

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fafafa',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
});

function ComplaintDetailsTables({ 
  searchPanelOpen, 
  setSearchPanelOpen, 
  selectedRows, 
  setSelectedRows 
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const handleCheckboxChange = (checked, rowId) => {
    if (checked) {
      setSelectedRows(prev => [...prev, rowId]);
    } else {
      setSelectedRows(prev => prev.filter(id => id !== rowId));
    }
  };

  const handleUserIconClick = (userData) => {
    setSelectedUserData(userData);
    setModalOpen(true);
  };

  // 테이블 헤더를 한 줄로 정의
  const tableHeaders = [
    { id: 'checkbox', label: '강제취소', color: '#bbdefb', width: '60px' },
    { id: 'rm', label: 'RM', color: '#bbdefb', width: '80px' },
    { id: 'no', label: 'No', color: '#bbdefb', width: '50px' },
    { id: 'detailInfo', label: '상세정보', color: '#bbdefb', width: '80px' },
    { id: 'offsetRefund', label: '상계환입', color: '#bbdefb', width: '80px' },
    { id: 'complaintType', label: '민원종류', color: '#bbdefb', width: '100px' },
    { id: 'completionStatus', label: '완료여부', color: '#bbdefb', width: '80px' },
    { id: 'responseDeadline', label: '회신기한', color: '#e3f2fd', width: '100px' },
    { id: 'turnCount', label: '회차수', color: '#e3f2fd', width: '60px' },
    { id: 'pgType', label: 'PG구분', color: '#e3f2fd', width: '100px' },
    { id: 'paymentMethod', label: '결제수단', color: '#e3f2fd', width: '80px' },
    { id: 'userId', label: 'ID', color: '#c8e6c9', width: '100px' },
    { id: 'merchantTid', label: '가맹점TID', color: '#c8e6c9', width: '120px' },
    { id: 'merchantName', label: '가맹점명', color: '#c8e6c9', width: '150px' },
    { id: 'branchName', label: '지사명', color: '#c8e6c9', width: '100px' },
    { id: 'complaintContent', label: '민원내용', color: '#ffebee', width: '200px' },
    { id: 'responseContent', label: '답변내용', color: '#ffebee', width: '200px' },
    { id: 'registerTime', label: '등록시간', color: '#ffebee', width: '150px' },
    { id: 'registrant', label: '등록자', color: '#ffebee', width: '80px' },
    { id: 'userInfo', label: '사용자 정보', color: '#ffebee', width: '80px' }
  ];

  // 1행의 더미 데이터
  const dummyData = [{
    id: 1,
    rm: 'RM001',
    no: '1',
    detailInfo: '상세보기',
    offsetRefund: '해당없음',
    complaintType: '결제취소요청',
    completionStatus: '처리중',
    responseDeadline: '2024-03-25',
    turnCount: '1',
    pgType: 'KG이니시스',
    paymentMethod: '신용카드',
    userId: 'USER001',
    merchantTid: 'TID123456789',
    merchantName: '테스트가맹점1',
    merchantStatus: ['block', 'sms'],
    branchName: '서울지사',
    complaintContent: '결제취소 요청드립니다.',
    responseContent: '확인 후 처리하겠습니다.',
    registerTime: '2024-03-21 15:30:00',
    registrant: '홍길동',
    userInfo: {
      customerName: '김고객',
      phoneNumber: '010-1234-5678',
      approvalAmount: '50,000원',
      approvalNo: 'APPR12345678',
      paymentDate: '2024-03-20 14:30:00',
      cancelDate: '',
      hasAttachments: true
    }
  }];

  return (
    <>
      <Box 
        className="cancel-options-section"
        sx={{ 
          display: 'flex',
          gap: 4,
          backgroundColor: '#f8f9fa',
          padding: '20px',
          borderRadius: '8px',
          mb: 3,
          width: '100%',
          '@media (max-width: 600px)': {
            overflowX: 'auto',
            WebkitOverflowScrolling: 'touch',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.2)',
              borderRadius: '3px'
            }
          }
        }}
      >
        <Box sx={{ 
          display: 'flex',
          gap: 2,
          '@media (max-width: 600px)': {
            minWidth: 'max-content'
          }
        }}>
          <CancelOptions 
            selectedItems={selectedRows}
          />
          <Box sx={{ borderLeft: '1px solid #ddd' }} />
          <MerchantIcons />
        </Box>
      </Box>

      <TableContainer 
        component={Paper} 
        sx={{ 
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px'
          }
        }}
      >
        <Table sx={{ minWidth: 2000 }}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell 
                  key={header.id}
                  align={header.id === 'checkbox' ? 'center' : 'left'}
                  style={{ 
                    backgroundColor: header.color,
                    width: header.width,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                  }}
                >
                  {header.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData.map((row) => (
              <StyledTableRow key={row.id}>
                {tableHeaders.map((header) => (
                  <StyledTableCell 
                    key={`${row.id}-${header.id}`}
                    align={header.id === 'checkbox' ? 'center' : 'left'}
                    style={{ width: header.width }}
                  >
                    {header.id === 'checkbox' ? (
                      <Checkbox 
                        checked={selectedRows.includes(row.id)}
                        onChange={(e) => handleCheckboxChange(e.target.checked, row.id)}
                      />
                    ) : header.id === 'merchantName' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {row[header.id]}
                        <MerchantStatusIcons statuses={row.merchantStatus} />
                      </Box>
                    ) : header.id === 'userInfo' ? (
                      <IconButton 
                        size="small"
                        onClick={() => handleUserIconClick(row.userInfo)}
                      >
                        <PersonIcon />
                      </IconButton>
                    ) : (
                      row[header.id]
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{
          backgroundColor: 'white',
          padding: 3,
          borderRadius: 1,
          width: '400px',
          maxWidth: '90vw'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            사용자 정보
          </Typography>
          {selectedUserData && (
            <>
              <Typography>고객명: {selectedUserData.customerName}</Typography>
              <Typography>휴대폰 번호: {selectedUserData.phoneNumber}</Typography>
              <Typography>승인금액: {selectedUserData.approvalAmount}</Typography>
              <Typography>승인번호: {selectedUserData.approvalNo}</Typography>
              <Typography>결제일자: {selectedUserData.paymentDate}</Typography>
              <Typography>취소일자: {selectedUserData.cancelDate || '-'}</Typography>
              <Typography>첨부파일: {selectedUserData.hasAttachments ? '있음' : '없음'}</Typography>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ComplaintDetailsTables;
