import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,
  Modal,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  fontSize: '0.75rem',
  whiteSpace: 'nowrap',
  borderBottom: '1px solid #e0e0e0',
  borderRight: '1px solid #e0e0e0',
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  
  '&.MuiTableCell-head': {
    backgroundColor: '#236BB5',
    color: '#fff',
    fontWeight: 500,
    height: '40px',
    borderTop: '1px solid #e0e0e0',
    '&:first-of-type': {
      borderLeft: '1px solid #e0e0e0',
    },
    '&:nth-of-type(5) ~ th': {
      backgroundColor: '#6A9ED3'
    },
    '&:nth-of-type(27) ~ th': {
      backgroundColor: '#CAE4FE',
      color: '#000'
    }
  },
  
  '&.MuiTableCell-body': {
    '&:first-of-type': {
      borderLeft: '1px solid #e0e0e0',
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#f5f5f5',
  },
  '&:hover': {
    backgroundColor: 'rgba(202, 228, 254, 0.1)'
  },
  '& td:nth-of-type(5), & td:nth-of-type(27)': {
    borderRight: '1px dashed #D0D0D0'
  }
}));

const formatNumber = (value, isAmount = false) => {
  if (!value) return '0';
  
  const num = typeof value === 'string' ? parseFloat(value) : value;
  const sign = num >= 0 ? '+' : '';
  const formattedNum = Math.abs(num).toLocaleString();
  
  return isAmount ? `${sign}${formattedNum}` : formattedNum;
};

const formatFee = (amount, rate) => {
  if (!amount || !rate) return '0';
  const feeAmount = Math.floor(amount * (parseFloat(rate) / 100));
  return `+${feeAmount.toLocaleString()}/${rate}`;
};

const formatAmountWithCount = (amount, count) => {
  if (!amount) return '0 (0건)';
  return `${formatNumber(amount, true)} (${count}건)`;
};

// Modal 스타일 추가
const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const ModalContent = styled(Box)({
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  minWidth: '300px',
  maxWidth: '500px'
});

function PaymentAggregationTable({ data = [], searchPanelOpen }) {
  // 모달 상태 관리
  const [agencyModalOpen, setAgencyModalOpen] = useState(false);
  const [merchantModalOpen, setMerchantModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  // 지사명 클릭 핸들러
  const handleAgencyClick = (agencyName) => {
    setSelectedData(agencyName);
    setAgencyModalOpen(true);
  };

  // 가맹점 클릭 핸들러
  const handleMerchantClick = (merchantName) => {
    setSelectedData(merchantName);
    setMerchantModalOpen(true);
  };

  console.log('PaymentAggregationTable - Search Panel State:', searchPanelOpen);
  
  return (
    <Paper sx={{ 
      width: '100%',
      overflow: 'hidden',
      transition: 'all 0.3s',
      marginRight: searchPanelOpen ? '280px' : '0'
    }}>
      <TableContainer sx={{ 
        maxHeight: 650,
        '& .MuiTableCell-root': {
          whiteSpace: 'nowrap',
          padding: '8px'
        }
      }}>
        <Table stickyHeader className="sum-jungsan-list">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No</StyledTableCell>
              <StyledTableCell align="left">PG구분</StyledTableCell>
              <StyledTableCell align="left">에이전시</StyledTableCell>
              <StyledTableCell align="left">가맹점명</StyledTableCell>
              <StyledTableCell align="left">가맹점MID</StyledTableCell>
              <StyledTableCell align="left">가맹점TID</StyledTableCell>
              <StyledTableCell align="right">승인건수</StyledTableCell>
              <StyledTableCell align="right">취소건수</StyledTableCell>
              <StyledTableCell align="right">건수합계</StyledTableCell>
              <StyledTableCell align="center">결제주기</StyledTableCell>
              <StyledTableCell align="right">승인금액</StyledTableCell>
              <StyledTableCell align="right">취소금액</StyledTableCell>
              <StyledTableCell align="right">매출합계</StyledTableCell>
              <StyledTableCell align="right">평균단가</StyledTableCell>
              <StyledTableCell align="right">가맹수수료율</StyledTableCell>
              <StyledTableCell align="right">수수료(in.VAT)</StyledTableCell>
              <StyledTableCell align="right">환입금</StyledTableCell>
              <StyledTableCell align="right">미수금</StyledTableCell>
              <StyledTableCell align="right">지급율</StyledTableCell>
              <StyledTableCell align="right">지급금액</StyledTableCell>
              <StyledTableCell align="right">차감액</StyledTableCell>
              <StyledTableCell align="right">유보액</StyledTableCell>
              <StyledTableCell align="right">정산액</StyledTableCell>
              <StyledTableCell align="right">리스크 금액</StyledTableCell>
              <StyledTableCell align="right">환입액</StyledTableCell>
              <StyledTableCell align="right">미수금</StyledTableCell>
              <StyledTableCell align="left">결제수단</StyledTableCell>
              <StyledTableCell align="left">인증방식</StyledTableCell>
              <StyledTableCell align="left">사업자등록번호</StyledTableCell>
              <StyledTableCell align="left">주민번호</StyledTableCell>
              <StyledTableCell align="left">연락처</StyledTableCell>
              <StyledTableCell align="left">이메일</StyledTableCell>
              <StyledTableCell align="right">보증금(%)</StyledTableCell>
              <StyledTableCell align="right">보증금액</StyledTableCell>
              <StyledTableCell align="right">리스크비율</StyledTableCell>
              <StyledTableCell align="right">RM건수</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">{row.no}</StyledTableCell>
                <StyledTableCell align="left">{row.pgType}</StyledTableCell>
                <StyledTableCell 
                  align="left" 
                  onClick={() => handleAgencyClick(row.agencyName)}
                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                >
                  {row.agencyName}
                </StyledTableCell>
                <StyledTableCell 
                  align="left"
                  onClick={() => handleMerchantClick(row.storeName)}
                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                >
                  {row.storeName}
                </StyledTableCell>
                <StyledTableCell align="left">{row.mchtId}</StyledTableCell>
                <StyledTableCell align="left">{row.tmnId}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.approvalCount)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.cancelCount)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.totalCount)}</StyledTableCell>
                <StyledTableCell align="center">{row.settlementCycle}</StyledTableCell>
                <StyledTableCell align="right">{formatAmountWithCount(row.approvalAmount, row.approvalCount)}</StyledTableCell>
                <StyledTableCell align="right">{formatAmountWithCount(row.cancelAmount, row.cancelCount)}</StyledTableCell>
                <StyledTableCell align="right">{formatAmountWithCount(row.totalAmount, row.totalCount)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.averageAmount, true)}</StyledTableCell>
                <StyledTableCell align="right">{row.feeRate}</StyledTableCell>
                <StyledTableCell align="right">{formatFee(row.approvalAmount, row.feeRate)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.refundAmount, true)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.unpaidAmount, true)}</StyledTableCell>
                <StyledTableCell align="right">{row.paymentRate}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.paymentAmount, true)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.deductionAmount, true)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.reserveAmount, true)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.settlementAmount, true)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.riskAmount, true)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.refundAmount2, true)}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.unpaidAmount2, true)}</StyledTableCell>
                <StyledTableCell align="left">{row.paymentMethod}</StyledTableCell>
                <StyledTableCell align="left">{row.authType}</StyledTableCell>
                <StyledTableCell align="left">{row.businessNumber}</StyledTableCell>
                <StyledTableCell align="left">{row.residentNumber}</StyledTableCell>
                <StyledTableCell align="left">{row.contact}</StyledTableCell>
                <StyledTableCell align="left">{row.email}</StyledTableCell>
                <StyledTableCell align="right">{row.depositRate}</StyledTableCell>
                <StyledTableCell align="right">{formatNumber(row.depositAmount)}</StyledTableCell>
                <StyledTableCell align="right">{row.riskRate}</StyledTableCell>
                <StyledTableCell align="right">{row.rmCount}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* 지사 데이터 모달 */}
      <StyledModal
        open={agencyModalOpen}
        onClose={() => setAgencyModalOpen(false)}
      >
        <ModalContent>
          <Typography variant="h6" component="h2" gutterBottom>
            해당 지사 데이터
          </Typography>
          <Typography>
            지사명: {selectedData}
          </Typography>
          {/* 추가 지사 데이터 표시 */}
        </ModalContent>
      </StyledModal>

      {/* 가맹점 데이터 모달 */}
      <StyledModal
        open={merchantModalOpen}
        onClose={() => setMerchantModalOpen(false)}
      >
        <ModalContent>
          <Typography variant="h6" component="h2" gutterBottom>
            해당 가맹점 데이터
          </Typography>
          <Typography>
            가맹점명: {selectedData}
          </Typography>
          {/* 추가 가맹점 데이터 표시 */}
        </ModalContent>
      </StyledModal>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        p: 2,
        backgroundColor: '#fff'
      }}>
        <Pagination count={10} variant="outlined" shape="rounded" />
      </Box>
    </Paper>
  );
}

export default PaymentAggregationTable;
