import React, { useEffect } from 'react';
import { Box } from '@mui/material';

const AlertMessage = ({ message, duration = 5000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <Box
      sx={{
        backgroundColor: '#ffebee',
        padding: '12px 20px',
        borderRadius: '4px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        borderLeft: '4px solid #f44336',
        marginBottom: '8px',
        animation: 'slideIn 0.3s ease-out',
        '@keyframes slideIn': {
          from: {
            transform: 'translateX(-100%)',
            opacity: 0
          },
          to: {
            transform: 'translateX(0)',
            opacity: 1
          }
        }
      }}
    >
      {message}
    </Box>
  );
};

export default AlertMessage; 