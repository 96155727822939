import React from 'react';
import { 
  Dialog, 
  DialogContent, 
  Paper, 
  Typography,
  Box,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ReceiptPaper = styled(Paper)({
  width: '350px',
  padding: '20px 15px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  position: 'relative',
  fontSize: '0.8rem',
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '12px',
    background: 'repeating-linear-gradient(45deg, #fff, #fff 5px, transparent 5px, transparent 12px)',
    borderBottom: '1px dashed #e0e0e0'
  }
});

const ReceiptTitle = styled(Typography)({
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 600,
  marginBottom: '15px',
  color: '#000'
});

const ReceiptSection = styled(Box)({
  marginBottom: '15px'
});

const ReceiptSectionTitle = styled(Typography)({
  fontSize: '0.9rem',
  fontWeight: 600,
  color: '#000',
  borderBottom: '1px solid #000',
  paddingBottom: '5px',
  marginBottom: '10px'
});

const ReceiptRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '4px 0',
  '& .label': {
    color: '#333',
    fontSize: '0.8rem'
  },
  '& .value': {
    color: '#000',
    fontSize: '0.8rem',
    textAlign: 'right'
  }
});

const ReceiptFooter = styled(Typography)({
  fontSize: '0.7rem',
  color: '#666',
  textAlign: 'left',
  marginTop: '15px',
  whiteSpace: 'pre-wrap',
  wordBreak: 'keep-all'
});

const ReceiptModal = ({ open, onClose, receiptData }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <ReceiptPaper>
        <ReceiptTitle>신용카드 영수증</ReceiptTitle>

        <ReceiptSection>
          <ReceiptSectionTitle>결제정보</ReceiptSectionTitle>
          <ReceiptRow>
            <Typography className="label">결제형식</Typography>
            <Typography className="value">{receiptData?.paymentType || '카드'}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">결제수단</Typography>
            <Typography className="value">{receiptData?.cardType || '단말기'}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">거래상태</Typography>
            <Typography className="value">{receiptData?.status || '승인'}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">거래일시</Typography>
            <Typography className="value">{receiptData?.transactionDate}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">결제카드</Typography>
            <Typography className="value">{receiptData?.cardCompany || '국민카드'}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">카드번호</Typography>
            <Typography className="value">{receiptData?.cardNumber}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">할부개월</Typography>
            <Typography className="value">{receiptData?.installment || '일시불'}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">구매자명</Typography>
            <Typography className="value">{receiptData?.buyerName}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">상품명</Typography>
            <Typography className="value">{receiptData?.productName}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">승인번호</Typography>
            <Typography className="value">{receiptData?.approvalNo}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">과세금액</Typography>
            <Typography className="value">{receiptData?.taxAmount}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">부가세</Typography>
            <Typography className="value">{receiptData?.vat}</Typography>
          </ReceiptRow>
          <ReceiptRow sx={{ 
            marginTop: '8px',
            '& .label, & .value': { 
              fontWeight: 600,
              fontSize: '0.85rem'
            }
          }}>
            <Typography className="label">총 결제금액</Typography>
            <Typography className="value">{receiptData?.totalAmount}</Typography>
          </ReceiptRow>
        </ReceiptSection>

        <ReceiptSection>
          <ReceiptSectionTitle>결제 가맹점 정보</ReceiptSectionTitle>
          <ReceiptRow>
            <Typography className="label">상호</Typography>
            <Typography className="value">{receiptData?.storeName}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">사업자번호</Typography>
            <Typography className="value">{receiptData?.businessNo}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">대표자명</Typography>
            <Typography className="value">{receiptData?.ownerName}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">연락처</Typography>
            <Typography className="value">{receiptData?.phoneNumber}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">휴대번호</Typography>
            <Typography className="value">{receiptData?.mobileNumber}</Typography>
          </ReceiptRow>
          <ReceiptRow>
            <Typography className="label">주소</Typography>
            <Typography className="value" sx={{ fontSize: '0.75rem' }}>
              {receiptData?.address}
            </Typography>
          </ReceiptRow>
        </ReceiptSection>

        <Divider sx={{ margin: '15px 0', borderStyle: 'dashed' }} />
        
        <Box sx={{ textAlign: 'center', mb: 1 }}>
          <Typography sx={{ fontSize: '0.8rem', color: '#666' }}>
            카드결제 이용문의: 010-5500-5487
          </Typography>
        </Box>

        <ReceiptFooter>
          * 신용카드 매출전표는 부가가치세법 제32조 2제3항에 의하여 발행되었으며, 
          부가가치세법 제46조에 따라 신용카드매출전표 등을 발급받은 경우에는 
          매입세액 공제가 가능합니다.
        </ReceiptFooter>
      </ReceiptPaper>
    </Dialog>
  );
};

export default ReceiptModal;
