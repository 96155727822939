import React, { useState, useRef } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Tooltip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TableSortLabel
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';

function PGProviderSettings() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [orderBy, setOrderBy] = useState('No');
  const [order, setOrder] = useState('asc');

  const columns = [
    { id: 'No', label: 'No', sortable: true },
    { id: 'PG', label: 'PG사', sortable: false },
    { id: 'PAY_FLAG', label: 'PAY_FLAG', sortable: false },
    { id: 'ADMIN', label: 'ADMIN', sortable: true },
    { id: 'SENOIR', label: 'SENOIR', sortable: true },
    { id: 'MID', label: 'MID', sortable: false },
    { id: 'KEY', label: 'KEY', sortable: false },
    { id: 'INV', label: 'INV', sortable: false },
    { id: 'INSERT_DATE', label: 'INSERT DATE', sortable: true },
    { id: 'UPDATE_DATE', label: 'UPDATE DATE', sortable: true },
    { id: 'delete', label: '삭제', sortable: false }
  ];

  // 더미 데이터
  const dummyData = [
    {
      No: 152,
      PG: 'payhub',
      PAY_FLAG: '인증',
      ADMIN: '1.95',
      SENOIR: 'seasy24823',
      MID: 'seasy24823123l',
      KEY: 'SU5JTElURV9UUklQTEVERVNfS0VZU1RS',
      INV: 'Y',
      INSERT_DATE: '2024-12-04 18:24:15',
      UPDATE_DATE: '2024-12-04 18:24:15',
    },
    {
      No: 151,
      PG: 'Galaxia',
      PAY_FLAG: '비인증',
      ADMIN: '1.95',
      SENOIR: 'seasy24823',
      MID: 'seasy24823123l',
      KEY: 'SU5JTElURV9UUklQTEVERVNfS0VZU1RS',
      INV: 'Y',
      INSERT_DATE: '2024-12-04 18:23:51',
      UPDATE_DATE: '2024-12-04 18:23:51',
    },
    {
      No: 150,
      PG: 'dapay',
      PAY_FLAG: '비인증',
      ADMIN: '2.0',
      SENOIR: 'M2140377',
      MID: 'QlJORJJOTUNIRVSSFZHOw==',
      KEY: 'test_sk_D4yKeq5bgrpKRd0J',
      INV: 'N',
      INSERT_DATE: '2024-11-06 21:35:40',
      UPDATE_DATE: '2024-11-10 13:46:57',
    },
    {
      No: 149,
      PG: 'payhub',
      PAY_FLAG: '구인증',
      ADMIN: '1.90',
      SENOIR: 'test24823',
      MID: 'test24823123l',
      KEY: 'TEST_TRIPLE_KEY_STR',
      INV: 'Y',
      INSERT_DATE: '2024-11-05 15:24:15',
      UPDATE_DATE: '2024-11-05 15:24:15',
    },
    {
      No: 148,
      PG: 'Galaxia',
      PAY_FLAG: '인증',
      ADMIN: '1.85',
      SENOIR: 'galaxy24823',
      MID: 'galaxy24823123l',
      KEY: 'GALAXY_TEST_KEY_STR',
      INV: 'N',
      INSERT_DATE: '2024-11-04 10:24:15',
      UPDATE_DATE: '2024-11-04 10:24:15',
    }
  ];

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      console.log("파일이 선택되었습니다:", file.name);
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCancelFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    fileInputRef.current.value = '';
  };

  const buttons = [
    { 
      label: 'Copy', 
      icon: <FontAwesomeIcon icon={faCopy} />,
      color: '#1976d2'
    },
    { 
      label: 'Excel', 
      icon: <DownloadIcon />,
      color: '#2e7d32'
    },
    { 
      label: 'Print', 
      icon: <FontAwesomeIcon icon={faPrint} />,
      color: '#1976d2'
    }
  ];

  const handleRequestSort = (property) => {
    if (!columns.find(col => col.id === property).sortable) return;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3}>
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AccountBalanceIcon sx={{ color: '#ed6c02' }} />
            <Typography variant="h6">PG사별 세팅</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              accept="image/*"
            />
            {selectedFile && (
              <Box sx={{ 
                position: 'relative',
                width: '40px',
                height: '40px'
              }}>
                <img 
                  src={previewUrl}
                  alt="preview"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '4px'
                  }}
                />
                <IconButton
                  onClick={handleCancelFile}
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    width: '20px',
                    height: '20px',
                    backgroundColor: '#fff',
                    border: '1px solid #d32f2f',
                    color: '#d32f2f',
                    padding: '2px',
                    '&:hover': {
                      backgroundColor: '#d32f2f10',
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} size="xs" />
                </IconButton>
              </Box>
            )}
            <Tooltip title="파일 선택">
              <IconButton
                onClick={handleFileButtonClick}
                sx={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#fff',
                  border: '1px solid #1976d2',
                  color: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#1976d210',
                  }
                }}
              >
                <UploadFileIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="엑셀 양식 다운로드">
              <IconButton
                sx={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#fff',
                  border: '1px solid #2e7d32',
                  color: '#2e7d32',
                  '&:hover': {
                    backgroundColor: '#2e7d3210',
                  }
                }}
              >
                <SaveAltIcon />
              </IconButton>
            </Tooltip>
            <Button variant="contained" color="primary">
              등록하기
            </Button>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            mb: 2 
          }}>
            {buttons.map((button, index) => (
              <Tooltip key={index} title={button.label}>
                <IconButton
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#fff',
                    border: `1px solid ${button.color}`,
                    color: button.color,
                    '&:hover': {
                      backgroundColor: `${button.color}10`,
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="center">
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {column.id === 'PG' ? (
                          <Select
                            displayEmpty
                            value=""
                            onChange={(e) => console.log(e.target.value)}
                            size="small"
                          >
                            <MenuItem value="">선택</MenuItem>
                            <MenuItem value="payhub">payhub</MenuItem>
                            <MenuItem value="Galaxia">Galaxia</MenuItem>
                            <MenuItem value="dapay">dapay</MenuItem>
                          </Select>
                        ) : (
                          <>
                            {column.label}
                            {column.sortable && (
                              <IconButton
                                size="small"
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {orderBy === column.id ? (
                                  order === 'asc' ? '↑' : '↓'
                                ) : '↕'}
                              </IconButton>
                            )}
                          </>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dummyData.map((row) => (
                  <TableRow key={row.No}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center">
                        {column.id === 'delete' ? (
                          <Button 
                            variant="contained" 
                            color="error" 
                            size="small"
                            onClick={() => console.log(`Delete row ${row.No}`)}
                          >
                            삭제
                          </Button>
                        ) : (
                          row[column.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default PGProviderSettings;
